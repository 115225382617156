import { Fragment, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from '@/shared/ui/table'
import dayjs from 'dayjs'
import { LangStore } from '@/shared/model/store'
import { getEllipsisTxt } from '@/shared/lib'
import { useCopyToClipboard } from 'usehooks-ts'
import { Button } from '@/shared/ui'
import { Copy } from 'lucide-react'

interface IGovernanceItem {
	id: number
	type: string
	proposal_id: number
	proposer: `0x${string}`
	vote_start: string
	vote_end: string
	description: string
	tx_hash: `0x${string}`
	block_number: number
	time_stamp: string
	calls: {
		target: string
		data: string
		value: number
	}[]
}

export const Governance = () => {
	const { state: governanceList } = useLocation() as { state: IGovernanceItem[] | null }
	const navigate = useNavigate()
	const { langPack } = LangStore()
	const [, copy] = useCopyToClipboard()

	useEffect(() => {
		if (!governanceList?.length) return navigate('/')
	}, [governanceList])

	return (
		<div className='container rounded-md border p-6'>
			<h1 className='my-10 scroll-m-20 text-center text-4xl font-extrabold tracking-tight lg:text-5xl'>
				{langPack('governance.title')}
			</h1>
			<Table>
				<TableHeader>
					<TableRow>
						<TableHead className='w-[100px]'>id</TableHead>
						<TableHead>{langPack('governance.description')}</TableHead>
						<TableHead>{langPack('governance.vote_start')}</TableHead>
						<TableHead>{langPack('governance.vote_end')}</TableHead>
						<TableHead className='text-center'>{langPack('governance.operation')}</TableHead>
					</TableRow>
				</TableHeader>
				<TableBody>
					{governanceList?.map(item => (
						<TableRow key={item.tx_hash}>
							<TableCell className='text-center align-middle font-medium'>
								<div className='inline-flex items-center justify-center gap-2'>
									<div>{getEllipsisTxt(item.tx_hash)}</div>
									<Button size='icon' variant='secondary' className='h-5 w-5' onClick={() => copy(item.tx_hash)}>
										<Copy />
									</Button>
								</div>
							</TableCell>
							<TableCell>{item.description}</TableCell>
							<TableCell>{dayjs(item.vote_start).format('DD.MM.YYYY')}</TableCell>
							<TableCell>{dayjs(item.vote_end).format('DD.MM.YYYY')}</TableCell>
							<TableCell className='text-center'>
								<TableCell className='text-end'>
									<div className='grid w-full grid-cols-[minmax(auto,360px)_minmax(auto,1fr)_minmax(auto,1fr)] gap-4'>
										<div className='text-left font-semibold'>{langPack('governance.data')}</div>
										<div className='text-center font-semibold'>{langPack('governance.description')}</div>
										<div className='text-right font-semibold'>{langPack('governance.value')}</div>

										{item.calls.map((call, index) => (
											<Fragment key={index}>
												<div className='text-left'>{call.data}</div>
												<div className='text-center'>{call.target}</div>
												<div className='text-right'>{call.value}</div>
											</Fragment>
										))}
									</div>
								</TableCell>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</div>
	)
}
