import { Button, Popover, PopoverContent, PopoverTrigger, Textarea } from '@/shared/ui'
import { FC } from 'react'
import { SendHorizontal, Smile, X } from 'lucide-react'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { useTheme } from '@/shared/lib'
import { formatEther } from 'viem'
import { UserStore } from '@/entities/user'
import { LangStore } from '@/shared/model/store'
import { ChatStore } from '@/entities/chat'
import { useChatActions } from '../lib/hooks/use-chat-actions'
import SponsorModal from '@/pages/profile/ui/sponsors/sponsor-modal'

type Props = {
	mySponsorship: number
}

const onCrtlClick = {
	send: ['Control', 'Enter'],
	new_line: ['Enter']
}

const onEnterClick = {
	send: ['Enter'],
	new_line: ['Control', 'Enter']
}

export const SendBox: FC<Props> = ({ mySponsorship }) => {
	const { langPack } = LangStore()
	const { editedMessage, setEditedMessage, activeChat, value, setValue } = ChatStore()
	const { signatureAddress, userData } = UserStore()

	const { theme } = useTheme()
	const { sendEditMessage, sendMessage, isLoadingSend } = useChatActions()

	if (!activeChat) return null
	const { author } = activeChat

	const addEmoji = e => {
		const sym = e.unified.split('_')
		const codeArray = sym.map(s => parseInt(s, 16))
		setValue(value + String.fromCodePoint(...codeArray))
	}

	const cancelMessageEdit = () => {
		setValue('')
		setEditedMessage(null)
	}

	const configChat = userData?.sendMessage === 'Ctrl|Enter' ? onCrtlClick : onEnterClick

	const keyDownHandler = event => {
		if (event.key === 'Enter') {
			event.preventDefault()
			const isControl = event.ctrlKey || event.metaKey;

			if (configChat.send.includes('Control') && configChat.send.includes('Enter')) {
					if (isControl) {
						sendMessage(value)
						cancelMessageEdit()
					} else {
						setValue(`${value}\r\n`)
					}
			} else if (configChat.send.includes('Enter')) {
					if (isControl) {
						setValue(`${value}\r\n`)
					} else {
						sendMessage(value)
						cancelMessageEdit()
					}
			}
		}
	}

	const onSendMessage = async () => {
		if (editedMessage) {
			await sendEditMessage(editedMessage.n, value)
		} else {
			await sendMessage(value)
		}

		cancelMessageEdit()
	}

	const placeholder =
		mySponsorship < author?.chat_write_price && activeChat?.chat_id !== signatureAddress
			? `${langPack('chat.textarea_placeholder_block')} ${formatEther(BigInt(author?.chat_write_price))} SAVVA`
			: langPack('chat.textarea_placeholder')

	return (
		<div className='z-[100] flex flex-col items-center gap-3 border-t'>
			{editedMessage && (
				<div className='flex w-full items-center bg-secondary text-sm text-muted-foreground'>
					<Button size='icon' variant='secondary' onClick={cancelMessageEdit}>
						<X />
					</Button>
					<div>
						{langPack('chat.edit_message')}: {`${value.length > 80 ? `${value.slice(0, 80)}...` : value}`}
					</div>
				</div>
			)}
			<div className='relative w-full'>
				<Textarea
					value={value}
					onChange={e => setValue(e.target.value)}
					placeholder={placeholder}
					onKeyDown={keyDownHandler}
					className='border-none pr-10 outline-none'
					disabled={mySponsorship < author?.chat_write_price && activeChat?.chat_id !== signatureAddress}
				/>
				<Popover>
					<PopoverTrigger className='absolute bottom-1 right-5'>
						<Button className='w-full' size='icon' variant='secondary' asChild>
							<Smile />
						</Button>
					</PopoverTrigger>
					<PopoverContent className='w-full bg-transparent p-0'>
						<Picker
							data={data}
							theme={theme}
							onEmojiSelect={addEmoji}
							emojiButtonSize={28}
							emojiSize={20}
							maxFrequentRows={0}
						/>
					</PopoverContent>
				</Popover>
			</div>
			<div className='flex w-full'>
				{mySponsorship < author?.chat_write_price && activeChat?.chat_id !== signatureAddress ? (
					<SponsorModal profile={author} />
				) : (
					<Button className='w-full rounded-none rounded-br-lg' onClick={onSendMessage} disabled={!value || isLoadingSend} loading={isLoadingSend}>
						{!isLoadingSend && <SendHorizontal />}
					</Button>
				)}
			</div>
		</div>
	)
}
