import { createContext, useEffect, useState } from 'react'

export type TTheme = 'dark' | 'light'

export interface IThemeProviderProps {
	children: React.ReactNode
	defaultTheme?: TTheme
	storageKey?: 'savva-ui-theme'
}

export type IThemeProviderState = {
	theme: TTheme
	setTheme: (theme: TTheme) => void
}

const initialState: IThemeProviderState = {
	theme: 'light',
	setTheme: () => null
}

export const ThemeProviderContext = createContext<IThemeProviderState>(initialState)

export function ThemeProvider({
	children,
	defaultTheme = 'light',
	storageKey = 'savva-ui-theme',
	...props
}: IThemeProviderProps) {
	const [theme, setTheme] = useState<TTheme>(() => (localStorage.getItem(storageKey) as TTheme) || defaultTheme)

	useEffect(() => {
		const root = window.document.documentElement

		root.classList.remove('light', 'dark')

		root.classList.add(theme)
	}, [theme])

	const value = {
		theme,
		setTheme: (theme: TTheme) => {
			localStorage.setItem(storageKey, theme)
			setTheme(theme)
		}
	}

	return (
		<ThemeProviderContext.Provider {...props} value={value}>
			{children}
		</ThemeProviderContext.Provider>
	)
}
