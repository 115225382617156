import { AppInfoStore, LangStore } from '@/shared/model/store'
import { MessageCircle } from 'lucide-react'
import defaultAvatar from '@/shared/assets/images/avatar-default.svg'
import { Card, CardContent, CardFooter, CardHeader } from '@/shared/ui/card'
import { Separator } from '@/shared/ui/separator'
import ReactionsPreview from '@/features/reaction-preview/ui/reaction-preview'
import { UserName } from '@/shared/ui/user-name'
import { useState } from 'react'
import { cn, formatNumber, formattedDate, parceEthAmount } from '@/shared/lib'
import { getCurrentTitles } from '../model/utils'
import { ContentCategories } from './content-categories'
import { ContentType } from '../model/types'
import { FundRibbon } from './fund-ribbon'
import { NftIcon } from '@/shared/assets'
import { ContentStore } from '../model/content-store'
import { Link } from 'react-router-dom'
import Blocked from './blocked'

type Props = {
	content: ContentType
}
const FeedBlockGrid = ({ content }: Props) => {
	const [showFund] = useState<boolean>(false)
	const { setActivePost } = ContentStore()
	const { appInfo } = AppInfoStore()
	const { lang, langPack } = LangStore()

	const linkToAuthor = content.author.name.length ? `/@${content.author.name}` : `/${content.author.address}`

	const imageSrc = content.savva_content.thumbnail.length
		? `${appInfo?.ipfs_gateways[0]}${content.savva_content.thumbnail}`
		: content.author.avatar.length
			? `${appInfo?.ipfs_gateways[0]}${content.author.avatar}`
			: defaultAvatar

	return (
		<Link
			to={`/content/${content.short_cid.length ? content.short_cid : content.savva_cid}`}
			key={content.savva_cid}
			state={content}
			onClick={() => setActivePost(content)}
		>
			<Card className='relative my-5 box-border bg-card'>
				<Blocked content={content} grid />
				{content.nft.owner?.address && (
					<div className='absolute -left-[10px] -top-[12px] z-10 scale-125'>
						<NftIcon />
					</div>
				)}
				<CardHeader className='relative p-0'>
					<img className='h-48 w-full rounded-t-lg object-cover' src={imageSrc} alt='savva.img' />
					<div
						className={cn(
							'absolute -top-[5px] flex h-[0px] w-full flex-col items-center justify-center overflow-hidden text-[white] backdrop-blur-xl [transition:all_.2s_linear]',
							showFund && 'h-[245px] [transition:all_.2s_linear]'
						)}
					>
						<div className='w-120px flex text-[12px]'>{langPack('fund.title')}</div>
						<div className='text-[22px] font-[500]'>{parceEthAmount(content.fund.amount, '', 2)}</div>
						<div className='text-[12px] font-[300]'>~${formatNumber(100, lang)}</div>
					</div>
					<FundRibbon content={content} className='' />
				</CardHeader>
				<CardContent className='relative flex h-[140px] flex-col py-1 text-sm'>
					<object style={{ display: 'flex' }}>
						<Link to={linkToAuthor}>
							<UserName account={content.author} sliceLen={19} />
						</Link>
					</object>
					<div className='absolute -top-[10px] left-0 flex h-[20px] w-full rounded-bl-none rounded-br-none rounded-tl-[10px] rounded-tr-[10px] content-[""]'></div>
					<h2 className='text-lg font-semibold truncate'>
						{getCurrentTitles(content.savva_content.locales, 'title')}
					</h2>
					<p className='postItem mt-1 break-words text-[14px] leading-5 truncate text-muted-foreground'>
						{getCurrentTitles(content.savva_content.locales, 'text_preview')}
					</p>
				</CardContent>
				<div className='h-[35px] pl-6 flex items-center mt-1'>
					<ReactionsPreview reactions={content.reactions} />
				</div>
				<CardFooter className='w-max-[50px] flex flex-col p-0'>
					<ContentCategories content={content} className='w-full px-6 py-4' />
					<Separator />
					<div className='flex w-full flex-row items-center justify-between p-2 px-6'>
						<div className='text-sm text-muted-foreground'>{formattedDate(content.timestamp, lang)}</div>
						<div className='row flex items-center gap-1 text-[grey]'>
							<MessageCircle color='grey' size={15} />
							<span className='text-[12px]'>{content.total_childs}</span>
						</div>
					</div>
				</CardFooter>
			</Card>
		</Link>
	)
}

export default FeedBlockGrid
