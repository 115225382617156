import { ToggleGroup, ToggleGroupItem } from '@/shared/ui'
import { FC } from 'react'

//FIXME: Указать типы
type Props = {
	value: string
	setValue: (value: string) => void
	locales: string[]
}

export const ContentLangToggle: FC<Props> = ({ locales, value, setValue }) => {
	const onValueChange = (value: string) => {
		if (!value) return
		setValue(value)
	}

	return (
		<ToggleGroup type='single' value={value} onValueChange={onValueChange}>
			{locales.map(el => (
				<ToggleGroupItem value={el} key={el}>
					{el.toLocaleUpperCase()}
				</ToggleGroupItem>
			))}
		</ToggleGroup>
	)
}
