import { fixLengthPreview, getCurrentTitles, getToastTitle } from '@/entities/feed-block/model/utils'
import { UserStore } from '@/entities/user'
import { parceEthAmount, useToast } from '@/shared/lib'
import { LangStore } from '@/shared/model/store'
import { SocketStore } from '@/shared/model/store/socket-store'
import { getName } from '@/shared/ui/user-name/ui/user-name'
import { useCallback, useEffect, useMemo } from 'react'

const GlobalToast = ({ children }: { children: React.ReactNode }) => {
	const { last } = SocketStore()
	const { signatureAddress } = UserStore()
	const { langPack } = LangStore()
	const { toast } = useToast()

	const key = useMemo(() => (Math.random() * Math.random()).toString(), [])

	const showNotification = useCallback(
		config => {
			toast({
				duration: config.duration,
				key,
				title: config.message,
				description: config.description,
				type: config.type,
				variant: config.variant
			})
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[key]
	)

	useEffect(() => {
		switch (last.type) {
			case 'content_processed':
				if(last.data.content.savva_content.content_type === "comment" && last.data.content.savva_content.root_post.author.address === signatureAddress) {
					showNotification({
						duration: 5000,
						message: `К посту "${getCurrentTitles(last.data.content.savva_content.root_post?.locales, 'title')}" оставили комментарий`,
						description: getCurrentTitles(last.data.content.savva_content.locales, 'text_preview'),
						variant: 'sucsess'
					})
				}
			// data.content.savva_content.parent_post
				if(last.data.content.savva_content.content_type === "comment" && last.data.content.savva_content.parent_post.author.address === signatureAddress) {
					showNotification({
					duration: 5000,
					key: key,
					message: `На Ваш комментарий "${fixLengthPreview(getCurrentTitles(last.data.content.savva_content.parent_post?.locales, 'text_preview'), 100)}" ответили`,
					description: fixLengthPreview(getCurrentTitles(last.data.content.savva_content.locales, 'text_preview'), 256),
					variant: 'sucsess'
					})
					}
		break;
		case 'fund_contributed':
			if(last.data.author.address === signatureAddress) {
				showNotification({
					duration: 5000,
					message: `${langPack('socket.message_three')} "${getToastTitle(last.data.title)}" ${langPack('socket.message_four')}`,
					description: `${langPack('socket.message_desc_three')} ${parceEthAmount(last.data.amount, 'SAVVA')}`,
					variant: 'sucsess'
				})
			}
			if (last.data.author_share > 0 && last.data.author.address === signatureAddress) {
				const description = `${langPack('socket.message_five')} ${parceEthAmount(
				last.data.author_share,
				'SAVVA',
				)} ${langPack('socket.message_six')} ${getName(last.data.contributor)} ${langPack('socket.message_desc_five')} "${getToastTitle(last.data.title)}".`;

				showNotification({
					duration: 5000,
					message: `${langPack('socket.message_seven')}`,
					description,
					variant: 'sucsess'
				})
			}
		break
		case 'banned_user':
			if(last.data.user.address === signatureAddress) {
				showNotification({
					duration: 500,
					message: `Your account is blocked`,
					description: last.data.comment,
					variant: 'destructive'
				})
			}
			break;
			case 'unbanned_user':
				if(last.data.user.address === signatureAddress) {
					showNotification({
						duration: 500,
						message: `Your account is unblocked`,
						description: last.data.comment,
						variant: 'sucsess'
					})
				}
				break;
		default:
		break;
		}
	}, [last])

	return <>{children}</>
}

export default GlobalToast
