import { Badge } from '@/shared/ui'
import { ContentType } from '../model/types'
import { fixLengthPreview } from '../model/utils'
import { cn } from '@/shared/lib'

type Props = {
    content: ContentType
    grid?: boolean
}

const Blocked = ({content, grid}: Props) => {

    if(!content.banned && !content.author.banned) {
        return <></>
    }

  return (
    <div className={cn('absolute w-full h-full backdrop-blur-lg rounded-lg z-10 flex flex-col items-start justify-start p-8 box-border', grid && 'justify-end')}>
        <Badge className='absolute -top-[15px] p-2' variant={'destructive'}>
            {content.author.banned ? 'AUTHOR' : 'POST'} BLOCKED
        </Badge>
         {content.author.banned && 
         <div className='flex flex-col items-start mb-2 w-[75%]'>
            <div className='font-bold'>Author is blocked:</div>
            <div>{fixLengthPreview(content.author.ban_comment, 170)}</div>
        </div>}
       {content.banned && 
       <div className='flex flex-col items-start w-[75%]'>
            <div className='font-bold'>Post blocked:</div>
            <div className=' flex w-max-[70%]'>{fixLengthPreview(content.ban_comment, 170)}</div>
        </div>}
    </div>
  )
}

export default Blocked