import { Button } from '@/shared/ui'
import { Ban, CircleCheck, Eye, EyeOff, Files, PencilLine, Trash2 } from 'lucide-react'
import { FC } from 'react'
import { useCopyToClipboard } from 'usehooks-ts'
import { IMessage } from '../model/types'
import useRequest from '@/shared/lib/use-request'
import { ConfigStore, LangStore } from '@/shared/model/store'
import dayjs from 'dayjs'
import { ChatStore } from '@/entities/chat'
import { UserStore } from '@/entities/user'
import { EMOJI_LIST } from '@/features/reactions/model/emoji-list'
import { IUser } from '@/entities/user/model/types'
import { cn } from '@/shared/lib'

type Props = {
	message: IMessage
	bannedUserList: IUser[]
	setShowBlocked: React.Dispatch<React.SetStateAction<boolean>>
	showBlocked: boolean
}

export const ContextMenu: FC<Props> = ({ message, bannedUserList, setShowBlocked, showBlocked }) => {
	const { langPack } = LangStore()
	const [, copy] = useCopyToClipboard()
	const { domain } = ConfigStore()
	const { activeChat, setEditedMessage, value, setValue } = ChatStore()
	const { signatureAddress } = UserStore()

	const { fetch: fetchSendReactions } = useRequest('sendReaction')
	const { fetch: fetchDeletedMessage } = useRequest('chatDelete')
	const { fetch: fetchBanMessage } = useRequest('chatBanMessage')
	const { fetch: fetchBanUser } = useRequest('chatBanUser')
	const { fetch: fetchUnBanMessage } = useRequest('chatUnBanMessage')
	const { fetch: fetchUnBanUser } = useRequest('chatUnBanUser')

	const addReaction = (message: IMessage, idx: number) => {
		fetchSendReactions({
			params: {
				'obj-type': '1',
				'obj-id': message.chat_id,
				n: message.n,
				reaction: idx,
				domain: domain
			}
		})
	}

	const removeReaction = (message: IMessage) => {
		fetchSendReactions({
			params: {
				'obj-type': '1',
				'obj-id': message.chat_id,
				n: message.n,
				reaction: -1,
				domain: domain
			}
		})
	}

	const deleteMessage = (message: IMessage) => {
		fetchDeletedMessage({
			params: {
				domain: message.domain,
				'chat-id': message.chat_id,
				n: message.n
			}
		})
	}

	const unBanUser = () => {
		fetchUnBanUser({
			params: {
				domain: message.domain,
				user_addr: message.author.address
			}
		})
	}

	const banUser = () => {
		fetchBanUser({
			params: {
				domain: message.domain,
				user_addr: message.author.address
			}
		})
	}

	const banMessage = () => {
		fetchBanMessage({
			params: {
				domain: message.domain,
				n: message.n
			}
		})
	}

	const unBanMessage = () => {
		fetchUnBanMessage({
			params: {
				domain: message.domain,
				n: message.n
			}
		})
	}

	const getDiff = () => {
		const a = dayjs()
		const b = dayjs(message.timestamp)
		return a.diff(b, 'hours') < 24
	}

	const setEditMessage = () => {
		setValue(message.text)
		setEditedMessage(message)
	}

	const isUserBanned = bannedUserList?.find(({ address }) => address === message.author.address)

	return (
		<div>
			{getDiff() && (
				<Button variant='ghost' className='flex w-full items-end gap-2 text-muted-foreground' onClick={setEditMessage}>
					<PencilLine className='h-4 w-4' />
					<div className='text-sm'>{langPack('action.edit')}</div>
				</Button>
			)}

			<Button variant='ghost' className='flex w-full items-end gap-2 text-muted-foreground'>
				<Files className='h-4 w-4' />
				<div className='text-sm' onClick={() => copy(message.text)}>
					{langPack('action.copy')}
				</div>
			</Button>
			{message.author.address === signatureAddress && (
				<Button
					variant='ghost'
					className='flex w-full items-end gap-2 text-muted-foreground'
					onClick={() => deleteMessage(message)}
				>
					<Trash2 className='h-4 w-4' />
					<div className='text-sm'>{langPack('action.delete')}</div>
				</Button>
			)}
			{activeChat?.owner.address === signatureAddress && message.author.address !== signatureAddress && (
				<Button
					variant='ghost'
					className='mb-2 flex w-full items-end gap-2 text-muted-foreground'
					onClick={message.banned ? unBanMessage : banMessage}
				>
					{message.banned ? <CircleCheck className='h-4 w-4' /> : <Ban className='h-4 w-4' />}
					<div className='text-sm'>{langPack(`chat.${message.banned ? 'unblock_message' : 'block_message'}`)}</div>
				</Button>
			)}

			{activeChat?.owner.address === signatureAddress && message.author.address !== signatureAddress && (
				<Button
					variant='ghost'
					className='flex w-full items-center gap-2 text-muted-foreground'
					onClick={isUserBanned ? unBanUser : banUser}
				>
					{isUserBanned ? <CircleCheck className='h-4 w-4' /> : <Ban className='h-4 w-4' />}
					<div className='text-sm'>{langPack(`${isUserBanned ? 'chat.unblock_author' : 'chat.block_author'}`)}</div>
				</Button>
			)}

			{activeChat?.owner.address === signatureAddress && message.banned && (
				<Button
					variant='ghost'
					className='flex w-full items-center gap-2 text-muted-foreground'
					onClick={() => setShowBlocked(!showBlocked)}
				>
					{showBlocked ? <EyeOff className='h-4 w-4' /> : <Eye className='h-4 w-4' />}
					<div className='text-sm'>{langPack(`${isUserBanned ? 'chat.show_message' : 'chat.show_message'}`)}</div>
				</Button>
			)}

			<div className='text-sm text-muted-foreground'>
				<div className='flex items-center h-8'>
					{EMOJI_LIST.map((el, idx) => (
						<div key={idx}
							className={cn('h-7 w-8 flex rounded-md items-center justify-center cursor-pointer hover:bg-primary/80', idx === message.my_reaction && 'bg-primary text-primary-foreground')}
							onClick={() => 
								idx === message.my_reaction
									? removeReaction(message)
									: addReaction(message, idx)
							}>
								{el}
						</div>
					))}
				</div>
			</div>
		</div>
	)
}
