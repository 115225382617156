import { ChatStore } from '@/entities/chat'
import { UserStore } from '@/entities/user'
import { toast } from '@/shared/lib'
import useRequest from '@/shared/lib/use-request'
import { useSubscribe } from '@/shared/lib/use-subscribe'
import { ConfigStore } from '@/shared/model/store'
import { useEffect, useState } from 'react'

export const useSponsorship = () => {
	const [mySponsorship, setMySponsorship] = useState(0)
	const { activeChat } = ChatStore()
	const { signatureAddress } = UserStore()
	const { domain } = ConfigStore()

	const { fetch } = useRequest('getUser')
	const { message } = useSubscribe('club_buy')

	const getMySponsorship = async () => {
		if (!activeChat.author) return
		await fetch({
			params: {
				domain,
				user_addr: activeChat?.owner.address,
				caller: signatureAddress
			},
			onSuccess({ i_sponsor_for }) {
				setMySponsorship(i_sponsor_for)
			},
			onError: () => toast({ title: 'Ошибка получения спонсирования', variant: 'destructive' })
		})
	}

	useEffect(() => {
		if (!activeChat) return
		getMySponsorship()
	}, [domain, activeChat, signatureAddress, message])

	return { mySponsorship }
}
