import yaml from 'yaml'

export const getDefaultConnectYaml = async (url: string) => {
	try {
		const response = await fetch(url)

		const text = await response.text()
		const defaultConnect = yaml.parse(text)

		if (!defaultConnect.domain || !defaultConnect.backendLink) {
			throw new Error('Error: Missing domain or backend link')
		}

		return defaultConnect
	} catch (error) {
		console.error('Error: Missing domain or backend link:', error)
		throw error
	}
}
