import { Badge } from '@/shared/ui'
import { FC } from 'react'
import { EMOJI_LIST } from '@/features/reactions/model/emoji-list'

type Props = {
	myReaction?: number
	reactions?: number[]
}

export const Reactions: FC<Props> = ({ reactions = [], myReaction = -1 }) => {
	return (
		<div className='flex justify-start'>
			{reactions.map(
				(el, idx) =>
					el !== 0 && (
						<Badge key={el} variant={myReaction === idx ? 'default' : 'secondary'} className='h-6 w-10 rounded-md'>
							<div>{EMOJI_LIST[idx]}</div>
						</Badge>
					)
			)}
		</div>
	)
}
