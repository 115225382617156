import { cn } from '@/shared/lib'
import { ContractsStore } from '@/shared/model/store/contracts-store'

type Props = {
    amount: number
    token?: 'SAVVA' | 'BASE'
    className?: string
    bracket?: boolean

}

const PerUsd = ({amount, token, className = '', bracket = false}: Props) => {
    const { tokenPrice } = ContractsStore()
  return (
    <div className={cn('', className)}>
        {bracket && '('}{`$${(tokenPrice[token === 'SAVVA' ? 'savva_token_price' : 'base_token_price'] * amount).toFixed(2)}`}{bracket && ')'}
    </div>
  )
}

export default PerUsd