import { ContentStore } from '@/entities/feed-block/model/content-store'
import { UserCard, UserStore } from '@/entities/user'
import { IUser } from '@/entities/user/model/types'
import { AppInfoStore, LangStore } from '@/shared/model/store'
import { Button, Card } from '@/shared/ui'
import { AmountBlock } from '@/shared/ui/amount-block/amount-block'
import defaultAvatar from '@/shared/assets/images/avatar-default.svg'
import { useState } from 'react'
import { getCurrentTitles } from '@/entities/feed-block/model/utils'
import { ILang } from '@/entities/feed-block/model/types'
import { cn } from '@/shared/lib'
import NftBox from './nft-box'
import NftMintDialog from './nft-mint-dialog'
import NftSettings from './nft-settings/nft-settings'
import NftBuy from './buy-nft/nft-buy'
import { ChevronDown, ChevronUp } from 'lucide-react'

export const format = (date: string, lang: ILang) => {
	const d = new Date(date)
	const year = new Intl.DateTimeFormat(lang, { year: 'numeric' }).format(d)
	const month = new Intl.DateTimeFormat(lang, { month: '2-digit' }).format(d)
	const day = new Intl.DateTimeFormat(lang, { day: '2-digit' }).format(d)
	return `${day}-${month}-${year}`
}

export const NftBlock = () => {
	const { appInfo } = AppInfoStore()
	const { activePost } = ContentStore()
	const { userData } = UserStore()
	const { langPack, lang } = LangStore()
	const [more, setMore] = useState<boolean>(false)

	if (!activePost?.savva_cid) return <></>

	const imageSrc = activePost.savva_content.thumbnail.length
		? `${appInfo?.ipfs_gateways[0]}${activePost.savva_content.thumbnail}`
		: activePost.author.avatar.length
			? `${appInfo?.ipfs_gateways[0]}${activePost.author.avatar}`
			: defaultAvatar

	if (!activePost.nft.owner?.address && userData?.address === activePost.author.address) {
		return (
			<NftBox className='mb-6'>
				<div className='mt-4 text-center text-[13px] leading-4 text-[grey]'>{langPack('nft.nft_info')}</div>
				<div className='mt-4'>
					<NftMintDialog />
				</div>
			</NftBox>
		)
	}

	if (!activePost.nft.owner?.address) return <></>

	return (
		<NftBox className='mb-6'>
			<div className='relative mt-3 flex flex-row items-center'>
				<UserCard user={activePost?.nft?.owner || ({} as IUser)} length={25} descriprion={langPack('nft.owner')} />
			</div>
			<div className='mt-3 flex flex-col items-center justify-between'>
				<div className='mb-3 flex w-full flex-col justify-between px-6'>
					{activePost.nft.on_market && (
						<div className='flex flex-col items-center justify-center'>
							<div className='text-sm'>{langPack('nft.price')}</div>
							<AmountBlock amount={activePost.nft.price} size='md' />
						</div>
					)}
				</div>
				<Card
					className={cn(
						'h-0 overflow-hidden transition duration-500 ease-in-out',
						more && 'h-auto transition duration-500 ease-in-out'
					)}
				>
					<div>
						<img className='w-full rounded-t-lg object-cover' src={imageSrc} alt='savva.img' />
					</div>
					<div className='p-2 text-[12px] text-[grey]'>
						{`(${format(activePost.timestamp, lang)}). ${getCurrentTitles(activePost.savva_content.locales, 'title')}`}
					</div>
				</Card>
				<div className='mt-3 flex w-full flex-row gap-3'>
					<div className='w-[50px]'>
						<Button variant='ghost' size='icon' onClick={() => setMore(!more)}>
							{!more ? <ChevronDown size={16} /> : <ChevronUp size={16} />}
						</Button>
					</div>
					<div className='w-full'>
						{userData?.address === activePost.nft.owner?.address && <NftSettings />}
						{activePost.nft.on_market && userData?.address !== activePost.nft.owner.address && (
							<NftBuy cid={activePost.savva_cid} />
						)}
					</div>
				</div>
			</div>
		</NftBox>
	)
}
