import { Navigation } from '@/features/navigation'
import { Button, Separator } from '@/shared/ui'
import { Sheet, SheetClose, SheetContent, SheetFooter, SheetHeader, SheetTitle, SheetTrigger } from '@/shared/ui/sheet'
import { Menu } from 'lucide-react'
import { ActionMenu } from './action-menu'
import { Wallet } from '@/entities/wallet'
import { UserCard, UserStore } from '@/entities/user'
import { Usermenu } from './user-menu'
import { useAccount } from 'wagmi'

export const MobileMenu = () => {
	const { userData, signatureAddress } = UserStore()
	const {isConnected} = useAccount()

	return (
		<Sheet>
			<SheetTrigger asChild>
				<Button variant='outline' size='icon'>
					<Menu />
				</Button>
			</SheetTrigger>
			<SheetContent className='h-screen w-3/5 sm:w-2/5'>
				<SheetHeader className='flex flex-row items-center gap-4'>
					<SheetTitle>
						<UserCard user={userData} />
					</SheetTitle>
				</SheetHeader>
				<Separator className='my-4' />
				<div className='mb-4'>
					<Navigation isMobile />
				</div>
				<div className='my-4'>
					<ActionMenu isMobile />
				</div>
				{(signatureAddress || isConnected) && <Usermenu />}
				<Separator className='my-4' />
				<SheetFooter>
					<SheetClose className='w-full'>
						<Wallet />
					</SheetClose>
				</SheetFooter>
			</SheetContent>
		</Sheet>
	)
}
