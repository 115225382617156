import useRequest from '@/shared/lib/use-request'
import { useEffect } from 'react'
import { ConfigStore } from '@/shared/model/store'
import { UserStore } from '@/entities/user'
import { toast } from '@/shared/lib'
import { ChatStore, IChat } from '@/entities/chat'

export const useChatList = () => {

	const { domain } = ConfigStore()
	const { signatureAddress } = UserStore()
	const { setChats, lastRead, setLastRead, setActiveChat } = ChatStore()

	const { fetch: fetchChatList, isLoading } = useRequest<{chats: IChat[]}>('getChatList')

	const getChatList = async () => {
		fetchChatList({
			params: {
				domain,
				addr: signatureAddress
			},
			onSuccess(res) {
				setChats(res?.chats ?? [])
				const tmp_last_read = {...lastRead}
				for(let i = 0; i < res.chats.length; i++){
					const k = `${res.chats[i].chat_id}:${domain}`
					if(!lastRead[k]){
						tmp_last_read[k] = {
							n: res.chats[i].n,
							lastRead: 0
						}
					} else {
						lastRead[k] = {
							...lastRead[k],
							n: res.chats[i].n
						}
					}
				}
				setActiveChat(res?.chats.find(el => el.owner.address === signatureAddress) ?? res?.chats[0])
				setLastRead(tmp_last_read)
			},
			onError() {
				setChats([])
				toast({
					title: 'Error',
					variant: 'destructive'
				})
			}
		})
	}

	useEffect(() => {
		if (!signatureAddress || !domain) return

		getChatList()
	}, [domain, signatureAddress])

	return { isLoading, getChatList }
}
