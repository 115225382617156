import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle
} from '@/shared/ui'
import { FC } from 'react'
import { useBlocker } from 'react-router-dom'
import { UseFormReturn } from 'react-hook-form'
import { z } from 'zod'
import { languageSchema } from '../model/model'
import deepEqual from 'deep-equal'
import { LangStore } from '@/shared/model/store'

type Props = {
	state: UseFormReturn<z.infer<typeof languageSchema>> | null
	form: UseFormReturn<z.infer<typeof languageSchema>>
	isConfirmed: boolean
	uploadUserFolder: () => Promise<void>
}

export const Blocker: FC<Props> = ({ form, state, isConfirmed, uploadUserFolder }) => {
	const { langPack } = LangStore()
	const blocker = useBlocker(({ currentLocation, nextLocation }) => {
		if (currentLocation.pathname === '/new-post') {
			if (!isConfirmed) {
				uploadUserFolder()
			}
			return false
		}

		return !deepEqual(form?.getValues(), state) && currentLocation.pathname !== nextLocation.pathname && !isConfirmed
	})

	if (blocker.state !== 'blocked') return

	return (
		<AlertDialog open={blocker.state === 'blocked'}>
			<AlertDialogContent>
				<AlertDialogHeader>
					<AlertDialogTitle>{langPack('editor.blocker_title')}</AlertDialogTitle>
					<AlertDialogDescription>{langPack('editor.blocker_description')}</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogCancel onClick={() => blocker.reset()}>{langPack('action.stay')}</AlertDialogCancel>
					<AlertDialogAction onClick={() => blocker.proceed()}>{langPack('action.leave')}</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	)
}
