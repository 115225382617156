import { useState } from 'react'
import { ProfileProps } from '../profile'
import {
	Button,
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
	Label
} from '@/shared/ui'
import AmountInput from '@/shared/ui/amount-input/ui/amount-input'
import { ConfigStore, LangStore } from '@/shared/model/store'
import { DEFAULT_PREC, getSplitTransaction, parseAmount } from '@/shared/lib'
import { AmountBlock } from '@/shared/ui/amount-block/amount-block'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import useNotifier from '@/shared/lib/use-notifier'
import StakeModal from '../wallet/modals/stake-modal'
import { Domain } from 'domain'
import { GuardButton } from '@/features/guard-button'
import { useWallet } from '@/entities/wallet'

const SponsorModal = ({ profile, getUserFetch, other = false }: ProfileProps) => {
	const { langPack } = LangStore()
	const { contracts } = ContractsStore()
	const { domain } = ConfigStore()
	const [quantity, setQuantity] = useState<number>()
	const [weeks, setWeeks] = useState<number>(1)
	const [open, setOpen] = useState<boolean>(false)

	const {stakedBalance} = useWallet()

	const closeHandle = (state: boolean) => {
		if (!state) {
			setQuantity(0)
			setWeeks(0)
		}
		setOpen(state)
	}

	const setQuaintyHandler = (value: number) => {
		setQuantity(value)
	}

	const setWeeksHandler = (value: number) => {
		if (value > 52) {
			setWeeks(52)
		} else {
			setWeeks(value)
		}
	}

	const { writeContract, isLoading } = useNotifier({
		onSuccess: () => {
			getUserFetch && getUserFetch()
			setOpen(false)
		}
	})

	const unSubscribeHandler = () => {
		writeContract({
			address: contracts.AuthorsClubs.address,
			abi: contracts.AuthorsClubs.abi,
			functionName: 'stop',
			args: [Domain, profile.address]
		})
	}

	const hanbleSubmit = async () => {
		if (!quantity) {
			unSubscribeHandler()
			return
		}
		const { message, v, r, s }: any = await getSplitTransaction({
			token: 'SAVVA_VOTES',
			spender: contracts!.AuthorsClubs.address,
			amount: Number(quantity) * Number(weeks),
			contract: 'Staking'
		})

		writeContract({
			address: contracts.AuthorsClubs.address,
			abi: contracts.AuthorsClubs.abi,
			functionName: 'buyWithPermit',
			args: [domain, profile.address, parseAmount(quantity), Number(weeks), message.deadline, v, r, s]
		})
	}

	const total = Number(quantity) * Number(weeks) || 0
	const upperThenBalance = total  > stakedBalance

	return (
		<Dialog open={open} onOpenChange={closeHandle}>
			<DialogTrigger asChild>
				<GuardButton
					title={langPack(other ? 'action.sponsor' : 'profile.sponsor')}
				/>
			</DialogTrigger>
			<DialogContent className='sm:max-w-[525px]'>
				<DialogHeader>
					<DialogTitle> {langPack('profile.sponsor_user')}</DialogTitle>
				</DialogHeader>
				<div className='text-[14px] text-[grey]'>{langPack('profile.amount_info')}</div>
				<div className='text-[14px] text-[grey]'>{langPack('profile.leave_this_field')}</div>
				{quantity && quantity > Number(stakedBalance) ? (
					<span className='text-destructive'>{langPack('profile.balance_error')}</span>
				) : null}
				<AmountInput
					value={quantity}
					onChange={setQuaintyHandler}
					placeholder={`Enter value`}
					label={langPack('fund.quantity')}
				/>
				<AmountInput
					value={weeks}
					onChange={setWeeksHandler}
					placeholder={`Enter value`}
					label={langPack('profile.week_count')}
					perUsd={false}
					// defaultValue={1}
					disabled={stakedBalance === 0}
					noDigits
				/>
				<div className='relative grid grid-cols-4 items-center gap-4'>
					<Label className='text-right'>{langPack('profile.total')}</Label>
					<div className='flex flex-col items-start'>
						<AmountBlock amount={total * DEFAULT_PREC} size='sm' danger={upperThenBalance} />
					</div>
				</div>
				<div className='relative grid grid-cols-4 items-center gap-4'>
					<Label className='text-right'>{langPack('profile.stake_balance')}</Label>
					<div className='flex flex-col items-start'>
						<AmountBlock amount={stakedBalance * DEFAULT_PREC} size='sm' />
					</div>
				</div>
				<DialogFooter>
					{stakedBalance === 0 ? (
						<div className='flex w-full flex-col'>
							<div className='mb-3 text-center text-[13px] text-[red]'>{langPack('profile.low_stake')}</div>
							<StakeModal getData={() => {}} />
						</div>
					) : (
						<Button
							onClick={hanbleSubmit}
							type='submit'
							className='text-[white]'
							variant='orange'
							loading={isLoading}
							disabled={upperThenBalance || stakedBalance === 0 || quantity > Number(stakedBalance) || !quantity || !weeks}
						>
							{langPack('profile.sponsor')}
						</Button>
					)}
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}

export default SponsorModal
