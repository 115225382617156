import { ContentStore } from '@/entities/feed-block/model/content-store'
import { UserStore } from '@/entities/user'
import { contractRead, stringToBytes32 } from '@/shared/lib'
import useNotifier from '@/shared/lib/use-notifier'
import { LangStore } from '@/shared/model/store'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import { Button } from '@/shared/ui'
import { AmountBlock } from '@/shared/ui/amount-block/amount-block'
import { useEffect, useState } from 'react'

const RewardsToken = () => {
  const { activePost } = ContentStore()
  const { contracts } = ContractsStore()
  const [nftBalance, setNftBalance] = useState<number>(0);
  const [nftCutSize, setNftCutSize] = useState<number>(0);
  const { userData } = UserStore()
  const { langPack } = LangStore()

  const getNftBlance = async () => {
    const balance = await contractRead('ContentFund', 'claimableNftGain', [userData?.address || '']);
    const nftCut = Number((await contractRead('Config', 'getUInt', [stringToBytes32('nftOwnerCut')])) || 0) / 100
    setNftBalance(balance)
    setNftCutSize(nftCut)
  }

  const { writeContract, isLoading } = useNotifier({
    onSuccess() {
      getNftBlance()
    },
  });

  const claim = () => {
    writeContract({
      address: contracts.ContentFund.address,
      abi: contracts.ContentFund.abi,
      functionName: 'claimNFTGain',
      args: [],
    });
  }

  useEffect(() => {
    if(!activePost?.savva_cid) return
    getNftBlance()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePost?.savva_cid])
  return (
    <div>
         <div className='text-[14px] text-[grey] flex w-full'>
             {`${langPack('nft.reward_title')} ${nftCutSize}% ${langPack('nft.reward_title_sec')}`}
            </div>
            <div className='flex flex-row items-center w-full gap-8 mt-6'>
                <div className='flex flex-row gap-4'>
                    <div>{langPack('nft.total_nfts_profit')}:</div>
                    <AmountBlock size='sm' amount={nftBalance}/>
                </div>
                <Button size={'sm'} onClick={claim} loading={isLoading}>{langPack('nft.claim')}</Button>
            </div>
    </div>
  )
}

export default RewardsToken