import { AppDetailStore, AppInfoStore, ConfigStore, LangStore } from '@/shared/model/store'
import { useEffect } from 'react'
import QRCode from 'react-qr-code'
import { Link } from 'react-router-dom'
import useRequest from '@/shared/lib/use-request'
import { Button } from '@/shared/ui'

//FIXME: добавить типизацию
export const TelegramConnect = () => {
	const domain = ConfigStore.getState().domain
	const default_locale = AppDetailStore(state => state.appDetails.default_locale)
	const tg = AppInfoStore(state => state.appInfo.domains.find(el => el.name === domain)!.telegram_bot)
	const { fetch, result } = useRequest('authToken')

	const { langPack } = LangStore()

	//FIXME: добавить типизацию
	useEffect(() => {
		fetch({})
	}, [])
	//FIXME: добавить типизацию
	return (
		<div>
			<QRCode className='mx-auto' value={`https://t.me/${tg}?start=${result?.token}-${default_locale}`} />
			<Button className='w-full' variant='link'>
				<Link to={`https://t.me/${tg}?start=${result?.token}-${default_locale}`} target='_blank'>
					{langPack('telegram.connect')}
				</Link>
			</Button>
		</div>
	)
}
