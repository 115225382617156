import { useEffect } from 'react'
import { IContentFilter, IPFSProfileType } from './profile'
import { IUser } from '@/entities/user/model/types'
import useRequest from '@/shared/lib/use-request'
import { ConfigStore } from '@/shared/model/store'
import { ContentType } from '@/entities/feed-block/model/types'
import { NoContent } from '@/entities/feed-block/ui/no-content'
import FeedBlockLine from '@/entities/feed-block/ui/feed-block-line'
import { UserStore } from '@/entities/user'
import BlogUnit from '@/shared/ui/skeletons/ui/blog-unit'

type Props = {
	profile: IUser & IPFSProfileType
	filters?: IContentFilter
}

const Blog = ({ profile, filters }: Props) => {
	const { domain } = ConfigStore()
	const { isAdmin, userData } = UserStore()

	const { fetch, result, isLoading } = useRequest<{ list: ContentType[] }>('getContentList')

	const getList = (filters = {}) => {
		fetch({
			params: {
				domain: domain,
				content_type: 'post',
				limit: 100,
				author_addr: profile.address,
				...((isAdmin || profile?.address === userData?.address) && { show_banned: true }),
				...filters
			}
		})
	}

	useEffect(() => {
		if (!filters || !Object.keys(filters).length) {
			return
		}
		getList(filters)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters])

	useEffect(() => {
		if (!profile.address) return
		getList()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [profile.address])

	if(isLoading) {
		return (
			<div className='flex flex-col gap-4 relative'>
				<BlogUnit />
				<BlogUnit />
				<BlogUnit />
			</div>
		)
	}

	if (!result?.list) {
		return <NoContent isLoading={isLoading} />
	}

	return (
		<div>
			{isLoading || result?.list.length < 1 ? (
				<NoContent isLoading={isLoading} />
			) : (
				<div>{result?.list.map((el: ContentType) => <FeedBlockLine content={el} key={el.savva_cid} />)}</div>
			)}
		</div>
	)
}

export default Blog
