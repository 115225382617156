import { ConfigStore } from '@/shared/model/store'
import { IChapter, IControlledFields, IEditorSavvaContent, ITransformedLocales } from '../model/types'
import { ABIsType, ContracNames, ContractsStore } from '@/shared/model/store/contracts-store'
import { v4 as uuidv4 } from 'uuid'
import { formSchema, systemChapter } from '../model/model'
import { hexToBytes, keccak256 } from 'viem'
import { UseFormReturn } from 'react-hook-form'
import { z } from 'zod'
import yaml from 'yaml'
import { ContentLocales } from '@/entities/feed-block/model/types'

export const compareValues = (state: ContentLocales, form: UseFormReturn<z.infer<typeof formSchema>>) => {
	const { locales } = form.getValues()

	for (const locale of Object.values(locales)) {
		for (const localeState of Object.values(state)) {
			if (localeState.title !== locale.title) return true

			if (localeState.data !== locale.data) return true
		}
	}

	return false
}

export const currentTotalSize = (files: File[]) => files.reduce((acc, file) => acc + file.size, 0)

// export const getNewFiles = (currentFiles: File[] | null, initFiles: File[]): File[] | null => {
// 	if (!currentFiles) return []
// 	const initialFileNames = initFiles.map(file => file.name)
// 	return currentFiles.filter(file => !initialFileNames.includes(file.name))
// }

export const getSubstringFromLastDot = input => {
	const lastDotIndex = input.lastIndexOf('.')
	return lastDotIndex !== -1 ? input.substring(lastDotIndex) : ''
}

export async function getContentFromHash(hash: string) {
	if (!hash) return ''
	const state = ConfigStore.getState()
	const contentData = await fetch(`${state.baseUrl}ipfs/${hash}`)
	return contentData.text()
}

export const getAbis = async data => {
	const state = ContractsStore.getState()
	const contracts = data.savva_contracts
	const ABI: ABIsType = {} as ABIsType
	const oldABI: ABIsType = state.contracts
	for await ( const property of Object.keys(contracts)) {
		const prop: ContracNames = property as ContracNames
		ABI[property] = {
			abi_cid: contracts[property].abi_cid,
			address: contracts[property].address,
			abi:
				!contracts[property]?.abi || oldABI[prop]?.abi_cid !== contracts[property]?.abi_cid
					? JSON.parse(await getContentFromHash(contracts[property].abi_cid))
					: contracts[property].abi
		}
	}
	ContractsStore.setState({ contracts: ABI })
}

export const createCid = (address: `0x${string}`, domain: string) => {
	const uid = uuidv4()

	const packed = [...hexToBytes(address), ...Buffer.from(domain), ...Buffer.from(uid)]
	const hash = keccak256(new Uint8Array(packed))

	return { uid, hash: `0x${hash}` }
}

export const isComment = (value: string) => ['comment', 'comment-edit'].includes(value)

export const getFormData = (form: UseFormReturn<z.infer<typeof formSchema>>, pathToFolder: string, domain): FormData => {
	const { locales, thumbnail } = form.getValues()
	const formData = new FormData()
	const transformedLocales = {}

	//FIXME: Возможно не стоит приводить к формату а сохранять в том виде в котором есть
	// тогда не придется парсить при получении
	for (const [key, locale] of Object.entries(locales)) {
		transformedLocales[key] = {
			tags: locale.tags.map(tag => tag.value),
			chapters: locale.chapters.slice(1).map(({ title }, idx) => ({
				title,
				data_path: `${key}/chapter_${idx + 1}.md`
			})),
			data_path: locale.data ? `${key}/data.md` : '',
			title: locale.title,
			categories: locale.categories.map(el => el.value)
		} satisfies ITransformedLocales
	}

	//FIXME: Возможно не стоит приводить к формату а сохранять в том виде в котором есть
	// тогда не придется парсить при получении
	Object.entries(locales).forEach(([key, locale]) => {
		locale.chapters.slice(1).forEach(({ data }, idx) => {
			const chapter = new File([data!], `${key}/chapter_${idx + 1}.md`)
			formData.append('file', chapter, `/${pathToFolder}/${key}/chapter_${idx + 1}.md`)
		})

		const data = new File([locale.data!], `${key}/data.md`)
		formData.append('file', data, `/${pathToFolder}/${key}/data.md`)
	})

	const data = yaml.stringify({ locales: transformedLocales, thumbnail })
	const info = new File([data], 'info')

	formData.append('file', info, `/${pathToFolder}/info.yaml`)
	formData.append('domain', domain)

	return formData
}

export const getNameData = (controlledFields: IControlledFields[], currentChapter: string) => {
	const idx = controlledFields.findIndex((el, idx) => el.id === currentChapter && idx !== 0)
	return idx > 0 ? `chapters.${idx}.data` : `data`
}

export const createChapters = chapters => {
	const newChapter = {
		title: '',
		data: '',
		id: Math.random().toString()
	}
	return !chapters.length ? [systemChapter, newChapter] : [...chapters, newChapter]
}

export const removeStrFromData = (data: string, name: string) => {
	// Разбиваем вручную текст на массив "строк" по определению блоков ([ и ])
	const result = []
	let current = ''
	let insideBrackets = false

	for (const char of data) {
		if (char === '[') {
			insideBrackets = true // Начало ссылки
		}
		if (char === ']' && insideBrackets) {
			current += char // Закрываем блок
			insideBrackets = false
			continue
		}
		current += char

		if (!insideBrackets && char === ')') {
			// Конец блока URL
			result.push(current)
			current = ''
		}
	}

	// Если остался незакрытый блок, добавляем
	if (current.trim()) {
		result.push(current)
	}

	// Удаляем строки с нужным именем файла
	const filteredResult = result.filter(line => !line.includes(name))

	// Объединяем оставшиеся строки обратно
	const updatedData = filteredResult.join('').trim()

	return updatedData
}

export const removeStrFromChapters = (chapters: IChapter[], name: string) => {
	const updateChapters = chapters.map(obj => {
		// Если объект содержит поле 'data' и оно включает имя файла
		if (obj.data && obj.data.includes(name)) {
			// Разбиваем строку 'data' на части и фильтруем
			const result = []
			let current = ''
			let insideBrackets = false

			for (const char of obj.data) {
				if (char === '[') {
					insideBrackets = true // Начало ссылки
				}
				if (char === ']' && insideBrackets) {
					current += char // Закрытие блока
					insideBrackets = false
					continue
				}
				current += char

				if (!insideBrackets && char === ')') {
					// Конец блока URL
					result.push(current)
					current = ''
				}
			}

			// Если остался незакрытый блок, добавляем
			if (current.trim()) {
				result.push(current)
			}

			// Фильтруем строки, содержащие имя файла
			const filteredResult = result.filter(line => !line.includes(name))

			// Обновляем поле 'data' в объекте
			obj.data = filteredResult.join('').trim()
		}

		return obj
	})

	return updateChapters
}
