import './index.css'
import ReactDOM from 'react-dom/client'
import { Providers } from './provider'
import { AppRouter } from './routers'

ReactDOM.createRoot(document.getElementById('root')!).render(
		<Providers>
			<AppRouter />
		</Providers>
)
