import { ContentStore } from '@/entities/feed-block/model/content-store'
import { CommentPayload, ContentType } from '@/entities/feed-block/model/types'
import { fixLengthPreview, getCurrentFullComment, getCurrentTitles } from '@/entities/feed-block/model/utils'
import { UserCard, UserStore } from '@/entities/user'
import ReactionsPreview from '@/features/reaction-preview/ui/reaction-preview'
import { cn, sortDesc, useTheme } from '@/shared/lib'
import useRequest from '@/shared/lib/use-request'
import { useSubscribe } from '@/shared/lib/use-subscribe'
import { ConfigStore, LangStore } from '@/shared/model/store'
import { Card, TimeAgo, CardContent } from '@/shared/ui'
import { UserName } from '@/shared/ui/user-name/ui/user-name'
import { getContentFromIpfs } from '@/widgets/post/lib/get-content-from-yaml'
import { ButtonLike } from '@/widgets/post/ui/button-like'
import { SimpleComment } from '@/widgets/post/ui/simple-сomment'
import MarkDownPreview from '@uiw/react-markdown-preview'
import { FileSymlink, MessageCircle, MessageCirclePlus, MessageCircleX, Pencil, RefreshCw, Reply } from 'lucide-react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import remarkBreaks from 'remark-breaks'

type Props = {
	content: ContentType
	level: number
	parrent?: string
	isPost?: boolean
}

export const CommentCard = ({ content, level, parrent, isPost }: Props) => {
	const { domain, baseUrl } = ConfigStore()
	const [parced, setParced] = useState<any>(null)
	const { lang, langPack } = LangStore()
	const { signatureAddress } = UserStore()
	const { commentList, setAddCommentPayload, commentPayload, setCommentList } = ContentStore()
	const [loadingMore, setLoadingMore] = useState<boolean>(false)
	const { message } = useSubscribe('react')

	const { theme } = useTheme()

	const { fetch, isLoading, dropState } = useRequest('getContentChildrensList')
	// const [offsetByMain, setOffsetByMain] = useState<number>(0)

	const ch: ContentType[] = commentList.filter(
		(el: ContentType) => el.savva_content.parent_savva_cid === content.savva_cid
	)
	const childrens = ch.sort(sortDesc)
	const preview = getCurrentTitles(content.savva_content.locales, 'text_preview')
	const fullData = parced ? getCurrentFullComment(parced) : null
	const isOverSize = preview.slice(-3) === '...'

	const isCurrentGuid = commentPayload.guid === content.guid

	const getUserYaml = async () => {
		setLoadingMore(true)
		try {
			const data = await getContentFromIpfs(`${baseUrl}ipfs/${content?.ipfs}`)
			setParced(data)
		} finally {
			setLoadingMore(false)
		}
	}

	const setMore = () => {
		if (!parced) {
			getUserYaml()
		} else {
			setParced(null)
		}
	}

	const addCommentHandler = (isEdit = false) => {
		setAddCommentPayload(
			isCurrentGuid
				? ({} as CommentPayload)
				: {
						guid: content.guid,
						isEdit,
						rootData: {
							root_savva_cid: content.savva_content.root_savva_cid,
							parent_savva_cid: parrent || content.savva_content.root_savva_cid
						}
					}
		)
	}

	const showMore =
		commentList.filter((el: ContentType) => el.savva_content.parent_savva_cid === content.savva_cid).length < 1 &&
		content.total_childs > 0

	const loadChildrens = () => {
		if (isLoading) return
		if (!showMore) {
			const filtred = commentList.filter((el: ContentType) => el.savva_content.parent_savva_cid !== content.savva_cid)
			setCommentList(filtred)
			dropState()
			return
		}

		fetch({
			params: {
				domain: domain,
				savva_cid: content.savva_cid,
				max_deep: 1,
				my_addr: signatureAddress,
				limit: 1000
			},
			onSuccess(res) {
				const filtred = res.list.filter((el: ContentType) => el.savva_cid !== content.savva_cid)
				setCommentList([...commentList, ...filtred])
			}
		})
	}

	useEffect(() => {
		if (!message) return
		if (message.object_id === content.savva_cid) {
			const lst = [...commentList]
			const index = lst.findIndex((el: ContentType) => el.savva_cid === content.savva_cid)

			if (index > -1) {
				lst[index] = {
					...lst[index],
					reactions: message.data.reactions,
					...(message.user.address === signatureAddress && { my_reaction: message.data.my_reaction })
				}

				setCommentList(lst)
			}
		}
	}, [message])

	return (
		<Card className={cn('mb-4 w-full rounded-md border-none p-0 shadow-none', level < 1 && 'bg-card')}>
			<CardContent className='relative mt-2 flex flex-row items-start gap-3 p-0'>
				<div className='flex justify-between pl-4 pt-2'>
					<UserCard user={content.author} descriprion={<TimeAgo date={content.timestamp} />} onlyAvatar />
				</div>
				<div
					className={cn(
						'relative flex w-full flex-col rounded-2xl bg-transparent px-4 pb-5 pt-2'
						// level < 1 ? 'shadow-md' : 'p-0'
					)}
				>
					<div className='flex flex-row items-center justify-between'>
						<div className='flex flex-row items-center gap-2'>
							<Link to={`/${content.author.address}`} className='cursor-pointer'>
								<UserName account={content.author} sliceLen={30} />
							</Link>
							{level < 1 && !isPost && (
								<>
									<div className='text-[12px] text-[grey]'>{langPack('post.comment_for')}</div>
									<Link
										to={`/content/${content.savva_content.root_savva_cid}`}
										className='text-[12px] font-bold uppercase text-[grey]'
									>
										{fixLengthPreview(getCurrentTitles(content.savva_content.root_post?.locales, 'title'), 50)}
									</Link>
								</>
							)}
						</div>
						<TimeAgo date={content.timestamp} />
					</div>
					<MarkDownPreview
						source={fullData ?? preview}
						remarkPlugins={[remarkBreaks]}
						style={{ backgroundColor: 'inherit', marginTop: 8 }}
						wrapperElement={{
							'data-color-mode': theme
						}}
					/>
					{isOverSize && (
						<div
							className='mt-1 flex cursor-pointer flex-row rounded-[5px] text-[12px] text-[grey] hover:opacity-70'
							onClick={setMore}
						>
							{loadingMore && <RefreshCw className='mr-2 h-4 w-4 animate-spin' />}{' '}
							{!parced ? langPack('post.more') : langPack('post.collapse')}
						</div>
					)}
					<div className='mt-5 flex w-full flex-row items-center gap-4'>
						<div className='flex flex-row items-center gap-1 text-[grey]'>
							<MessageCircle color='grey' size={15} />
							<span className='text-[12px]'>{content.total_childs}</span>
						</div>
						{level < 6 ? (
							<div className='flex cursor-pointer flex-row items-center gap-1' onClick={() => addCommentHandler(false)}>
								{!isCurrentGuid ? (
									<>
										<Reply color='grey' size={15} />
										<span className='text-[12px] text-[grey]'>{langPack('action.replay')}</span>
									</>
								) : (
									<>
										<Reply size={15} color='grey' />
										<span className='text-[12px] text-[grey]'>{langPack('action.cancel')}</span>
									</>
								)}
							</div>
						) : (
							<Link to='' className='flex cursor-pointer flex-row items-center gap-1'>
								<FileSymlink color='grey' size={15} />
								<span className='text-[12px] text-[grey]'>{langPack('comment.go_post')}</span>
							</Link>
						)}
						{content.author.address === signatureAddress && (
							<div className='flex cursor-pointer flex-row items-center gap-1' onClick={() => addCommentHandler(true)}>
								<Pencil color='grey' size={12} />
								<span className='text-[12px] text-[grey]'>{langPack('action.edit')}</span>
							</div>
						)}
						{level > 0 && content.total_childs > 0 && (
							<div className='flex cursor-pointer flex-row items-center gap-1' onClick={loadChildrens}>
								{isLoading ? (
									<>
										<RefreshCw className='mr-2 h-4 w-4 animate-spin' />
										<span className='text-[12px] text-[grey]'>{langPack('action.loading')}</span>
									</>
								) : (
									<>
										{!showMore ? (
											<MessageCircleX color='grey' size={15} />
										) : (
											<MessageCirclePlus color='grey' size={15} />
										)}
										<span className='text-[12px] text-[grey]'>
											{!showMore ? langPack('comment.less_answers') : langPack('comment.more_answers')}
										</span>
									</>
								)}
							</div>
						)}
						<div className='flex'>{signatureAddress && <ButtonLike activePost={content} ghost />}</div>
						<div className='ml-auto flex flex-row items-center'>
							<div className='mt-[2px] pl-6'>
								<ReactionsPreview reactions={content.reactions} />
							</div>
						</div>
					</div>
					{isCurrentGuid && level < 6 && (
						<div className='mt-5 flex w-full flex-col'>
							<SimpleComment
								lang={lang}
								domain={domain}
								address={signatureAddress as `0x${string}`}
								state={content}
								drop={() => setAddCommentPayload({} as CommentPayload)}
							/>
						</div>
					)}
					{childrens.length > 0 && (
						<div className='mt-4 border-l-4 pt-4'>
							{childrens.map((el: ContentType) => (
								<CommentCard content={el} level={level + 1} parrent={content.guid} key={el.guid} />
							))}
						</div>
					)}
				</div>
			</CardContent>
		</Card>
	)
}
