import { Sheet, SheetContent, SheetTrigger } from '@/shared/ui/sheet'
import { FC, useState } from 'react'
import { ChatTrigger } from './chat-trigger'
import { ChatList } from './chat-list'
import { ChatBox } from './chat-box'
import { useChatList } from '../lib/hooks/use-chat-list'
import { useMessages } from '../lib/hooks/use-messages'

type Props = {
	userAddress?: string
	hiddenBage?: boolean
}

export const Chat: FC<Props> = ({ userAddress, hiddenBage = false }) => {
	const [isOpenChat, setIsOpenChat] = useState(false)

	useChatList()
	useMessages()

	return (
		<Sheet open={isOpenChat} onOpenChange={setIsOpenChat}>
			<SheetTrigger>
				<ChatTrigger hiddenBage={hiddenBage} userAddress={userAddress}/>
			</SheetTrigger>
			<SheetContent>
				<div className='flex size-full rounded-lg border'>
					<ChatList userAddress={userAddress} />
					<ChatBox setIsOpenChat={setIsOpenChat} />
				</div>
			</SheetContent>
		</Sheet>
	)
}
