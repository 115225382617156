import { SocketEventsTypes, SocketStore } from "../model/store/socket-store";


export const useSubscribe = (type: SocketEventsTypes) => {
    const { messages } = SocketStore()
    
    const message = messages[type] || null
    return {
        message
    }
}
