import { CommentCard } from '@/entities/comments/ui/comment'
import { ContentStore } from '@/entities/feed-block/model/content-store'
import { ContentType } from '@/entities/feed-block/model/types'
import { NoContent } from '@/entities/feed-block/ui/no-content'
import { UserStore } from '@/entities/user'
import useRequest from '@/shared/lib/use-request'
import { useSubscribe } from '@/shared/lib/use-subscribe'
import { ConfigStore } from '@/shared/model/store'
import { useEffect } from 'react'

export const CommentFeed = ({ author }: { author?: string }) => {
	const { domain } = ConfigStore()
	const { commentList, setCommentList } = ContentStore()
	const { userData } = UserStore()
	const { fetch, isLoading, result } = useRequest<{ list: ContentType[] }>(
		author ? 'commentLatestBy' : 'getCommentLatest'
	)
	const { message } = useSubscribe('content_processed')

	useEffect(() => {
		fetch({
			params: {
				domain: domain,
				...(userData?.address && { my_addr: userData.address }),
				...(author && { author }),
				limit: 1000,
				max_deep: author ? 2 : 1
			},
			onSuccess(res) {
				setCommentList(res.list)
			}
		})
	}, [])

	useEffect(() => {
		if (!message) return

		const old = [...commentList]
		const index = old.findIndex((el: ContentType) => el.savva_cid === message.content.savva_cid)
		if (index < 0) {
			old.unshift(message.content)
			setCommentList(old)
		} else {
			old[index] = message.content
			setCommentList(old)
		}
	}, [message])

	useEffect(() => {
		return () => {
			setCommentList([])
		}
	}, [])

	const rootComments = commentList.filter(
		(el: ContentType) =>
			el.savva_content.root_savva_cid.length > 0 &&
			el.savva_content.parent_savva_cid === el.savva_content.root_savva_cid
	)

	return (
		<div className='flex w-full'>
			{isLoading || result?.list?.length < 1 ? (
				<NoContent isLoading={isLoading} />
			) : (
				<div className='flex w-full flex-col'>
					{(rootComments || []).map((el: ContentType) => (
						<CommentCard content={el} key={el.savva_cid} level={0} />
					))}
				</div>
			)}
		</div>
	)
}
