import { Logo } from '@/features/logo'
import { MobileMenu } from './mibile-menu'
import { DesktopMenu } from './desktop-menu'

export const Header = () => {
	return (
		<header className='sticky top-0 z-20 border-b bg-card py-4'>
			<nav className='container flex items-center justify-between'>
				<Logo />
				<div className='hidden w-[70%] xl:block'>
					<DesktopMenu />
				</div>
				<div className='block xl:hidden'>
					<MobileMenu />
				</div>
			</nav>
		</header>
	)
}
