import { LangStore } from '@/shared/model/store'
import { IChat } from '../model/types'
import { getName } from '@/shared/ui/user-name/ui/user-name'

const { langPack } = LangStore.getState()

export const getLastMessage = (el: IChat) => {
	const last = el

	if (!el.author.address) {
		return {
			name: getName(el.owner, 22),
			text: langPack('chat.welcome')
		}
	}
	const lastText = last.text.length > 25 ? `${last.text.slice(0, 22)}...` : last.text
	return {
		name: getName(last.author, 22),
		text: el.deleted ? langPack('chat.message_deleted') : el.banned ? langPack('chat.message_blocked') : lastText
	}
}
