import { Brave, Rubby } from '@/shared/assets'
import { LangStore } from '@/shared/model/store';
import { Button, Card } from '@/shared/ui'
import { Download, ShieldQuestionIcon } from 'lucide-react'
import { useParams } from 'react-router-dom';

export const DownloadWallet = () => {
    const { langPack } = LangStore()
    const { id } = useParams();
    
    const checkAlready = () => {
        window.location.replace(`/promo-code/${id}?step=no_wallet`)
    }
  
  return (
    <div>
        <div className='text-center'>
            {langPack('promo_code.promo_info_four_sec')}
        </div>
        <div className='flex flex-row justify-between mt-10'>
            <Card className='flex flex-col w-[48%] p-10 items-center shadow-xl overflow-hidden'>
                <div className='flex flex-row gap-5 relative'>
                    <div className='absolute -left-[400px] -top-[20px] z-0'>
                        <Rubby className='h-[320px]'/>
                    </div>
                    <div className='text-[50px]'>
                        RUBBY
                    </div>
                </div>
                <div className='mt-6 z-10'>
                    <a href="https://support.rabby.io/hc/en-us/articles/11476915889807-Get-started-with-Rabby-extension" target='__blank' >
                        <Button variant='link' className='flex flex-row items-center gap-4'>
                            <ShieldQuestionIcon />  {langPack('promo_code.hot_to')}
                        </Button>
                    </a> 
                    <a href="https://rabby.io/" target='__blank'>
                        <Button variant='link' className='flex flex-row items-center gap-4'>
                            <Download />  {langPack('promo_code.download')}
                        </Button>
                    </a> 
                </div>                
            </Card>
            <Card className='flex flex-col gap-5 w-[48%] p-10 items-center shadow-xl overflow-hidden'>
                <div className='flex flex-row gap-5 relative'>
                    <div className='absolute -left-[280px] top-[290px]'>
                        <Brave className='scale-[4]'/>
                    </div>  
                    <div className='text-[50px]'>
                        BRAVE 
                    </div>
                </div>
                <div className=''>
                    <a href="https://support.brave.com/hc/en-us/articles/360025390311-How-do-I-download-and-install-Brave" target='__blank' >
                        <Button variant='link' className='flex flex-row items-center gap-4'>
                            <ShieldQuestionIcon />  {langPack('promo_code.hot_to')}
                        </Button>
                    </a> 
                    <a href="https://brave.com/download/" target='__blank'>
                        <Button variant='link' className='flex flex-row items-center gap-4'>
                            <Download />  {langPack('promo_code.download')}
                        </Button>
                    </a> 
                </div>   
            </Card>
        </div>
        <div className='flex w-full items-center justify-center mt-10'>
            <Button onClick={checkAlready}> {langPack('promo_code.uploaded')}</Button>
        </div>
    </div>
  )
}