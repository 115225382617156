import { getAppDetails } from '@/shared/api'
import { AppDetailStore } from '@/shared/model/store'

export const getCurrentAppDetails = async (configUrl: string) => {
	const {setAppDetails} = AppDetailStore.getState()
	try {
		const appDetails = await getAppDetails(configUrl)
		if (!appDetails.ok) throw new Error(appDetails.error)

		const locales = appDetails.data.locales.map(({ code, dictionary }) => ({
			lang: code,
			url: `${configUrl}${dictionary}`
		}))
		setAppDetails(appDetails.data)
		return { appDetails: appDetails.data, locales }
	} catch (error) {
		console.error('Error: Missing app details:', error)
		throw error
	}
}
