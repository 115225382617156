import { SavvaIcon } from '@/shared/assets'
import { LangStore } from '@/shared/model/store'
import { Card, CardContent, CardTitle } from '@/shared/ui'
import ContributeModal from './contribute-modal'

const EmptyFundBox = () => {
	const { langPack } = LangStore()

	return (
		<div className='flex w-full flex-col'>
			<Card className={'relative h-auto w-full overflow-hidden shadow-lg'}>
				<CardTitle className='mt-2 flex items-center justify-center text-[15px]'>{langPack('fund.title')}</CardTitle>
				<CardContent className='flex flex-col items-center justify-center'>
					<div className='text-[14px] font-bold leading-4'>{langPack('fund.empty_fund_title')}</div>
					<div className='mt-4 text-[13px] leading-4 text-[grey]'>{langPack('fund.empty_fund_one')}</div>
					<div className='mt-4 text-[13px] leading-4 text-[grey]'>{langPack('fund.empty_fund_two')}</div>
					<div className='right-[85px] z-30 mt-6 min-w-28'>
						<ContributeModal />
					</div>
					<div className='absolute bottom-[40px] right-[85px] z-10 scale-[13] opacity-[0.1] grayscale'>
						<SavvaIcon />
					</div>
				</CardContent>
			</Card>
		</div>
	)
}

export default EmptyFundBox
