import { useCallback, useState } from 'react'
import {
	Button,
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
	Input,
	Label,
	Preloader,
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue
} from '@/shared/ui'
import { Settings } from 'lucide-react'
import { AppDetailStore, AppInfoStore, ConfigStore } from '@/shared/model/store'
import { LangStore } from '@/shared/model/store/lang-store'
import { getAppDetails, getAppInfo, getLangPack } from '@/shared/api'
import { URL } from 'url'
import axios from 'axios'
import { DEFAULT_ASSETS_ROOT } from '@/shared/config'
import { apiClient } from '@/shared/api/api-client'
import { useDebounceCallback } from 'usehooks-ts'
import { toast } from '@/shared/lib'
import { getAbis } from '@/widgets/post-editor/lib/utils'
import { UserStore } from '@/entities/user'

export const AppSetup = () => {
	const { baseUrl, domain, setBaseUrl, setDomain, defaultConnect } = ConfigStore()
	const { appInfo, setAppInfo } = AppInfoStore()
	const { setAppDetails } = AppDetailStore()
	const { setI18n, setLang, langPack } = LangStore()
	const { setSignatureAddress, setUserData } = UserStore()

	const [value, setValue] = useState(baseUrl)
	const [selectDomain, setSelectDomain] = useState(domain)
	const [currentAppInfo, setCurrentAppInfo] = useState(appInfo)
	const [isLoading, setIsLoading] = useState(false)

	const setNewConfig = async () => {
		setIsLoading(true)
		const assets_cid = currentAppInfo.domains.find(el => el.name === selectDomain)?.assets_cid
		const configUrl = assets_cid ? `${currentAppInfo.assets_url}/$selectDomain}/` : DEFAULT_ASSETS_ROOT

		try {
			const newAppDetails = await getNewAppDetails(configUrl)

			const urls = newAppDetails.locales.map(({ code, dictionary }) => ({
				lang: code,
				url: `${configUrl}${dictionary}`
			}))

			const newI18n = await getNewI18n(urls)
			setUserData(null)
			setSignatureAddress(null)
			setBaseUrl(value)
			setDomain(selectDomain)
			setSelectDomain(selectDomain)
			setAppInfo(currentAppInfo)
			setAppDetails(newAppDetails)
			getAbis(appInfo)
			setSignatureAddress(null)
		
			setUserData(null)

			setI18n(newI18n)
			setLang(newAppDetails.default_locale)

		// 	//TODO: Возможно удалим
			apiClient.setBaseURL(value)
		} catch (error) {
			if (axios.isAxiosError(error) || error instanceof Error) {
				toast({
					title: error.message,
					variant: 'destructive'
				})
			}
		}

		setIsLoading(false)

		window.location.reload()
	}

	const getNewI18n = async urls => {
		const response = await getLangPack(urls)

		if (response.ok) {
			return response.data
		} else {
			throw new Error(response.error)
		}
	}

	const getNewAppDetails = async (configUrl: string) => {
		const response = await getAppDetails(configUrl)

		if (response.ok) {
			return response.data
		} else {
			throw new Error(response.error)
		}
	}

	const getNewAppInfo = useCallback(async (value: string) => {
		// setSelectDomain('')
		try {
			new URL(value)
			const response = await getAppInfo(value)

			if (response.ok) {
				setCurrentAppInfo(response.data)
			} else {
				setCurrentAppInfo(state => ({ ...state, domains: [] }))
				throw new Error(response.error)
			}
		} catch (error) {
			if (error instanceof Error) {
				setCurrentAppInfo(state => ({ ...state, domains: [] }))
				toast({
					title: error.message,
					variant: 'destructive'
				})
			}
		}
	}, [])

	const debouncedGetNewAppInfo = useDebounceCallback(getNewAppInfo, 1000)

	const handleChange = event => {
		const newValue = event.target.value
		setValue(newValue)
		debouncedGetNewAppInfo(newValue)
	}

	const returnState = () => {
		setValue(baseUrl)
		setSelectDomain(domain)
	}

	if (isLoading) return <Preloader />

	return (
		<Dialog>
			<DialogTrigger asChild>
				<Button variant='outline' size='icon'>
					<Settings />
				</Button>
			</DialogTrigger>
			<DialogContent onCloseAutoFocus={returnState}>
				<DialogHeader>
					<DialogTitle>{langPack('app_setup.title')}</DialogTitle>
					<DialogDescription>{langPack('app_setup.description')}</DialogDescription>
				</DialogHeader>
				<div>
					<div className='mb-3'>
						<div className='mb-1 items-center gap-1'>
							<Label htmlFor='back-end'>Back-end</Label>
							<Input value={value} onChange={handleChange} />
						</div>

						<Button variant='secondary' onClick={() => setValue(defaultConnect.backendLink)}>
							{langPack('app_setup.set_default_url')}
						</Button>
					</div>
					{currentAppInfo.domains.length > 0 && (
						<Select onValueChange={setSelectDomain} defaultValue={selectDomain}>
							<SelectTrigger>
								<SelectValue />
							</SelectTrigger>
							<SelectContent>
								<SelectGroup>
									{currentAppInfo.domains.map(domain => (
										<SelectItem key={domain.name} value={domain.name}>
											{domain.name}
										</SelectItem>
									))}
								</SelectGroup>
							</SelectContent>
						</Select>
					)}
				</div>
				<DialogFooter className='sm:justify-start'>
					{currentAppInfo.domains.length > 0 && (
						<Button type='button' onClick={setNewConfig} disabled={!selectDomain}>
							{langPack('action.save')}
						</Button>
					)}
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}
