import { Input } from './input'

const LabelInput = ({label, ...props}) => {
  return (
    <div className='flex flex-row items-center border-[1px] rounded-lg overflow-hidden'>
        <div className='px-[10px]'>{label}</div>
        <Input className='border-0 border-l-[1px] rounded-none'  {...props}/>
    </div>
  )
}

export default LabelInput