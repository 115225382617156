import { Wallet } from '@/entities/wallet'
import { PlsIcon } from '@/shared/assets'
import { AppInfoStore, LangStore } from '@/shared/model/store'
import { Button, Card } from '@/shared/ui'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { useEffect } from 'react'
import { useAccount } from 'wagmi'

const Chain = ({ setNext }: {setNext: (chain: number) => void}) => {
    const { appInfo } = AppInfoStore()
    const { langPack } = LangStore()
    const {  chain, address } = useAccount()

    useEffect(() => {
		const getNetworkId = async () => {
			const ver2 = await window.ethereum.request({
				method: 'net_version'
			})

			if (Number(ver2) === appInfo.blockchain_id) {
				setNext(Number(ver2))
			}
		}
		getNetworkId()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

    useEffect(() => {
        if(chain?.id === appInfo.blockchain_id) {
            setNext(chain?.id)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chain])
    
  return (
    <div className='flex flex-col items-center mt-10'>
        <div>
            {langPack('promo_code.promo_info_three')}
        </div>
        <Card className='mt-20 px-20 py-10 relative overflow-hidden flex flex-col items-center'>
            <div className='text-center text-[40px]'>PULSECHAIN</div>
            <PlsIcon className='absolute scale-[6] -left-[20px] top-[130px]' />
            <div className='mt-10'>
               {
               address ? 
                    <ConnectButton.Custom>
                        {({ openChainModal }) => {		
                            return (
                                <Button onClick={openChainModal}>{langPack('promo_code.change_network')}</Button>
                            )
                        }}
                    </ConnectButton.Custom>
                : <Wallet />   
                }
            </div>
        </Card>
    </div>
  )
}

export default Chain