import { ContentStore } from '@/entities/feed-block/model/content-store'
import { ContentType } from '@/entities/feed-block/model/types'
import { fixLengthPreview, getCurrentTitles } from '@/entities/feed-block/model/utils'
import ReactionsPreview from '@/features/reaction-preview/ui/reaction-preview'
import { SavvaIcon } from '@/shared/assets'
import { cn, parceEthAmount, shortNumberFormat } from '@/shared/lib'
import useRequest from '@/shared/lib/use-request'
import { AppDetailStore, ConfigStore, LangStore } from '@/shared/model/store'
import { IBlock } from '@/shared/model/types'
import { TimeAgo } from '@/shared/ui'
import { AmountBlock } from '@/shared/ui/amount-block/amount-block'
import { CardTitle, Card, CardContent } from '@/shared/ui/card'
import { UserName } from '@/shared/ui/user-name'
import { MessageCircle } from 'lucide-react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

type Props = {
	data: IBlock
	className?: string
}

export const AnnounceBlock = ({ data, className }: Props) => {
	const { lang, langPack } = LangStore()
	const { setActivePost } = ContentStore()
	const { domain } = ConfigStore()
	const [currentContentList, setCurrentContentlist] = useState<IBlock | null>(null)
	const { appDetails } = AppDetailStore()
	const { modules } = appDetails
	const { fetch, result } = useRequest<{ list: ContentType[] }>('getList')

	useEffect(() => {
		const currentContentlistSplited = {
			...modules.content_lists.list?.[data.list_name],
			...data
		}
		setCurrentContentlist(currentContentlistSplited)
		fetch({
			params: {
				domain: domain,
				list_name: 'main',
				limit: data.count
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data])

	// if (!modules.content_lists.list ) return null

	return (
		<Card className={cn('mb-3 w-full', className)}>
			<CardTitle className='border-b p-2 pl-4 text-[18px]'>{currentContentList?.title?.[lang]}</CardTitle>

			<CardContent className='p-0'>
				{result?.list.map((content: ContentType) => (
					<Link
						className='flex w-full flex-col border-b'
						to={`/content/${content.savva_cid}`}
						key={content.savva_cid}
						state={content}
						onClick={() => setActivePost(content)}
					>
						<div className='px-4 pt-[10px]'>
							<div className='flex flex-row justify-between'>
								<object style={{ display: 'flex' }}>
									<Link to={`/${content.author.address}`} className='flex flex-row justify-between pr-1'>
										<UserName account={content.author} sliceLen={content.fund.amount > 0 ? 25 : 34} />
									</Link>
								</object>
								{content.fund.amount > 0 && (
									<div className='flex flex-row items-center text-[14px] font-[400]'>
										{/* <span className='mr-1 text-[12px] uppercase text-[grey]'>{langPack('fund.title')}:</span> */}
										<AmountBlock amount={content.fund.amount} size='sm'/>
									</div>
								)}
							</div>
							<TimeAgo date={content.timestamp} />
							<h2 className='text-md font-semibold text-gray-800 dark:text-gray-50'>
								{fixLengthPreview(getCurrentTitles(content.savva_content.locales, 'title'), 38)}
							</h2>
							<p className='mt-1 text-[14px] text-gray-500 break-words'>
								{fixLengthPreview(getCurrentTitles(content.savva_content.locales, 'text_preview'), 70)}
							</p>
						</div>
						
						<div className='flex w-full flex-row items-center justify-between px-4 py-2'>
							<div className='row flex items-center gap-1 text-[grey]'>
								<MessageCircle color='grey' size={15} />
								<span className='text-[12px]'>{content.total_childs}</span>
							</div>
							<div className='mt-[2px] pl-4'>
								<ReactionsPreview reactions={content.reactions} />
							</div>
						</div>
					</Link>
				))}
			</CardContent>
		</Card>
	)
}
