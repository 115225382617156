import { Button, Card, Checkbox, Label} from '@/shared/ui'
import { useEffect, useState } from 'react'
import { OPTIONS_HISTORY } from './utils'
import { LangStore } from '@/shared/model/store'
import { Separator } from '@radix-ui/react-dropdown-menu'


type FilterType = {contracts: string, type: string}

const CascadeFilter = ({setSelectOptions} : { setSelectOptions: (data: FilterType) => void} ) => {
    const [selected, setSelected] = useState<string[]>([])
    const [current, setCurrent] = useState<string>('staking')
    const { langPack } = LangStore()
    const hist = OPTIONS_HISTORY(langPack)

    const setValueHandler = (data) => {
        const { value, title } = data
        if(value.includes('all_')) {
            const curr = value.split('_').slice(1).join('_')
            const detailed = hist.find(el => el.value === curr)?.children.map(el => el.value) || []
            if(selected.includes(value)) {
                const filtred = selected.filter((el: string) => el !== curr || detailed.includes(el))
                const filtred2 = filtred.filter((el: string) => !detailed.includes(el))
                setSelected([...new Set(filtred2)])
            } else {
                setSelected(oldarray => [...new Set([...oldarray, value, ...detailed])])
            }
        } else {
            if(selected.includes(value)) {
                const filtred = selected.filter((el: string) => el !== value)
                const filtred2 = filtred.filter((el: string) => el !== `all_${title}`)
                setSelected([...new Set(filtred2)])
            } else {
                setSelected(oldarray => [...new Set([...oldarray, value])])
            }
        }
    }

    useEffect(() => {
        setSelectOptions({
            contracts: selected.filter((el: string) => el.includes('all_')).map((el: string) => el.replace('all_', '')).join(','),
            type: selected.filter((el: string) => !el.includes('all_')).map((el: string) => el).join(',')
        })
    }, [selected])

    const currentList =  hist.find(el => el.value === current)?.children || []

    return (
        <Card className='flex flex-row gap-10 px-6 py-4 mb-5 bg-[current]'>
            <div>
                <div className='border-b-2 pb-2 mb-2'>Type</div>
                <Separator />
                <div className='flex flex-col flex-wrap gap-4'>
                    {hist.map(el => (
                        <Button className='flex items-center justify-start space-x-2' variant={el.value === current ?  'orange' : 'secondary'} size={'sm'} onClick={() => setCurrent(el.value)}>
                                <Label
                                    htmlFor='secure'
                                    className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 cursor-pointer'
                                >
                                    {el.label}
                                </Label>
                        </Button>
                    ))}
                </div>
            </div>
            <div className='flex w-full flex-col'>
                <div className='border-b-2 pb-2 mb-2'>Detailed</div>
                <Separator />
                <div className='flex flex-col flex-wrap gap-4 mt-1'>
                    {
                       currentList.map(el => (
                            <div className='flex items-center space-x-2'>
                            <Checkbox checked={selected.includes(el.value)} onClick={() => setValueHandler(el)} id={el.value} />
                            <Label
                                htmlFor='secure'
                                className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
                            >
                                {el.label}
                            </Label>
                    </div>
                        ))
                    }
                </div>
            </div>
        </Card>
    )
}

export default CascadeFilter