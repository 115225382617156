import { IUser } from '@/entities/user/model/types'
import { cn, getEllipsisTxt } from '../../../lib'
import CheckMark from '../assets/check-mark.png'
import StakedLevelIcon from './staked-level-icon'

type Props = {
	account: IUser
	sliceLen?: number
	className?: string
	classNameBox?: string
	extra?: JSX.Element
}

export const getName = (data: IUser, sliceLen: number = 20) => {
	if (!data || !data.address) return ''

	switch (true) {
		case !!data.display_name.length:
			// eslint-disable-next-line no-case-declarations
			const displayName =
				data.display_name.length > sliceLen && sliceLen !== 0
					? `${data.display_name.slice(0, sliceLen)}...`
					: data.display_name
			return displayName
		case !!data.name.length:
			return data.name.length ? data.name.slice(0, sliceLen) : ''
		default:
			return getEllipsisTxt(data.address, 4)
	}
}

export const UserName = ({ account, sliceLen, className, extra, classNameBox }: Props) => {
	if (!account || !account.address) return <></>

	return (
		<div className={cn('flex flex-row items-center justify-between', classNameBox && classNameBox)}>
			<div className='flex flex-row items-center gap-2'>
				<div className={cn('text-[12px] uppercase text-[grey]', className)}>{getName(account, sliceLen)}</div>
				{!!account.name && account.display_name.length < 1 && (
					<div>
						<img src={CheckMark} alt='check-mark' width={14} height={14} />
					</div>
				)}
				{Number(account.staked) > 0 && (
					<div className='text-[10px]'>
						<StakedLevelIcon staked={Number(account.staked)} />
					</div>
				)}
			</div>
			{extra && extra}
		</div>
	)
}
