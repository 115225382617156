import { Card } from '@/shared/ui'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/shared/ui/tabs'
import { useState } from 'react'
import Wallet from './wallet'
import { IUser } from '@/entities/user/model/types'
import { IPFSProfileType } from '../profile'
import TransactionHistory from './history'
import PromoCode from './promo-code'
import { LangStore } from '@/shared/model/store'
import { UserStore } from '@/entities/user'

type Props = {
	profile: IUser & IPFSProfileType
}

const WalletMain = ({ profile }: Props) => {
	const { userData } = UserStore()
	const [currentTab, setCurrentTab] = useState<string>('balances')
	const { langPack } = LangStore()

	const isMy = userData?.address === profile.address

	return (
		<Card className='p-8 shadow-md'>
			<Tabs defaultValue='blog' className='w-full' value={currentTab} onValueChange={setCurrentTab}>
				<TabsList className={`grid w-full grid-cols-${isMy ? '3' : '2'}`}>
					<TabsTrigger value='balances'>{langPack('profile.wallet_balances')}</TabsTrigger>
					<TabsTrigger value='history'>{langPack('profile.wallet_history')}</TabsTrigger>
					{isMy && <TabsTrigger value='code'>{langPack('profile.wallet_code')}</TabsTrigger>}
				</TabsList>
				<TabsContent value='balances' className='p-2'>
					<Wallet profile={profile} />
				</TabsContent>
				<TabsContent value='history' className='p-2'>
					<TransactionHistory profile={profile} />
				</TabsContent>
				<TabsContent value='code' className='p-2'>
					<PromoCode profile={profile} />
				</TabsContent>
			</Tabs>
		</Card>
	)
}

export default WalletMain
