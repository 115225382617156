import { FC } from 'react'
import { EMOJI_LIST } from '../model/emoji-list'
import { Badge } from '@/shared/ui'
import { cn } from '@/shared/lib'

type Props = {
	reactions: number[]
}

export const Reactions: FC<Props> = ({ reactions = [] }) => (
	<div className='flex items-center gap-1'>
		{EMOJI_LIST.map(
			(el, idx) =>
				reactions[idx] !== 0 && (
					<Badge
						key={el}
						variant={'secondary'}
						className={cn('text-md flex h-8 w-10 cursor-default justify-center gap-1 rounded-sm')}
					>
						<div>{el}</div>
						{reactions[idx] > 1 && <div>{reactions[idx]}</div>}
					</Badge>
				)
		)}
	</div>
)
