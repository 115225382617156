import { IUser } from '@/entities/user/model/types'
import { useState } from 'react'
import { IPFSProfileType } from '../profile'
import { Card } from '@/shared/ui'
import { LangStore } from '@/shared/model/store'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/shared/ui/tabs'
import { General } from './general'
import { Sponsors } from './sponsors'

type Props = {
	profile: IUser & IPFSProfileType
	getUserFetch: () => void
}

const Settings = ({ profile, getUserFetch }: Props) => {
	const [currentTab, setCurrentTab] = useState<string>('general')
	const { langPack } = LangStore()

	return (
		<Card className='p-8 shadow-md'>
			<Tabs defaultValue='blog' className='w-full' value={currentTab} onValueChange={setCurrentTab}>
				<TabsList className='grid w-full grid-cols-2'>
					<TabsTrigger value='general'>{langPack('profile.general')}</TabsTrigger>
					<TabsTrigger value='sponsors'>{langPack('profile.sponsoring')}</TabsTrigger>
				</TabsList>
				<TabsContent value='general' className='p-2'>
					<General profile={profile} getUserFetch={getUserFetch} />
				</TabsContent>
				<TabsContent value='sponsors' className='p-2'>
					<Sponsors profile={profile} getUserFetch={getUserFetch} />
				</TabsContent>
			</Tabs>
		</Card>
	)
}

export default Settings
