import { create } from 'zustand'
import { IAppDetails } from '../types/app-details'

interface IAppDetailsStore {
	appDetails: IAppDetails
	setAppDetails: (state: IAppDetails) => void
}

export const AppDetailStore = create<IAppDetailsStore>(set => ({
	appDetails: {} as IAppDetails,
	setAppDetails: state => set({ appDetails: state })
}))
