import { FundBox } from '@/features/fund-block/ui/fund-box'
import { NftBlock } from '@/features/nft-block/ui/nft-block'

export const ContentSidebar = () => {
  return (
    <div>
        <NftBlock />
        <FundBox />
    </div>
  )
}
