import { useEffect } from 'react'
import { Card } from '@/shared/ui'
import { SavvaIcon } from '@/shared/assets'
import { UserStore } from '@/entities/user'
import { useNavigate, useParams } from 'react-router-dom'
import { LangStore } from '@/shared/model/store'
import { Auth } from '@/features/auth'

const AuthReedem = () => {
    const navigate = useNavigate()
    const { id } = useParams();
    const { userData } = UserStore()
    const { langPack } = LangStore()

    useEffect(() => {
        if(userData?.address) {
            navigate(`/promo-code/${id}?step=get_wallet&address=${userData.address}`);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData?.address])

  return (
    <div className='flex flex-col items-center mt-10'>
    <div>
        {langPack('promo_code.promo_info_one')}
    </div>
    <Card className='mt-20 px-20 py-10 relative overflow-hidden flex flex-col items-center'>
        <div className='text-center text-[40px]'> {langPack('promo_code.promo_item_desc_four')}</div>
        <SavvaIcon className='absolute scale-[6] -left-[20px] top-[130px]' />
        <div className='mt-10 gap-4 flex flex-row'>
           <Auth />
        </div>
    </Card>
</div>
  )
}

export default AuthReedem