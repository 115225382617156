import { Announces } from '@/features/announces/ui/announces'
import { LayoutSidebar } from '@/shared/layout'
import { CommentFeed } from '@/widgets/feed/ui/comment-feed'

export const CommentPage = () => {
	return (
		<LayoutSidebar sidebar={<Announces pageType={'comments'} />}>
			<CommentFeed />
		</LayoutSidebar>
	)
}
