import useRequest from '@/shared/lib/use-request'
import { ConfigStore, LangStore } from '@/shared/model/store'
import { Button, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/shared/ui'
import { UserStore } from '@/entities/user'
import { EMOJI_LIST } from '@/features/reactions/model/emoji-list'
import { cn, toast } from '@/shared/lib'
import { ContentType } from '@/entities/feed-block/model/types'

interface IButtonLike {
	activePost: ContentType
	ghost?: boolean
}

export const ButtonLike = ({ activePost, ghost }: IButtonLike) => {
	const { domain } = ConfigStore()
	const { fetch } = useRequest('sendReaction')
	const { setSignatureAddress } = UserStore()
	const { langPack } = LangStore()

	const REACTION_TITLE = [
		langPack('reactions.like'),
		langPack('reactions.super'),
		langPack('reactions.ha_ha'),
		langPack('reactions.sad'),
		langPack('reactions.angry'),
		langPack('reactions.wow'),
		langPack('reactions.trophy'),
		langPack('reactions.hot'),
		langPack('reactions.clap'),
		langPack('reactions.dislike'),
		langPack('reactions.all_reactions')
	]

	const onReaction = (idx: number) => {
		fetch({
			params: {
				'obj-type': '0',
				'obj-id': activePost?.savva_cid,
				reaction: idx,
				domain: domain
			},
			onError: () => toast({ title: 'Error' })
		})
	}

	return (
		<TooltipProvider>
			<Tooltip>
				<TooltipTrigger asChild>
					<Button
						size={ghost ? 'sm' : 'icon'}
						className={cn('rounded-none hover:text-primary', !ghost && 'w-full', ghost && 'mt-[2px] p-2 text-[12px]')}
						variant={ghost ? 'ghost' : activePost?.my_reaction === -1 ? 'ghost' : 'default'}
						onClick={() => onReaction(activePost?.my_reaction !== -1 ? -1 : 0)}
						disabled={!setSignatureAddress}
					>
						<div className='flex items-center gap-1 '>
							<div className={cn(ghost ? 'text-xl' : 'text-lg', activePost?.my_reaction === -1 && 'grayscale')}>
								{EMOJI_LIST[activePost?.my_reaction === -1 ? 0 : activePost?.my_reaction || 0]}
							</div>
							<div>{REACTION_TITLE[activePost?.my_reaction === -1 ? 0 : activePost?.my_reaction]}</div>
						</div>
					</Button>
				</TooltipTrigger>
				<TooltipContent className='flex w-full gap-0.5 bg-secondary'>
					{EMOJI_LIST.map((el, idx) => (
						<Button onClick={() => onReaction(idx)} size='icon' key={idx} variant={'ghost'} className='text-3xl'>
							{el}
						</Button>
					))}
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	)
}
