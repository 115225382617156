import { useEffect, useState } from 'react'

export const HtmlContentBlock = ({ configUrl, el, lang }) => {
	const [htmlContent, setHtmlContent] = useState('')

	useEffect(() => {
		fetch(`${configUrl}${el[lang] || el['*']}`)
			.then(response => response.text())
			.then(html => setHtmlContent(html))
	}, [configUrl, el, lang])

	return (
		<div
			dangerouslySetInnerHTML={{ __html: htmlContent }}
			className='mb-3 w-full rounded-lg border bg-card p-3 shadow-sm [&_h1]:mb-4 [&_h1]:font-bold [&_p]:mb-2'
		/>
	)
}
