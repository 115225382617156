import { AppDetailStore, LangStore } from "@/shared/model/store"
import { ContentLocales } from "./types"
import { ILocaleForContent } from "@/widgets/post/model/types"

export const getCurrentTitles = (locales: ContentLocales, key: string) => {
	if(!locales) return 'ERROR LOCALES'
	const currentLanguage = LangStore.getState().lang
	const preferredLanguage = AppDetailStore.getState().appDetails.default_locale
	const keys = Object.keys(locales)

	switch (true) {
		case keys.includes(currentLanguage):
			return locales[currentLanguage]?.[key]
		case keys.includes(preferredLanguage):
			return locales[preferredLanguage]?.[key];
		default:
			// eslint-disable-next-line no-case-declarations
			const [filter] = keys.filter((el: string) => el !== preferredLanguage || el !== currentLanguage) || ['en']

			return locales[filter]?.[key] || 'ERROR'
	}
}

export const getToastTitle = (locales: ContentLocales) => {
	if(!locales) return 'ERROR LOCALES'
	const currentLanguage = LangStore.getState().lang
	const preferredLanguage = AppDetailStore.getState().appDetails.default_locale
	const keys = Object.keys(locales)

	switch (true) {
		case keys.includes(currentLanguage):
			return locales[currentLanguage]
		case keys.includes(preferredLanguage):
			return locales[preferredLanguage];
		default:
			// eslint-disable-next-line no-case-declarations
			const [filter] = keys.filter((el: string) => el !== preferredLanguage || el !== currentLanguage) || ['en']

			return locales[filter] || 'ERROR'
	}
} 

export const getCurrentFullComment = (locales: ILocaleForContent) => {
	if(!locales) return 'ERROR LOCALES'
	const currentLanguage = LangStore.getState().lang
	const preferredLanguage = AppDetailStore.getState().appDetails.default_locale
	const keys = Object.keys(locales)

	switch (true) {
		case keys.includes(currentLanguage):
			return locales[currentLanguage]?.chapters[0].data
		case keys.includes(preferredLanguage):
			return locales[preferredLanguage]?.chapters[0].data
		default:
			// eslint-disable-next-line no-case-declarations
			const [filter] = keys.filter((el: string) => el !== preferredLanguage || el !== currentLanguage) || ['en']

			return locales[filter]?.chapters[0].data || 'ERROR'
	}
}

export const getCurrentCats = (locales: ContentLocales) => {
	if(!locales) return ['ERROR LOCALES']
	const currentLanguage = LangStore.getState().lang

	const preferredLanguage = AppDetailStore.getState().appDetails.default_locale
	const keys = Object.keys(locales)
	const first = keys[0]

	switch (true) {
		case keys.includes(currentLanguage):
			return locales[currentLanguage]?.categories
		case keys.includes(preferredLanguage):
			return locales[preferredLanguage]?.categories;
		default:
			// eslint-disable-next-line no-case-declarations
			return locales[first]?.categories || ['ERROR']
	}
}

export const fixLengthPreview = (d: string, length: number = 80) => {
	if (!d) return
	return `${d.slice(0, length).trim()}${d.length > length ? '...' : ''}`
}