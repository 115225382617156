import { FC } from 'react'
import { Announcements } from '@/features/announcements'
import { Button, Popover, PopoverContent, PopoverTrigger } from '@/shared/ui'
import { Ellipsis } from 'lucide-react'
import { AuthorContentBlocker } from './author-content-blocker'
import { ContentType } from '@/entities/feed-block/model/types'

type Props = {
	activePost: ContentType
	domain: string
	onAction?: () => void
}

export const AdminPanel: FC<Props> = ({ activePost, domain, onAction }) => {
	const { savva_cid, author: {banned}, banned: contentBanned } = activePost
	return (
		<Popover>
			<PopoverTrigger asChild>
				<Button size='icon' variant='secondary'>
					<Ellipsis />
				</Button>
			</PopoverTrigger>
			<PopoverContent className='p-0'>
				{!banned && !contentBanned &&<Announcements savva_cid={savva_cid} />}
				<AuthorContentBlocker content={activePost} domain={domain} type='content' onAction={onAction} />
				<AuthorContentBlocker content={activePost} domain={domain} type='author' onAction={onAction} />
			</PopoverContent>
		</Popover>
	)
}
