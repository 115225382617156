import { AppInfoStore } from '@/shared/model/store'
import defaultAvatar from '@/shared/assets/images/avatar-default.svg'
import { ContentType } from '@/entities/feed-block/model/types'

type Props = {
    content: ContentType
}

const ContentImage = ({content}: Props) => {
  const { appInfo } = AppInfoStore()
  
  const imageSrc = content.savva_content.thumbnail.length
    ?`${appInfo?.ipfs_gateways[0]}${content.savva_content.thumbnail}`
    : content.author.avatar.length ? `${appInfo?.ipfs_gateways[0]}${content.author.avatar}` : defaultAvatar;
  
  return (
    <img
        className="h-[190px] w-full object-cover md:w-60"
        src={imageSrc}
        alt="Event image"
    />
  )
}

export default ContentImage;