import Web3  from 'web3'
import { createWalletClient, custom, parseSignature } from 'viem';
import { ContracNames, ContractsStore } from "../model/store/contracts-store";
import {
    readContract
  } from '@wagmi/core';
import { ethers } from 'ethers';
import { UserStore } from "@/entities/user";
import { AppInfoStore, ConfigStore } from "../model/store";
import axios from "axios";
import sha3 from 'js-sha3';
import { availableNetwork, getWeb3config } from '@/app/model';


interface IMessage {
  owner: `0x${string}`
  spender: `0x${string}`
  value: string
  nonce: string
  deadline: string
}

export const createKeccakHash = (str: string) => {
  const packed = [...Buffer.from(str)];

  const hash = sha3.keccak256(packed);
  return `0x${hash}`;
};

export const parseAmount = (amount: number | string) => `0x${Web3.utils.toBigInt(Web3.utils.toWei(amount.toString(), 'ether')).toString(16)}`;

export const contractRead = async (contractName: ContracNames, functionName: string, args?: string[]) => {
    const { contracts } = ContractsStore.getState()
    const config = getWeb3config()
    const {address, abi} = contracts[contractName]
    return await readContract(config, {
        abi,
        address,
        functionName,
        args,
      })
}

export function stringToBytes32(str: string) {
    return ethers.encodeBytes32String(str);
}

export const getSplitTransaction = async data => {
  const userStore = UserStore.getState()
  const appStore = AppInfoStore.getState()
  const contractStore = ContractsStore.getState()
  const { appInfo } = appStore
  const { blockchain_id } = appInfo

  if(!userStore.userData?.address) return
  const nonce: number = await contractRead(data.contract ?? 'SavvaToken', 'nonces', [userStore.userData?.address]);
  const domain = {
    name: data.token,
    version: '1',
    chainId: blockchain_id,
    verifyingContract: contractStore.contracts![data.contract ?? 'SavvaToken'].address,
  };

  const types = {
    Permit: [
      { name: 'owner', type: 'address' },
      { name: 'spender', type: 'address' },
      { name: 'value', type: 'uint256' },
      { name: 'nonce', type: 'uint256' },
      { name: 'deadline', type: 'uint256' },
    ],
    EIP712Domain: [
      { name: 'name', type: 'string' },
      { name: 'version', type: 'string' },
      { name: 'chainId', type: 'uint256' },
      { name: 'verifyingContract', type: 'address' },
    ],
  };

  const message: IMessage = {
    owner: userStore.userData?.address,
    spender: data.spender,
    value: parseAmount(data.amount),
    nonce: nonce.toString(),
    deadline: (Math.round(+new Date()) + 3600).toString(),
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const requestData: any = {
    account: userStore.userData?.address,
    domain,
    types,
    primaryType: 'Permit',
    message,
  };

  const state = AppInfoStore.getState()
  const currentChain = availableNetwork[state.appInfo.blockchain_id]

  const walletClient = createWalletClient({
    chain: currentChain,
    transport: custom(window.ethereum!),
  })

  const permit: `0x${string}` = await walletClient.signTypedData(requestData);

  const { v, r, s } = parseSignature(permit);
  
  return {
    message,
    v,
    r,
    s,
  };
};

export const updloadToIpfs = async (text: string, fileInput?: File) => {
  const { baseUrl } = ConfigStore.getState();
  const body = text.toString();
  const blob = new Blob([body], { type: 'text/plain' });
  const file = fileInput || new File([blob], 'file_new');
  const formData = new FormData();
  formData.append('file', file);

  const headers = {
    'Content-type': 'multipart/form-data',
  };

  const axiosInstance = axios.create({
    timeout: 300000,
    headers,
  });

  const result = await axiosInstance.post(`${baseUrl}store`, formData);
  return result?.data.cid || '';
};

export const getContractData = (
  contract_name: ContracNames,
  functionName: string,
) => {
  const { contracts } = ContractsStore.getState()

  const {address, abi} = contracts[contract_name]

  return {
    address,
    abi,
    functionName,
  };
};