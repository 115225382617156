import { ChatStore } from '@/entities/chat'
import { ConfigStore } from '@/shared/model/store'
import { Badge } from '@/shared/ui'

type Props = {
    address: string | undefined | null
}

const LastReadBagde = ({address}: Props) => {
  const { lastRead } = ChatStore()
  const { domain} = ConfigStore()
  
  const key = `${address}:${domain}`

  if(!lastRead[key] || lastRead[key].n === lastRead[key].lastRead || !address || lastRead[key].n < 1) return <></>
  return (
    <Badge className='flex h-6 w-9 justify-center'>
      {lastRead[key].n - lastRead[key].lastRead < 100 ? lastRead[key].n - lastRead[key].lastRead : '99+'}
    </Badge>
  )
}

export default LastReadBagde