import { ProfileProps } from '../profile'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import useNotifier from '@/shared/lib/use-notifier'
import { ConfigStore, LangStore } from '@/shared/model/store'
import { UserStore } from '@/entities/user'
import { GuardButton } from '@/features/guard-button'

const FollowButton = ({ profile, getUserFetch, isSubscribed, if_follow_me, is_my, followers }: ProfileProps) => {
	const { contracts } = ContractsStore()
	const { domain } = ConfigStore()
	const { userData } = UserStore()
	const { langPack } = LangStore()

	const { writeContract: writeFollow, isLoading } = useNotifier({
		onSuccess() {
			getUserFetch && getUserFetch()
		}
	})

	const subscribe = async (addr: string) => {
		writeFollow({
			address: contracts.ContentRegistry.address,
			abi: contracts.ContentRegistry.abi,
			functionName: 'command',
			args: [domain, 'follow', addr, '', '', '']
		})
	}

	const unSubscribe = async (addr: string) => {
		writeFollow({
			address: contracts.ContentRegistry.address,
			abi: contracts.ContentRegistry.abi,
			functionName: 'command',
			args: [domain, 'unfollow', addr, '', '', '']
		})
	}

	if (userData?.address === profile.address) return <></>

	return (
		<div className='flex flex-row items-center gap-4'>
			{if_follow_me && !(is_my && followers) && (
				<div className='text-[12px] text-[grey]'>{langPack('profile.follow_me')}</div>
			)}
			{isSubscribed ? (
				<GuardButton
					onClick={() => unSubscribe(profile.address || '')}
					varinat='secondary'
					isLoading={isLoading}
					requireAuth
					title={langPack('profile.unfollow')}
					size='sm'
				/>
			) : (
				<GuardButton
					onClick={() => subscribe(profile.address || '')}
					varinat='secondary'
					isLoading={isLoading}
					requireAuth
					title={langPack('profile.follow')}
					size='sm'
				/>
			)}
		</div>
	)
}

export default FollowButton
