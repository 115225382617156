import { Helmet } from 'react-helmet'
import { AppDetailStore, ConfigStore } from '@/shared/model/store'
import { useInitApp } from '../lib'
import { ErrorComponent, LoadingComponent } from '../ui'

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const { isError, isLoading, style, textLoading, title, favicons } = useInitApp()

	const { appDetails } = AppDetailStore()
	const { assetsUrl } = ConfigStore()

	if (isLoading) return <LoadingComponent text={textLoading} />

	if (isError) return <ErrorComponent />

	return (
		<>
			<Helmet>
				{style && <style>{style}</style>}
				<title>{title}</title>
				{favicons.length &&
					favicons.map((key, index) => {
						switch (key) {
							case '16':
							case '32':
							case 'apple-touch-icon':
							case 'manifest':
							case 'base':
								return <link key={index} rel='icon' href={`${assetsUrl}${appDetails.favicon[key]}`} type='image/png' />
							case 'mask-icon':
								return (
									<link
										key={index}
										rel='mask-icon'
										href={`${assetsUrl}${appDetails.favicon['mask-icon'].href}`}
										color={appDetails.favicon['mask-icon'].color}
									/>
								)
							case 'meta':
								return appDetails.favicon.meta.map((metaItem, metaIndex) => (
									<meta key={`${index}-${metaIndex}`} name={metaItem.name} content={metaItem.content} />
								))
							default:
								console.error(`Неизвестный ключ favicon: ${key}`)
								return null
						}
					})}
			</Helmet>
			{children}
		</>
	)
}
