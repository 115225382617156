import useRequest from '@/shared/lib/use-request'
import { useEffect, useState } from 'react'

export const useGoveranceList = () => {
	const [governanceList, setGovernanceList] = useState([])

	const { fetch } = useRequest('getProposals')

	const getGoveranceList = async () => {
		await fetch({
			params: {
				active: true
			},
			onSuccess: setGovernanceList
		})
	}

	useEffect(() => {
		getGoveranceList()
	}, [])

	return { governanceList }
}
