import { UserCard } from '@/entities/user'
import { Button, Input } from '@/shared/ui'
import { FC, useEffect, useState } from 'react'
import { cn } from '@/shared/lib'
import { UserRoundSearch } from 'lucide-react'
import { ConfigStore, LangStore } from '@/shared/model/store'
import { getLastMessage } from '../lib/utils'
import { ChatStore } from '@/entities/chat'
import dayjs from 'dayjs'
import { UserName } from '@/shared/ui/user-name/ui/user-name'
import LastReadBagde from './last-read-bagde'
import { useChatList } from '../lib/hooks/use-chat-list'
import { ChatSkeleton } from './chat-skeleton'

type Props = {
	userAddress: string
}

export const ChatList: FC<Props> = ({ userAddress }) => {
	const [value, setValue] = useState('')
	const [countUnread, setCountUnread] = useState<number>(0)

	const { domain } = ConfigStore()
	const { langPack } = LangStore()
	const { lastRead, setActiveChat, activeChat, setLastRead, chats } = ChatStore()

	const { getChatList, isLoading } = useChatList()

	const filterChats = () => {
		if (!value) return chats
		const lowerValue = value.toLocaleLowerCase()

		return chats.filter(
			el =>
				el.owner.name.toLocaleLowerCase().includes(lowerValue) ||
				el.owner.display_name.toLocaleLowerCase().includes(lowerValue) ||
				el.owner.address.toLocaleLowerCase().includes(lowerValue)
		)
	}

	/* useEffect(() => {
		if (!chats.length || !!activeChat?.chat_id) return
		setActiveChat(chats.find(el => el.owner.address === userAddress) ?? chats[0])
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chats, userAddress]) */

	useEffect(() => {
		if(!lastRead) return

		let unread = 0
		
		for (const key in lastRead) {
			const current = lastRead[key].n - lastRead[key].lastRead
			unread += current
		}

		setCountUnread(unread)
	}, [lastRead])
	
	useEffect(() => {
		if (!activeChat?.chat_id) return
		
		const chat = lastRead[`${activeChat.chat_id}:${domain}`]

		//FIXME: убрать 1
		if (!chat) {
			setLastRead({
				...lastRead,
				...{ [`${activeChat.chat_id}:${domain}`]: { n: activeChat.n, lastRead: 1 } }
			})
		}
	}, [activeChat?.chat_id])

	const readAll = () => {
		const tmp = {...lastRead}
		for (const key in tmp) {
			tmp[key] = {
				...tmp[key],
				lastRead: tmp[key].n
			}
		}
		setLastRead(tmp)
	}

	useEffect(() => {
		getChatList()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userAddress])


	return (
		<aside className='h-full w-[380px] overflow-hidden overflow-y-auto border-r'>
			<div className='flex items-center gap-3 p-3 pl-2'>
				<UserRoundSearch className='mx-3' />
				<Input
					value={value}
					onChange={e => setValue(e.target.value)}
					placeholder={langPack('chat.input_placeholder')}
				/>
			</div>
			{countUnread > 0 && <div>
				<Button variant={'ghost'} className='w-full text-[grey] text-[13px]' onClick={readAll}>{`${langPack('chat.mark_as_read')} (${countUnread})`}</Button>
			</div>}
			<div>
				{isLoading ? <ChatSkeleton /> : filterChats().map((el) => (
					<div
						className={cn(
							activeChat?.chat_id === el.chat_id ? 'border-l-primary' : 'border-l-transparent',
							activeChat?.chat_id !== el.chat_id && 'hover:bg-secondary',
							'flex cursor-pointer justify-between gap-7 border-l-2 p-2 py-3 pl-2'
						)}
						onClick={() => setActiveChat(el)}
						key={el.chat_id}
					>
						<div className='flex items-center w-full flex-row justify-center'>
							<UserCard
								forTrigger
								user={el.owner}
							/>
							<div className='flex flex-col w-full ml-2 items-center justify-center relative -mt-1'>
									<UserName 
											account={el.owner}
											sliceLen={15} 
											className='w-full font-semibold text-left'
											classNameBox='w-full'
											extra={
												<div className='ml-auto text-xs text-muted-foreground'>
													{dayjs(el.timestamp).format('HH.mm')}
												</div>
											}
									/>
									<div className='flex flex-col gap-2 text-[grey] text-[12px] leading-[14px] w-full '>
										<div className='flex flex-row gap-2' >
											<UserCard
												forTrigger
												className='h-3 w-3 '
												user={el.author}
											/>
											<div>
												{getLastMessage(el).name}:
											</div>
										</div>
										<div className='-mt-2'>
											{getLastMessage(el).text}{` `}
										</div>
									</div>
									<div className='flex flex-col items-end justify-between absolute right-0 bottom-0'>
										<LastReadBagde address={el.chat_id}/>
									</div>
								</div>
						</div>
					</div>
				))}
			</div>
		</aside>
	)
}