import { Announces } from '@/features/announces/ui/announces'
import { LayoutSidebar } from '@/shared/layout'
import { ROUTER } from '@/shared/router'
import { Feed } from '@/widgets/feed/ui/feed'

export type FeedPageType = {
	pageType: (typeof ROUTER)[keyof typeof ROUTER]
}

export const FeedPage = ({ pageType }: FeedPageType) => {
	return <LayoutSidebar sidebar={<Announces pageType={pageType} />} children={<Feed pageType={pageType} />} />
}
