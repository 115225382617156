import { LangStore } from '@/shared/model/store'
import { Preloader } from '@/shared/ui'
import { CardDescription, CardTitle } from '@/shared/ui/card'
import { UserRoundX } from 'lucide-react'

export const NoUser = ({ isLoading }: { isLoading: boolean }) => {
	const { langPack } = LangStore()

	if (isLoading) return
	
	return (
		<div className='mt-3 flex size-full flex-col items-center justify-center rounded-lg border-[1px] border-dashed'>
			{isLoading ? (
				<Preloader small />
			) : (
				<div className='flex flex-col items-center justify-center'>
					<UserRoundX size={'52px'}/>
					<CardTitle className='mt-4'>{langPack('profile.no_user_title')}</CardTitle>
					<CardDescription>{langPack('profile.no_user_description')}</CardDescription>
				</div>
			)}
		</div>
	)
}
