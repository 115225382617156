import useRequest from '@/shared/lib/use-request'
import {
	Button,
	DialogDescription,
	DialogTitle,
	Drawer,
	DrawerClose,
	DrawerContent,
	DrawerFooter,
	DrawerTrigger,
	FormField,
	FormItem
} from '@/shared/ui'
import { CloudUpload } from 'lucide-react'
import { AppInfoStore, ConfigStore, LangStore } from '@/shared/model/store'
import { UseFormReturn, useWatch } from 'react-hook-form'
import { FileInput, FileUploader, FileUploaderContent, FileUploaderItem } from '@/shared/ui/filte-input'
import { dropzone, formSchema } from '../model/model'
import { z } from 'zod'
import { FileCard } from './file-card'
import { removeStrFromChapters, removeStrFromData } from '../lib/utils'

type Props = {
	form: UseFormReturn<z.infer<typeof formSchema>>
	pathToFolder: string
	contentLang: string
}

export const FileDrawer: React.FC<Props> = ({ form, pathToFolder, contentLang }) => {
	const { appInfo } = AppInfoStore()
	const { domain, baseUrl } = ConfigStore()

	useWatch({ name: `locales.${contentLang}.currentChapter` })
	useWatch({ name: `locales.${contentLang}.data` })
	useWatch({ name: 'thumbnail' })

	const { setValue, getValues } = form
	const { thumbnail } = getValues()
	const { chapters, currentChapter, data } = getValues().locales[contentLang]

	const { fetch: fetchDeleteFile } = useRequest('deleteFile')
	const { fetch: fetchUploadFiles } = useRequest('uploadUserFiles')
	const { fetch: fetchFiles } = useRequest('getUserFiles')
	const { langPack } = LangStore()

	const deleteAllFiles = async () => {
		await fetchDeleteFile({
			params: {
				path: `/${pathToFolder}/uploads/`
			},
			onSuccess: () => {
				setValue('files', [])
			}
		})
	}

	const getFiles = async () => {
		await fetchFiles({
			params: {
				path: `/${pathToFolder}/uploads/`
			},
			onSuccess: res => {
				setValue('files', res.files)
			}
		})
	}

	const onValueChange = async (values: File[] | null) => {
		if (!values?.length) return

		const formData = new FormData()
		const existingFiles = form.getValues().files ?? []
		const newFiles = values.filter(file => !existingFiles.some(existingFile => existingFile.name === file.name))

		newFiles.forEach(el => {
			const fileName = el.name.replace(/\s+/g, '_')
			const file = new File([el], fileName)
			formData.append('file', file, `/${pathToFolder}/uploads/${fileName}`)
		})

		formData.append('domain', domain)

		await fetchUploadFiles({
			data: formData,
			isMultipart: true,
			onSuccess: async () => {
				await getFiles()
			}
		})
	}

	const addFile = (value: string) => {
		const editor = document.querySelector('.w-md-editor');
	
		if (editor) {
			const textarea = editor.querySelector('textarea');
	
			if (textarea) {
				// Сохраняем текущее положение курсора
				const startPos = textarea.selectionStart;
				const endPos = textarea.selectionEnd;
	
				// Вставляем новый текст на позицию курсора
				const currentText = textarea.value;
				const newText = currentText.slice(0, startPos) + value + currentText.slice(endPos);
	
				// Обновляем данные формы
				const idx = chapters.findIndex(el => el.id === currentChapter);
	
				if (idx < 1) {
					form.setValue(`locales.${contentLang}.data`, newText);
				} else {
					form.setValue(
						`locales.${contentLang}.chapters.${idx}.data`,
						newText
					);
				}
	
				// Восстанавливаем курсор после вставки
				setTimeout(() => {
					textarea.focus(); // Фокусируемся на textarea
					const newCursorPos = startPos + value.length; // Сдвигаем курсор на длину вставленного текста
					textarea.setSelectionRange(newCursorPos, newCursorPos);
				}, 0);
			}
		}
	};
	

	const deleteFile = async (name: string) => {
		if (thumbnail.includes(name)) {
			setValue('thumbnail', '')
		}

		if (data.includes(name)) {
			const updatedData = removeStrFromData(data, name)
			setValue(`locales.${contentLang}.data`, updatedData)
		}

		if (chapters.some(chapter => chapter.data && chapter.data.includes(name))) {
			const updateChapters = removeStrFromChapters(chapters, name)

			setValue(`locales.${contentLang}.chapters`, updateChapters)
		}

		await fetchDeleteFile({
			params: {
				path: `${pathToFolder}/uploads/${name}`
			},
			onSuccess: async () => await getFiles()
		})
	}

	const setThumb = (value: string) => {
		setValue('thumbnail', `uploads/${value}`)
	}

	return (
		<Drawer>
			<DrawerTrigger asChild>
				<Button size={'icon'} className='w-full'>
					<CloudUpload />
				</Button>
			</DrawerTrigger>
			<DrawerContent className='flex max-h-[90%] min-h-[70%]'>
				<FormField
					control={form.control}
					name='files'
					render={({ field }) => {
						return (
							<FormItem>
								<FileUploader
									value={field.value}
									onValueChange={onValueChange}
									dropzoneOptions={dropzone(field.value, appInfo.max_user_disk_space)}
									className='overflow-auto p-5'
								>
									<FileInput>
										<div className='flex h-32 w-full flex-col items-center justify-center rounded-md border border-dashed bg-background'>
											<DialogTitle>
												<CloudUpload className='mx-auto' />
												<p className='mb-1 text-sm text-gray-500 dark:text-gray-400'>
													<span className='font-semibold'>Click to upload</span>
													&nbsp; or drag and drop
												</p>
											</DialogTitle>

											<DialogDescription>SVG, PNG, JPG or GIF</DialogDescription>
										</div>
									</FileInput>
									<FileUploaderContent className='flex flex-row flex-wrap place-content-center gap-2'>
										{field.value.map((file, i) => (
											<FileUploaderItem
												key={file.name}
												index={i}
												className='size-40 rounded-md p-0'
												aria-roledescription={`file ${i + 1} containing ${file.name}`}
											>
												<FileCard
													pathToFolder={pathToFolder}
													file={file}
													index={i}
													addFile={addFile}
													setThumb={setThumb}
													deleteFile={deleteFile}
												/>
											</FileUploaderItem>
										))}
									</FileUploaderContent>
								</FileUploader>
							</FormItem>
						)
					}}
				/>

				<DrawerFooter>
					<DrawerClose asChild>
						<Button className='w-full' variant={'secondary'}>
							{langPack('action.close')}
						</Button>
					</DrawerClose>
					<Button className='w-full' onClick={deleteAllFiles} variant={'destructive'}>
						{langPack('action.clear')}
					</Button>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	)
}
