import { PlsIcon, SavvaIcon } from '@/shared/assets'
import { cn, DEFAULT_PREC, parceEthAmount, shortNumberFormat } from '@/shared/lib'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../tooltip'
import { ChevronRight } from 'lucide-react'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import { useEffect, useState } from 'react'
import FormatNumber from '../format-number'

type Props = {
	amount: number | bigint
	size?: keyof AmountBlockSizeType
	perUsd?: boolean
	token?: 'SAVVA' | 'PLS'
	usdClassName?: string
	danger?: boolean
	short?: boolean
	description?: JSX.Element
}

type ABUT = {
	icon: string
	text: string
	gap: string
}

type AmountBlockSizeType = {
	sm: ABUT
	md: ABUT
	lg: ABUT
}

const ICON_SIZE: AmountBlockSizeType = {
	sm: {
		icon: 'scale-[0.7]',
		text: `text-[16px] font-bold`,
		gap: 'gap-0'
	},
	md: {
		icon: 'scale-[1.05]',
		text: `text-[28px] font-bold`,
		gap: 'gap-2'
	},
	lg: {
		icon: 'scale-[1.8]',
		text: `text-[54px] font-bold`,
		gap: 'gap-4'
	}
}

export const AmountBlock = ({
	amount,
	size = 'sm',
	perUsd = false,
	token = 'SAVVA',
	usdClassName,
	danger,
	short,
	description
}: Props) => {
	const { tokenPrice } = ContractsStore()
	const [blinkUsd, setblinkUsd] = useState<boolean>(false)
	const [blink, setblink] = useState<boolean>(false)

	useEffect(() => {
		setblink(true)
		setTimeout(() => setblink(false), 500)
	}, [amount])

	useEffect(() => {
		if (token !== 'SAVVA') return
		setblinkUsd(true)
		setTimeout(() => setblinkUsd(false), 500)
	}, [tokenPrice.savva_token_price])

	useEffect(() => {
		if (token !== 'PLS') return
		setblinkUsd(true)
		setTimeout(() => setblinkUsd(false), 500)
	}, [tokenPrice.base_token_price])

	const getIcon = () => {
		switch (token) {
			case 'SAVVA':
				return <SavvaIcon />
			case 'PLS':
				return <PlsIcon />
		}
	}

	return (
		<TooltipProvider>
			<Tooltip>
				<TooltipTrigger asChild>
					<div className={cn('flex flex-row items-center justify-center', ICON_SIZE[size].gap)}>
						<div className={cn(ICON_SIZE[size].icon, 'flex flex-row items-center')}>{getIcon()}</div>
						<div className='relative'>
							<div
								className={cn(
									'flex flex-row items-center',
									ICON_SIZE[size].text,
									blink && 'animate-flyin',
									danger && 'text-[red]'
								)}
							>
								{amount <= DEFAULT_PREC / 1000 && amount > 0 && !short && (
									<div className='font-[12px]'>
										<ChevronRight size={20} />
									</div>
								)}
								{short ? <FormatNumber number={amount} /> : shortNumberFormat(Number(parceEthAmount(amount)))}
							</div>
							{perUsd && (
								<div
									className={cn(
										'-mt-5 flex w-full flex-row items-center justify-end',
										short && '-mt-3',
										blinkUsd && 'animate-flyin',
										usdClassName && usdClassName
									)}
								>
									{`~$${(tokenPrice[token === 'SAVVA' ? 'savva_token_price' : 'base_token_price'] * Number(parceEthAmount(amount))).toFixed(2)}`}
								</div>
							)}
						</div>
					</div>
				</TooltipTrigger>
				<TooltipContent className='bg-gradient-to-bl from-sky-600 via-sky-800 to-sky-900 p-3'>
					{
						description || 
						<p className={cn('text-[20px] text-white', blink && 'animate-flyin')}>
							{parceEthAmount(amount)} {token}
						</p>
					}
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	)
}
