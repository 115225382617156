import { ConfigStore } from '@/shared/model/store'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import { useEffect, useState } from 'react'
import { useReadContract } from 'wagmi'
import { UserStore } from '../model/user-store'
import { useSubscribe } from '@/shared/lib/use-subscribe'

export const useSponsorsSumm = () => {
	const [sponsorsSumm, setSponsorsSumm] = useState<bigint | null>(null)
	const { contracts } = ContractsStore()
	const { domain } = ConfigStore()
	const { signatureAddress } = UserStore()

	const { message } = useSubscribe('club_buy')

	const { data, isSuccess, refetch } = useReadContract({
		abi: contracts.AuthorsClubs.abi,
		address: contracts.AuthorsClubs.address,
		functionName: 'claimableGain',
		args: [domain, signatureAddress]
	})

	useEffect(() => {
		if (isSuccess) {
			setSponsorsSumm(data)
		}

		if (message?.author.address === signatureAddress) {
			refetch()
		}
	}, [data, isSuccess, message])

	return { sponsorsSumm }
}
