import { FC } from 'react'
import { ChatInfo } from './chat-info'
import { SendBox } from './send-box'
import { Messages } from './messages'
import { useSponsorship } from '../lib/hooks/use-sponsorship'
import { ChatStore } from '@/entities/chat'

type Props = {
	setIsOpenChat: React.Dispatch<React.SetStateAction<boolean>>
}

export const ChatBox: FC<Props> = ({ setIsOpenChat }) => {
	const { mySponsorship } = useSponsorship()
	const { activeChat } = ChatStore()

	return (
		<div className='flex size-full flex-col'>
			<ChatInfo setIsOpenChat={setIsOpenChat} />
			{activeChat && (
				<>
					<main className='flex w-full flex-grow flex-col gap-3 overflow-y-auto overflow-x-hidden overscroll-contain px-5'>
						<Messages mySponsorship={mySponsorship} />
					</main>
					<SendBox mySponsorship={mySponsorship} />
				</>
			)}
		</div>
	)
}
