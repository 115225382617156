import { IUser } from '@/entities/user/model/types'
import useRequest from '@/shared/lib/use-request'
import { ConfigStore, LangStore } from '@/shared/model/store'
import { Card } from '@/shared/ui'
import { Link, MapPin } from 'lucide-react'
import { useEffect } from 'react'
import { IContentFilter, IPFSProfileType } from './profile'
import { cn } from '@/shared/lib'



type Props = {
    profile: IUser & IPFSProfileType
    filters: IContentFilter
    setFilters: (filter: IContentFilter) => void
}

function capitalize(s)
{
    return s[0].toUpperCase() + s.slice(1);
}


function ensureHttpPrefix(url: string) {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
        return 'https://' + url;
    }
    return url;
}

const SidebarProfile = ({profile, filters, setFilters}: Props) => {
    const { domain } = ConfigStore()
    const { lang, langPack } = LangStore()

    const { fetch: getCategories, result } = useRequest<string[]>('getUserCats');
    
    const { fetch: getTags, result: tags } = useRequest<string[]>('getUserTags');

    const filterHandler = (key: string, value: string) => {
        if(value === 'all') {
            setFilters((prev: IContentFilter) => ({...prev, [key]: ''}))
        }   else    {
            setFilters((prev: IContentFilter) => ({...prev, [key]: value}))
        }
    }

    useEffect(() => {
        if(!profile?.address) return
        getCategories({
            params: {
                domain: domain,
                user_addr: profile.address,
                locale: lang,
            }
        })
        getTags({
            params: {
                domain: domain,
                user_addr: profile.address,
                locale: lang,
            }
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile.address])

  return (
    <div>
        {(profile.location || profile.site) && 
            <Card className='p-4 gap-2 flex flex-col mb-3'>
                    {profile.location && 
                    <div>
                        <div className='text-[grey]'>{langPack('profile.location')}:</div>
                        <div className='flex flex-row gap-2 items-center'><MapPin size={16}/>{profile.location}</div>
                    </div>
                    }
                     {profile.site && 
                    <div className='flex flex-col'>
                        <div className='text-[grey]'>{langPack('profile.site')}:</div>
                        <a href={ensureHttpPrefix(profile.site)} className='flex flex-row gap-2 items-center'><Link size={16} />{ensureHttpPrefix(profile.site)}</a>
                    </div>
                    }

            </Card>
        }
        <Card className='p-4 gap-1 flex flex-col mb-3'>
            <div className='text-[grey]'>{langPack('profile.categories')}:</div>
            <div className={cn('text-[14px] cursor-pointer hover:opacity-80', filters.category === 'all' && 'font-bold')} onClick={() => filterHandler('category', 'all')}>{langPack('profile.all')}</div>
            {result?.map((el: string) => <div key={el} className={cn('text-[14px] cursor-pointer hover:opacity-80', filters.category === `${lang}:${el}` && 'font-bold')} onClick={() =>filterHandler('category', `${lang}:${el}`)}>{el}</div>)}
        </Card>
        <Card className='p-4 gap-1 flex flex-col'>
            <div className='text-[grey]'>{langPack('profile.tags')}:</div>
            <div className={cn('text-[14px] cursor-pointer hover:opacity-80', filters.tag === 'all' && 'font-bold')} onClick={() =>filterHandler('tag', 'all')}>{langPack('profile.all')}</div>
            {tags?.map((el: string) => <div key={el} className={cn('text-[14px] cursor-pointer hover:opacity-80', filters.tag === `${lang}:${el}` && 'font-bold')} onClick={() =>filterHandler('tag', `${lang}:${el}`)}>{capitalize(el.toLowerCase())}</div>)}
        </Card>
    </div>
  )
}

export default SidebarProfile