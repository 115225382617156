import { contractRead, createKeccakHash, DEFAULT_PREC, getEllipsisTxt, getSplitTransaction, useToast } from '@/shared/lib'
import useNotifier from '@/shared/lib/use-notifier'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import { useEffect, useState } from 'react'
import { formatEther } from 'viem'
import dayjs from 'dayjs'
import { AppInfoStore, ConfigStore, LangStore } from '@/shared/model/store'
import { Button, Card, Label } from '@/shared/ui'
import { Slider } from '@/shared/ui/slider'
import AmountInput from '@/shared/ui/amount-input/ui/amount-input'
import PromoBox from './promo-box'
import { Alert, AlertDescription, AlertTitle } from '@/shared/ui/alert'
import { Copy } from 'lucide-react'
import { ProfileProps } from '../profile'
import { publicClientWeb3 } from '@/app/model'

const tzOffset = new Date().getTimezoneOffset()

const generateArray = (length, max) => [...new Array(length)].map(() => Math.round(Math.random() * max))


const PromoCode = ({profile}: ProfileProps) => {
    const { contracts } = ContractsStore()
    const { domain } = ConfigStore()
    const { langPack } = LangStore()
	const { toast } = useToast()

	
    const [savvaAmount, setSavvaAmount] = useState<number>()
	const [plsAmount, setPlsAmount] = useState<number>()
	const [exparation, setExparation] = useState<number>(0)
	const [secretCode, setSecretCode] = useState<string | null>(null)
	const [promoHash, setPromoHash] = useState<string | null>('')
	const [savvaTokens, setSavvaTokens] = useState<bigint>(0n)
	const [plsTokens, setPlsTokens] = useState<bigint>(0n)
	const [fee, setFee] = useState<any>(0)
    const dropPromo = () => {
		setSecretCode(null)
		setPromoHash('')
	}

    const { writeContract: createPromoCode } = useNotifier({
		onSuccess: () => {
			dropPromo()
		},
	})

    const expDate: any = dayjs().utcOffset(tzOffset, true).add(exparation, 'days').format('DD.MM.YY  HH:mm:ss')

    const generateCode = () => {
		const nn = profile?.name ? profile?.name : getEllipsisTxt(profile?.address || '', 9, '-')
		const random = generateArray(12, 9).join('')
		const secret = `${nn}-${random}`
		setSecretCode(secret)
		const hash = createKeccakHash(secret)
		setPromoHash(hash)
	}

    const createPromo = async () => {
		const { message, v, r, s } = await getSplitTransaction({
			token: 'SAVVA',
			spender: contracts.Promo.address,
			amount: savvaAmount
		})

		createPromoCode({
            address: contracts.Promo.address,
            abi: contracts.Promo.abi,
            functionName: 'createPromoCodeWithPermit',
			args: [
				message.value,
				promoHash,
				dayjs().utcOffset(tzOffset, true).add(exparation, 'hours').unix(),
				message.deadline,
				v,
				r,
				s
			],
			value: BigInt(Number(plsAmount) * DEFAULT_PREC + Number(fee))
		})
	}

	const baseSiteUrl = AppInfoStore(state => state.appInfo.domains.find(el => el.name === domain)!.website)


    const redeemLink = `${baseSiteUrl}/promo-code/${promoHash}`
	
	const lowPls = (Number(plsTokens) / DEFAULT_PREC) > Number(plsAmount)
	const lowSavva = (Number(savvaTokens) / DEFAULT_PREC) > Number(savvaAmount)
	
	const lowAmount = (!(Number(savvaAmount) > 0) && !(Number(plsAmount) > 0)) || !lowPls || !lowSavva

    const copyAllData = () => {
		let text = `${langPack('profile.your_promo')}:\r\n`
		if (Number(savvaAmount) > 0) {
			text += `${savvaAmount} SAVVA\r\n`
		}
		if (Number(plsAmount) > 0) {
			text += `${plsAmount} PLS\r\n`
		}
		text += `Он доступен по ссылке ${redeemLink}\r\nдо ${expDate} \r\nSecret: ${secretCode}`

		navigator.clipboard.writeText(text)
        toast({
			duration: 5000,
			key: 'copy_promo',
			title: langPack('profile.copied_text'),
		})
	}

    useEffect(() => {
		const getData = async () => {
			const feeSize: bigint = await contractRead('Promo', 'processor_fee', [])
			setFee(feeSize)

			const tokensAmount: bigint = await contractRead('SavvaToken', 'balanceOf', [profile.address])
			setSavvaTokens(tokensAmount)

			const client = publicClientWeb3()

			const balance: bigint = await client.getBalance({
				address: profile.address as `0x${string}`
			})
			setPlsTokens(balance)
		}
		getData()
	}, [])

  return (
    <div className='flex flex-col'>
        <div className='flex flex-col gap-2 text-[grey] text-[14px] mb-6'>
			<span>{langPack('profile.promo_info_two')}</span>
			<span>{`${langPack('profile.promo_info_three')}: ${formatEther(fee)} PLS. ${langPack('profile.promo_info_four')}`}</span>
		</div>
		<div className='flex flex-row gap-5 justify-between'>
			<Card className='flex flex-col gap-5 w-[45%] px-6 py-4'>
				<div className='flex flex-row items-center gap-4'>
					<Label className='w-[100px]'>SAVVA</Label>
					<AmountInput
						value={savvaAmount} 
						onChange={setSavvaAmount}
						placeholder={langPack('profile.promo_info_five')}
						max={Number(savvaTokens) / DEFAULT_PREC}
					/>
				</div>
				<div className='flex flex-row items-center gap-4'>
					<Label className='w-[100px]'>PLS</Label>
					<AmountInput
						value={plsAmount} 
						onChange={setPlsAmount}
						placeholder={langPack('profile.promo_input_placeholder')}
						max={Number(plsTokens) / DEFAULT_PREC}
						token='PLS'
					/>
				</div>
			</Card>
			<Card className='flex flex-col items-center w-[45%] justify-between px-6 py-4 text-[14px] text-[grey]'>
				<div>
					{langPack('profile.promo_info_eight')}
				</div>
				<div className='flex flex-row justify-between w-full'>
					<div>{langPack('profile.promo_info_nine')}</div>
					<div>{langPack('profile.promo_info_ten')}</div>
				</div>
				<Slider
					defaultValue={[3]}
					max={90}
					min={1}
					step={1}
					onValueChange={(e) => setExparation([e])} 
				/>
				<div className='flex flex-row justify-between w-full'>
					<div>{langPack('profile.promo_info_nine')}</div>
					<div>{expDate}</div>
				</div>
			</Card>
		</div>
		{secretCode && (
				<div className='mt-6'>
					<Alert variant={'destructive'}>
						<AlertTitle>{langPack('promo_code.attention')}</AlertTitle>
						<AlertDescription>
							{langPack('profile.profile_info_eleven')}
						</AlertDescription>
						
					</Alert>
					<Card className='p-4 mt-4 relative bg-slate-100 text-[grey]'>
						<div className='absolute right-0 top-0'>
							<Button variant={'link'} onClick={copyAllData} className='gap-2'><Copy size={16}/> {langPack('profile.copy_code')}</Button>
						</div>
						<div className='flex flex-row gap-4 mt-4'>
							<div className='font-bold'>{langPack('profile.secret_text')}:</div>
							<div>{secretCode}</div>
						</div>
						<div className='mt-4 flex flex-row gap-4'>
							<div className='font-bold'>{langPack('profile.link')}:</div>
							<div>{redeemLink}</div>
						</div>
						<div className='flex flex-row gap-4 justify-end'>
							<Button onClick={createPromo} className='mt-4' disabled={lowAmount}>{langPack('profile.create')}</Button>
							<Button onClick={dropPromo} className='mt-4' variant={'destructive'}>{langPack('profile.reset')}</Button>		
						</div>
					</Card>
				</div>
			)}
		<div className='w-full'>
			{!secretCode && 
				<>
					<Button onClick={generateCode} className='mt-4 w-full' disabled={lowAmount}>{langPack('profile.generate')}</Button>
				</>
}
		</div>
		<div className='mt-6'>
			<PromoBox savva={savvaAmount * DEFAULT_PREC || 0} pls={plsAmount * DEFAULT_PREC} expDate={expDate} />
		</div>
	</div>
  )
}

export default PromoCode