import { ChatStore } from '@/entities/chat'
import SponsorModal from '@/pages/profile/ui/sponsors/sponsor-modal'
import { LangStore } from '@/shared/model/store'
import { FC } from 'react'
import { formatEther } from 'viem'

type Props = {
	chat_read_price: boolean
}

export const Permission: FC<Props> = ({ chat_read_price }) => {
	const { activeChat } = ChatStore()
	const { langPack } = LangStore()

	
	return (
		<div className='flex h-full flex-col items-center justify-center text-balance'>
			<div className='mb-2 text-center'>
				{langPack('chat.permission_message')} {formatEther(BigInt(chat_read_price))} SAVVA
			</div>
			<div className='w-max'>
				<SponsorModal profile={activeChat?.author} />
			</div>
		</div>
	)
}
