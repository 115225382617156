import { create } from 'zustand'
import { CommentPayload, ContentType } from './types'

interface IContentStore {
	contenList: ContentType[]
	commentList: ContentType[]
	setContentList: (state: ContentType[]) => void
	setCommentList: (state: ContentType[]) => void
	setActivePost: (state: ContentType) => void
	activePost: ContentType | null
	commentPayload: CommentPayload
	setAddCommentPayload: (state: CommentPayload) => void
}

export const ContentStore = create<IContentStore>(set => ({
	contenList: [],
	commentList: [],
	commentPayload: {} as CommentPayload,
	activePost: null,
	setContentList: (state: ContentType[]) => set({ contenList: state }),
	setCommentList: (state: ContentType[]) => set({ commentList: state }),
	setActivePost: (state: ContentType) => set({ activePost: state }),
	setAddCommentPayload: (state: CommentPayload) => set({ commentPayload: state }),
}))
