export const EMOJI_LIST = [
  "👍",
  "❤️",
  "😂",
  "😢",
  "😡",
  "😱",
  "🏆",
  "🔥",
  "👏",
  "👎",
]