import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface IConfigStore {
	domain: string
	baseUrl: string
	testMode: boolean
	assetsUrl: string
	gear: boolean
	defaultConnect: {
		domain: string
		backendLink: string
		default_ipfs_link: string
	}
	setAssetsUrl: (state: string) => void
	setDomain: (state: string) => void
	setBaseUrl: (state: string) => void
	setTestMode: (state: boolean) => void
	setGear: (state: boolean) => void
	
}

export const ConfigStore = create<IConfigStore>()(
	persist(
		(set) => ({
			domain: '',
			baseUrl: '',
			defaultConnect: {
				domain: '',
				backendLink: '',
				default_ipfs_link: '',
			},
			testMode: false,
			assetsUrl: '',
			gear: false,
			setDefaultConnect: state => set({ defaultConnect: state }),
			setDomain: state => set({ domain: state }),
			setGear: state => set({ gear: state }),
			setBaseUrl: state => set({ baseUrl: state }),
			setTestMode: state => set({ testMode: state }),
			setAssetsUrl: state => set({ assetsUrl: state })
		}),
		{
			name: 'config',
			partialize: state => ({ domain: state.domain, baseUrl: state.baseUrl, testMode: state.testMode })
		}
	)
)