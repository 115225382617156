import { ThemeProvider } from '@/features/theme-toggle'
import { Web3Provider } from './web3-provider'
import { QueryProvider } from './query-provider'
import { WalletProvider } from './wallet-provider'
import GlobalToast from '@/widgets/global-toast'
import { ReactNode } from 'react'
import { AppProvider } from './app-provider'

export const Providers = ({ children }: { children: ReactNode }) => {
	return (
		<AppProvider>
			<Web3Provider>
				<QueryProvider>
					<ThemeProvider defaultTheme='light' storageKey='savva-ui-theme'>
						<WalletProvider>
							<GlobalToast>{children}</GlobalToast>
						</WalletProvider>
					</ThemeProvider>
				</QueryProvider>
			</Web3Provider>
		</AppProvider>
	)
}
