import { WagmiProvider } from 'wagmi'
import { getWeb3config } from '../model'
import { AppInfoStore } from '@/shared/model/store'

export const Web3Provider = ({ children }) => {
	const { appInfo } = AppInfoStore()
	if (!appInfo.blockchain_id) return
	const config = getWeb3config()
	return <WagmiProvider config={config}>{children}</WagmiProvider>
}
