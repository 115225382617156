import { ChatStore } from '@/entities/chat'
import { Button } from '@/shared/ui'
import { MessageSquareText } from 'lucide-react'
import { FC, useEffect } from 'react'
import { ConfigStore } from '@/shared/model/store'
import { UserStore } from '@/entities/user'
import LastReadBagde from './last-read-bagde'

type Props = {
	hiddenBage: boolean
	userAddress?: string
}

export const ChatTrigger: FC<Props> = ({ hiddenBage, userAddress }) => {
	const { setLastRead, lastRead } = ChatStore()
	const { domain } = ConfigStore()
	const { signatureAddress } = UserStore()

	const { chats } = ChatStore()

	const key = `${signatureAddress}:${domain}`

	useEffect(() => {
		const mayChat = chats.find(el => el.chat_id === signatureAddress)
		if (!mayChat) return

		setLastRead({
			...lastRead,
			[key]: {
				lastRead: lastRead[key]?.lastRead || 0,
				n: mayChat.n
			}
		})
	}, [chats])

	return (
		<Button size={userAddress ? 'sm' : 'icon'} className='relative' variant={userAddress ? 'secondary' : 'outline'}>
			<MessageSquareText />
			{!hiddenBage && <div className='absolute end-[-14px] top-[-15px]'>
				<LastReadBagde address={signatureAddress} />
			</div>
}		</Button>
	)
}
