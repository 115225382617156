import { cn, useCountdown } from "@/shared/lib";
import { useEffect, useState } from "react";

interface ICountDown {
    targetDate: string | number
    onFinish?: (state: boolean) => void
}

interface IShowCounter {
    days: number
    hours: number
    minutes: number
    seconds: number
}

interface IDateTimeDisplay {
    noBorder?: boolean
    type: string
    value: string | number
}

const DateTimeDisplay = ({ value, type, noBorder }: IDateTimeDisplay) => (
    <div className={cn("flex flex-col items-center justify-center w-1/4 ", !noBorder && "border-r-[1px] border-slate-200")}>
      <p className="text-[20px]">{value}</p>
      <span className="text-[8px]">{type}</span>
    </div>
  );


  const ShowCounter = ({ days, hours, minutes, seconds }: IShowCounter) => (
      <div className="flex flex-row justify-center w-full text-black z-40 items-center bg-white p-2 rounded-lg">
        <DateTimeDisplay value={days < 10 ? `0${days}` : days} type="Days"  />
        <DateTimeDisplay value={hours < 10 ? `0${hours}` : hours} type="Hours"  />
        <DateTimeDisplay value={minutes < 10 ? `0${minutes}` : minutes} type="Mins"  />
        <DateTimeDisplay value={seconds < 10 ? `0${seconds}` : seconds} type="Seconds" noBorder/>
    </div>
  );

  
  export const CountdownTimer = ({ targetDate, onFinish }: ICountDown) => {
    const [days, hours, minutes, seconds] = useCountdown(targetDate);
    const [finish, setfinish] = useState<boolean>(false)

    useEffect(() => {
        setfinish(Number(targetDate) < Date.now() + 1000)
    }, [days, hours, minutes, seconds, targetDate])

    useEffect(() => {
      onFinish(finish)
    }, [finish, onFinish])

    if(finish) return <></>

    return (
        <ShowCounter
          days={days}
          hours={hours}
          minutes={minutes}
          seconds={seconds}
        />
      );
  }