import { NftIcon } from '@/shared/assets'
import { cn } from '@/shared/lib'
import { Card } from '@/shared/ui'

type Props = {
	children: React.ReactNode
	className?: string
}

const NftBox = ({ children, className }: Props) => {
	return (
		<Card className={cn('relative h-auto w-full p-4 shadow-lg', className)}>
			<div className='absolute -top-[14px] left-[42%] scale-125'>
				<NftIcon />
			</div>
			{children}
		</Card>
	)
}

export default NftBox
