import { DEFAULT_PREC } from "../lib"

interface IFormatNumber {
	number: number
	fontSize?: string
}

const FormatNumber = ({ number, fontSize }: IFormatNumber) => {
	const num = number / DEFAULT_PREC

	const modNumber = new Intl.NumberFormat('ru-RU', {
		...(num < 1 && { minimumSignificantDigits: 1 })
	})

	if (num < 1) {
		const value = modNumber.format(num).split(',')[1]
		const res = String(value).startsWith('000') ? value.replace(/[0]/g, '') : value

		return (
			<span style={{fontSize }}>
				{res === value ? (
					modNumber.format(num)
				) : (
					<>
						0.0
					<sub >{String(value).length - String(res).length}</sub>
						{res}
					</>
				)}
			</span>
		)
	}

	return modNumber.format(num)
}

export default FormatNumber
