import { UserCard } from '@/entities/user'
import { FC } from 'react'
import { LangStore } from '@/shared/model/store'
import { ChatStore } from '@/entities/chat'

type Props = {
	setIsOpenChat: React.Dispatch<React.SetStateAction<boolean>>
}

export const ChatInfo: FC<Props> = ({ setIsOpenChat }) => {
	const { langPack } = LangStore()
	const { activeChat } = ChatStore()

	if (!activeChat?.author) return null

	return (
		<div className='w-full border-b p-6 hover:bg-secondary' onClick={() => setIsOpenChat(false)}>
			<UserCard
				user={activeChat.owner}
				descriprion={
					<div className='flex gap-3'>
						<div className='flex gap-1'>
							<div>{langPack('profile.followings')}</div>
							<div>{activeChat.author?.n_following}</div>
						</div>
						<div className='flex gap-1'>
							<div>{langPack('profile.followers')}</div>
							<div>{activeChat.author?.n_followers}</div>
						</div>
					</div>
				}
			/>
		</div>
	)
}
