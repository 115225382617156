import { useTheme } from '@/shared/lib'
import { AppDetailStore, ConfigStore } from '@/shared/model/store'
import { Link } from 'react-router-dom'

export const Logo = () => {
	const { theme } = useTheme()
	const { appDetails } = AppDetailStore()
	const { domain } = ConfigStore()

	const logo = appDetails.logo?.[theme].includes('null') ? (
		<h1 className='font-extrabold tracking-tight text-primary text-2xl'>
			{domain}
		</h1>
	) : (
		<img src={appDetails.logo[theme]} alt='logo' height={50} width={120} className='h-full' />
	)

	return (
		<Link to={`/${appDetails.modules.tabs.tabs[0].type}`}>
			{logo}
		</Link>
	)
}
