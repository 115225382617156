import { ContentType } from '@/entities/feed-block/model/types'
import { GuardButton } from '@/features/guard-button'
import { stringToBytes32 } from '@/shared/lib'
import useNotifier from '@/shared/lib/use-notifier'
import useRequest from '@/shared/lib/use-request'
import cleanMDtext from '@/shared/lib/utils/utils'
import { ConfigStore, LangStore } from '@/shared/model/store'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import { Button } from '@/shared/ui'
import { Textarea } from '@/shared/ui/textarea'
import { useCheckStaking } from '@/widgets/post-editor/lib/hook/use-check-staking'
import { createCid } from '@/widgets/post-editor/lib/utils'
import { ContentBodyType } from '@/widgets/post-editor/model/types'
import { RefreshCw } from 'lucide-react'
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import yaml from 'yaml'
import { getContentFromIpfs } from '../lib/get-content-from-yaml'
import { ContentStore } from '@/entities/feed-block/model/content-store'
import { getCurrentFullComment } from '@/entities/feed-block/model/utils'

type Props = {
	state: ContentType
	address: `0x${string}`
	lang: string
	domain: string
	drop?: () => void
}

const createCommentPreview = (data: string) => (data?.length > 200 ? `${data.slice(0, 512)}...` : data);


export const SimpleComment: FC<Props> = ({ state, address, lang, domain, drop }) => {
	const [value, setValue] = useState('')
	const { isLowStake, minStake } = useCheckStaking({ address })
	const { langPack } = LangStore()
	const { contracts } = ContractsStore()
	const { baseUrl } = ConfigStore()
	const { commentPayload } = ContentStore()
	const { writeContract, isLoading: isLoadingWrite } = useNotifier({
		onSuccess: () => {
			setValue('')
			drop && drop()
		}
		
	})

	const isEdit = commentPayload.isEdit

	const { fetch, isLoading } = useRequest('uploadUserFiles')
	const { fetch: fetchIpfsUserDir, isLoading: isLoadingUserDir } = useRequest('ipfsUserDir')

	const addComment = () => {
		const formData = new FormData()
		const {
			savva_cid,
			savva_content: { root_savva_cid }
		} = state
		const rd = {
			parent_savva_cid: isEdit ? state.savva_content.parent_savva_cid : savva_cid,
			root_savva_cid: root_savva_cid ? root_savva_cid : savva_cid
		}

		const mainBody: ContentBodyType = {
			savva_spec_version: '2.0',
			mime_type: 'text/markdown',
			encoding: 'utf-8',
			license: 'CC0',
			author: address,
			thumbnail: '',
			locales: {
				[lang]: {
					text_preview: createCommentPreview(value),
					data_path: `${lang}/data.md`
				}
			},
			...rd
		}

		const data = new File([value], `${lang}/data.md`)
		formData.append('file', data, `/content/${lang}/data.md`)

		const yml = yaml.stringify(mainBody)
		const blob = new Blob([yml], { type: 'text/markdown' })
		const info = new File([blob], `/content/info.yaml`)

		formData.append('file', info, `/content/info.yaml`)
		formData.append('domain', domain)

		fetch({
			data: formData,
			isMultipart: true,
			onSuccess: async () =>
				await fetchIpfsUserDir({
					params: {
						path: 'content'
					},
					onSuccess: res => regContent(res.cid)
				})
		})
	}

	const getUserYaml = async () => {
        try{
            const data = await getContentFromIpfs(`${baseUrl}ipfs/${state.ipfs}`)
			setValue(getCurrentFullComment(data))
        } catch(e) {
			console.log('e', e)
        }
	}

	const regContent = async (cid: string) => {
		if (!address) return
		const { uid } = createCid(address, domain)
		const {
			guid,
		} = state

		writeContract({
			address: contracts.ContentRegistry.address,
			abi: contracts.ContentRegistry.abi,
			functionName: 'reg',
			args: [domain , address, isEdit ? guid : uid, cid, stringToBytes32(isEdit ? 'comment-edit' : 'comment')]
		})
	}

	useEffect(() => {
		if(commentPayload.isEdit) {
			getUserYaml()
		}
	}, [])

	if(isLoadingWrite) {
		return (
		<div className='flex flex-row text-[grey] items-center'>
			<RefreshCw className="mr-2 h-4 w-4 animate-spin" />
			<div>Send comment...</div>
		</div>
		)
	}

	return (
		<div>
			<Textarea value={value} onChange={e => setValue(e.target.value)} className='mb-3' />
			{isLowStake && (
				<p className='mb-3 text-sm text-muted-foreground'>
					{langPack('editor.increase_stake')} {minStake}
				</p>
			)}
			<div className='flex flex-row gap-4'>
				<GuardButton
					onClick={addComment}
					requireAuth
					title={langPack( isEdit ? 'action.save' : 'action.add')}
					isLoading={isLoading || isLoadingUserDir || isLoadingWrite}
				/>
				<Button
					variant='ghost'
					loading={isLoadingWrite}
				>
					<Link to={`/new-comment`} state={state}>
						{langPack('post.content_action')}
					</Link>
				</Button>
			</div>
		</div>
	)
}
