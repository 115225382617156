import { IUser } from '@/entities/user/model/types'
import { FC, useEffect, useState } from 'react'
import { IPFSProfileType } from '../profile'
import {
	Button,
	Input,
	Label,
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
	Textarea,
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
	useDebounce
} from '@/shared/ui'
import LabelInput from '@/shared/ui/label-input'
import { ConfigStore, LangStore } from '@/shared/model/store'
import { cn, contractRead, stringToBytes32, updloadToIpfs } from '@/shared/lib'
import { formatEther } from 'viem'
import useNotifier from '@/shared/lib/use-notifier'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import { useNavigate } from 'react-router-dom'
import { CircleHelp, RefreshCw } from 'lucide-react'

type Props = {
	profile: IUser & IPFSProfileType
	getUserFetch: () => void
}

export const DEFAULT_SPONSOR_VALUES = [5, 10, 25, 50, 100] 

export const General: FC<Props> = ({ getUserFetch, profile }) => {
	const navigate = useNavigate()
	const { langPack } = LangStore()
	const { domain } = ConfigStore()
	const { contracts } = ContractsStore()

	const [currentConfig, setcurrentConfig] = useState<IPFSProfileType>({} as IPFSProfileType)
	const [minContribute, setMinContribute] = useState<bigint | number>(0)
	const [changed, setChanged] = useState<boolean>(false)
	const [isCreated, setIsCreated] = useState<boolean>(false)
	const [isLoadingChekName, setIsLOadingChekName] = useState<boolean>(false)
	const [sponsorValues, setSponsorValues] = useState<number[]>(DEFAULT_SPONSOR_VALUES)

	const debouncedValue = useDebounce(currentConfig.name, 1000)

	const showType = [
		{ value: 'h', label: langPack('profile.nsfw_h') },
		{ value: 's', label: langPack('profile.nsfw_s') },
		{ value: 'w', label: langPack('profile.nsfw_w') }
	]

	const chatSendType = [
		{ value: 'Ctrl|Enter', label: 'Ctrl + Enter' },
		{ value: 'Enter', label: 'Enter' }
	]

	const { writeContract } = useNotifier({
		onSuccess: async () => {
			await getUserFetch()
			if (profile.name !== currentConfig.name) {
				const url = currentConfig.name ? `@${currentConfig.name}` : profile.address
				navigate(`/${url}/settings/edit-profile`, { replace: true })
			}
		}
	})

	useEffect(() => {
		const getOwners = async (name: string) => {
			setIsLOadingChekName(true)
			const address = await contractRead('UserProfile', 'owners', [name])

			setIsCreated(address !== '0x0000000000000000000000000000000000000000' && address !== profile.address)

			setIsLOadingChekName(false)
		}
		getOwners(currentConfig.name)
	}, [debouncedValue])

	const handleSubmit = async () => {
		setChanged(false)

		const newBody = {
			about: currentConfig.about,
			display_name: currentConfig.display_name,
			location: currentConfig.location,
			site: currentConfig.site,
			sponsor_values: sponsorValues,
			nsfw: currentConfig.nsfw,
			name: currentConfig.name,
			sendMessage: currentConfig.sendMessage,
			banner: currentConfig.banner
		}
		const body = JSON.stringify({ ...newBody })
		const res = await updloadToIpfs(body)

		writeContract({
			address: contracts.UserProfile.address,
			abi: contracts.UserProfile.abi,
			functionName: 'setAll',
			args: [currentConfig.name, profile.avatar, stringToBytes32(domain), res]
		})
	}

	const getMinContribute = async () => {
		const res = await contractRead('Config', 'getUInt', [stringToBytes32('minContribution')])

		const minContribute = res && typeof res === 'bigint' ? Number(formatEther(res)) : 0

		setMinContribute(minContribute)
	}

	const onChange = async (value: string, key: string) => {
		if (currentConfig[key] === value) return
		!changed && setChanged(true)
		
		setcurrentConfig(p => ({ ...p, [key]: value }))
	}

	const onChangeSponsorValues = (newValue: number, index: number) => {
		if (!changed) setChanged(true);

		setSponsorValues((prevValues: any) => 
				prevValues.map((value, i) => (i === index ? (newValue ? newValue : null) : value))
			);
	}

	useEffect(() => {
		if (!profile.sponsor_values) return
		setSponsorValues(profile.sponsor_values ??  DEFAULT_SPONSOR_VALUES)
	}, [])

	useEffect(() => {
		if (!profile) return
		getMinContribute()
		setcurrentConfig(profile)
	}, [profile])

	return (
		<div>
			<div className='grid grid-cols-2 grid-rows-2 gap-4'>
				<div>
					<Label className='flex flex-row items-center gap-1'>
						{langPack('profile.short_address')}
						<TooltipProvider>
							<Tooltip>
								<TooltipTrigger><CircleHelp size={14}/></TooltipTrigger>
								<TooltipContent side='right'>
								<p>The name must contain only lowercase latin letters, without special characters, spaces or numbers</p>
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
						{isCreated  && <div className='ml-[10px] text-[12px] text-[red]'>{langPack('profile.already_exist')}</div>}
					</Label>
					<LabelInput
						value={currentConfig.name}
						className={cn(isCreated && 'text-[red]')}
						label={
							isLoadingChekName ? (
								<div className='flex w-[90px] items-center justify-center'>
									<RefreshCw className='mr-2 h-4 w-4 animate-spin' />
								</div>
							) : (
								`${domain}/@`
							)
						}
						onChange={event => onChange(event.target.value, 'name')}
						onKeyDown={(event: any) => {
							if (['ArrowLeft', 'ArrowRight', 'Backspace'].includes(event.key)) {
								return
							}
							if (/[^a-z.-]/.test(event.key)) {
								event.preventDefault()
							}
						}}
					/>
				</div>
				<div>
					<Label>{langPack('profile.display_name')}</Label>
					<Input value={currentConfig.display_name} onChange={event => onChange(event.target.value, 'display_name')} />
				</div>
				<div>
					<Label>{langPack('profile.site')}</Label>
					<Input value={currentConfig.site} onChange={event => onChange(event.target.value, 'site')} />
				</div>
				<div>
					<Label>{langPack('profile.location')}</Label>
					<Input value={currentConfig.location} onChange={event => onChange(event.target.value, 'location')} />
				</div>

				<div>
					<Label>{langPack('profile.send_message')}</Label>
					<Select value={currentConfig.sendMessage} onValueChange={value => onChange(value, 'sendMessage')}>
						<SelectTrigger>
							<SelectValue placeholder='Select a type' />
						</SelectTrigger>
						<SelectContent>
							{chatSendType.map(el => (
								<SelectItem value={el.value}>{el.label}</SelectItem>
							))}
						</SelectContent>
					</Select>
				</div>
				<div>
					<Label>{langPack('profile.show_nsfw')}</Label>
					<Select value={currentConfig.nsfw} onValueChange={value => onChange(value, 'nsfw')}>
						<SelectTrigger>
							<SelectValue placeholder={langPack('profile.show_nsfw')} />
						</SelectTrigger>
						<SelectContent>
							{showType.map(el => (
								<SelectItem value={el.value}>{el.label}</SelectItem>
							))}
						</SelectContent>
					</Select>
				</div>
				<div className='col-span-2'>
					<Label>{langPack('profile.about')}</Label>
					<Textarea
						placeholder={langPack('profile.setting_textarea_placeholder')}
						onChange={event => onChange(event.target.value, 'about')}
						value={currentConfig.about}
					/>
				</div>
				<div className='col-span-2 row-start-5'>
					<Label>{langPack('profile.sponsor_values')}</Label>
					<div className='grid grid-cols-5 grid-rows-1 gap-4'>
						{sponsorValues.map((el: number, index: number) => (
							<Input
								type='number'
								onChange={event => onChangeSponsorValues(Number(event.target.value), index)}
								value={el}
								placeholder='0'
								key={index}
							/>
						))}
					</div>
				</div>
			</div>
			<div className='text-[12px] text-[grey]'>{`${langPack('profile.min_amount')} ${minContribute} SAVVA`}</div>
			<div className='mt-4'>
				<Button onClick={handleSubmit} disabled={!changed || isCreated}>
					{langPack('profile.save')}
				</Button>
			</div>
		</div>
	)
}
