import Stepper, { StepperItem } from '@/shared/ui/stepper'
import { LogIn, Network, Wallet } from 'lucide-react'
import { useEffect, useState } from 'react'
import { DownloadWallet } from './download-wallet'
import Chain from './chain'
import { AppInfoStore } from '@/shared/model/store'
import AuthReedem from './auth'




const NoWallet = () => {
    const { appInfo } = AppInfoStore()

    const [current, setCurrent] = useState<string>('wallet')
    const [chain, setChain] = useState<number>(0)
    const isWallet: boolean = window.ethereum

    const steps: StepperItem[] = [
        {
            label: 'Wallet',
            value: 'wallet',
            icon: <Wallet />,
            disabled: isWallet,
            success: isWallet,
            component: <DownloadWallet />
        },
        {
            label: 'Chain',
            value: 'chain',
            disabled: appInfo.blockchain_id === chain,
            success: appInfo.blockchain_id === chain,
            icon: <Network />,
            component: <Chain setNext={(chain: number) => setChain(chain)}/>
        },
        {
            label: 'Auth',
            value: 'auth',
            disabled: !isWallet,
            component: <AuthReedem />,
            icon: <LogIn />
        },

    ]

    useEffect(() => {
        if(appInfo.blockchain_id === chain){
            setCurrent('auth')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chain])

    useEffect(() => {
        if(isWallet && current === 'wallet') {
            setCurrent('chain')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isWallet])

    useEffect(() => {
		const getNetworkId = async () => {
			const ver2 = await window.ethereum.request({
				method: 'net_version'
			})
            setChain(Number(ver2))
		}
        if(isWallet) {
            getNetworkId()
        }
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
    
  return (
    <div className='flex flex-col w-full mt-20'>
            <Stepper
                items={steps}
                onSelect={setCurrent}
                current={current}
            />
    </div>
  )
}

export default NoWallet