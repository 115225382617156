import { cn } from '@/shared/lib'
import { ArrowDown, ArrowDown10, ChevronDown, ChevronRight, DeleteIcon, File, FolderOpen, Trash } from 'lucide-react'
import { useState } from 'react'

const TreeNode = ({ file, removeFile }: any) => {
	const color = 'grey'
	const isDirectory = !file.name.includes('.')
	const [open, setOpen] = useState<boolean>(false)

	if (isDirectory) {
		return (
			<div>
				<div
					className='mb-2 flex flex-row justify-between pl-2 hover:cursor-pointer hover:bg-slate-100'
					onClick={() => setOpen(!open)}
				>
					<div className='flex flex-row items-center hover:cursor-pointer hover:bg-slate-100'>
						{!open ? <ChevronRight size={18} style={{ color }} /> : <ChevronDown size={18} style={{ color }} />}
						<FolderOpen style={{ color }} size={18} />
						<div className='ml-2'>{file.name}</div>
					</div>
					<div onClick={() => removeFile(file.orp)} style={{ cursor: 'pointer' }}>
						<Trash size={16} style={{ color }} />
					</div>
				</div>
				<div>{open && file.children.map((child: any) => <TreeNode key={child.webkitRelativePath} file={child} />)}</div>
			</div>
		)
	}

	return (
		<div className='mb-2 ml-[25px] flex flex-row justify-between hover:cursor-pointer hover:bg-slate-100'>
			<div className='flex flex-row items-center'>
				<File style={{ color }} size={18} />
				<div className='ml-2'>{file.name}</div>
			</div>
			<div onClick={() => removeFile(file.orp)} style={{ cursor: 'pointer' }}>
				<Trash size={1} style={{ color }} />
			</div>
		</div>
	)
}

export default TreeNode
