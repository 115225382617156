//TODO: переписать стили на tailwindcss

export const LoadingComponent = ({ text }) => {
	return (
		<div className='flex min-h-[100dvh] flex-col items-center justify-center bg-background px-4 py-12 sm:px-6 lg:px-8'>
			<div className='mx-auto max-w-md text-center'>
				<h1 className='mb-4 text-6xl font-bold tracking-tight text-foreground sm:text-7xl'>
					{text.split('').map((char, index) => (
						<span
							key={index}
							className='inline-block'
							style={{
								animation: `wave 1.5s ease-in-out ${index * 0.1}s infinite`
							}}
						>
							{char}
						</span>
					))}
				</h1>
			</div>
			<style>{`
        @keyframes wave {
          0%, 100% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-15px);
          }
        }
      `}</style>
		</div>
	)
}
