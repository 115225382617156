export const OPTIONS_HISTORY = (trl) => {
    return[
	{
		label: trl('profile.staking'),
		value: 'staking',
		children: [
			{
				label: trl('profile.all'),
				title: 'staking',
				value: 'all_staking'
			},
			{
				label: trl('profile.option_one'),
				title: 'staking',
				value: 'set'
			},
			{
				label: trl('profile.option_two'),
				title: 'staking',
				value: 'paid'
			},
			{
				label: trl('profile.option_three'),
				title: 'staking',
				value: 'claimed'
			},
			{
				label: trl('profile.option_four'),
				title: 'staking',
				value: 'transferred'
			},
			{
				label: trl('profile.option_five'),
				title: 'staking',
				value: 'staked'
			},
			{
				label: trl('profile.option_six'),
				title: 'staking',
				value: 'unstaked'
			},
			{
				label: trl('profile.option_seven'),
				title: 'staking',
				value: 'us_claimed'
			},
			{
				label: trl('profile.option_eight'),
				title: 'staking',
				value: 'compound'
			}
		]
	},
	{
		label: trl('profile.label_one'),
		value: 'nft_market',
		children: [
			{
				label: trl('profile.all'),
				title: 'nft_market',
				value: 'all_nft_market'
			},
			{
				label: trl('profile.option_nine'),
				title: 'nft_market',
				value: 'NFTAddedToMarket'
			},
			{
				label: trl('profile.option_ten'),
				title: 'nft_market',
				value: 'NFTRemovedFromMarket'
			},
			{
				label: trl('profile.option_eleven'),
				title: 'nft_market',
				value: 'NFTBought'
			},
			{
				label: trl('profile.option_twelve'),
				title: 'nft_market',
				value: 'NFTPriceChanges'
			},
			{
				label: trl('profile.option_thirteen'),
				title: 'nft_market',
				value: 'NFTOwnerChanged'
			}
		]
	},
	{
		label: 'NFT',
		value: 'nft',
		children: [
			{
				label: trl('profile.all'),
				title: 'nft',
				value: 'all_nft'
			},
			{
				label: trl('profile.option_fourteen'),
				title: 'nft',
				value: 'mint'
			},
			{
				label: trl('profile.option_thirteen'),
				title: 'nft',
				value: 'transfer'
			},
			{
				label: trl('nft.nft_share'),
				title: 'nft',
				value: 'nft_share'
			}
		]
	},
	{
		label: trl('profile.label_two'),
		value: 'fund',
		children: [
			{
				label: trl('profile.all'),
				title: 'fund',
				value: 'all_fund'
			},
			{
				label: trl('profile.option_fifteen'),
				title: 'fund',
				value: 'donation'
			},
			{
				label: trl('profile.option_sixteen'),
				title: 'fund',
				value: 'contribute'
			},
			{
				label: trl('profile.option_seventeen'),
				title: 'fund',
				value: 'nft_gain_withdraw'
			},
			{
				label: trl('profile.option_eighteen'),
				title: 'fund',
				value: 'prize'
			}
		]
	},
	{
		label: trl('profile.label_three'),
		value: 'promotion',
		children: [
			{
				label: trl('profile.all'),
				title: 'promotion',
				value: 'all_proomotion'
			},
			{
				label: trl('profile.option_nineteen'),
				title: 'promotion',
				value: 'list_bought'
			},
			{
				label: trl('profile.option_twenty'),
				title: 'promotion',
				value: 'aom_sucrifice'
			}
		]
	},
	{
		label: trl('profile.label_four'),
		value: 'faucet',
		children: [
			{
				label: trl('profile.all'),
				title: 'faucet',
				value: 'all_faucet'
			},
			{
				label: trl('profile.option_twenty_one'),
				title: 'faucet',
				value: 'deposit'
			},
			{
				label: trl('profile.option_twenty_two'),
				title: 'faucet',
				value: 'sent'
			}
		]
	},
	{
		label: trl('profile.label_five'),
		value: 'promo',
		children: [
			{
				label: trl('profile.all'),
				title: 'promo',
				value: 'all_promo'
			},
			{
				label: trl('profile.option_twenty_two'),
				title: 'promo',
				value: 'redeem'
			},
			{
				label: trl('profile.option_twenty_three'),
				title: 'promo',
				value: 'created'
			},
			{
				label: trl('profile.option_twenty_four'),
				title: 'promo',
				value: 'canceled'
			}
		]
	},
	{
		label: 'SAVVA',
		value: 'token',
		children: [
			{
				label: trl('profile.option_twenty_five'),
				title: 'token',
				value: 'transfer'
			}
		]
	},
	{
		label: 'CLUB',
		value: 'club',
		children: [
			{
				label: trl('profile.all'),
				title: 'club',
				value: 'all_club'
			},
			{
				label: trl('profile.option_twenty_seven'),
				title: 'club',
				value: 'buy'
			},
			
			{
				label: trl('profile.option_twenty_six'),
				title: 'club',
				value: 'stopped'
			},
			{
				label: trl('profile.option_twenty_eight'),
				title: 'club',
				value: 'claim'
			},

		]
	}
]}

export const transformFilterValues = (trl) => {
	const list = OPTIONS_HISTORY(trl)

	let newList: { label: string; title: string; value: string}[] = [] as { label: string; title: string; value: string}[]

	for (let index = 0; index < list.length; index++) {
		const getChildren: { label: string; title: string; value: string}[] = list[index].children

		newList = [...newList, ...getChildren]
	}

	return newList.map(el => {
		return {
			group: el.title || '',
			label: el.label || el.title,
			value: el
		}
	})
}