import { useEffect, useState } from 'react'
import { useSubscribe } from '@/shared/lib/use-subscribe'
import { IMessage } from '../../model/types'
import { ChatStore, IChat } from '@/entities/chat'
import { ConfigStore } from '@/shared/model/store'

export const useMessages = () => {
	const { chats, setChats, setLastRead, lastRead} = ChatStore()
	const { domain } = ConfigStore()
	const [updatedMessage, setUpdatedMessage] = useState<IMessage | null>(null)

	const { message: newMessage } = useSubscribe('chat_new')
	const { message: chatDelete } = useSubscribe('chat_delete')
	const { message: chatBanned } = useSubscribe('chat_banned')
	const { message: chatUnbanned } = useSubscribe('chat_unbanned')
	const { message: reactMessage } = useSubscribe('react')
	const { message: chatEdit } = useSubscribe('chat_edit')
	const { message: userBanned } = useSubscribe('chat_user_banned')
	const { message: userUnbanned } = useSubscribe('chat_user_unbanned')

	useEffect(() => {
		if (!reactMessage) return

		setUpdatedMessage(reactMessage.data)
	}, [reactMessage])

	useEffect(() => {
		if (!chatEdit) return
		setUpdatedMessage(chatEdit)
	}, [chatEdit])

	useEffect(() => {
		if (chatBanned) {
			setUpdatedMessage(chatBanned)
		}
	}, [chatBanned])

	useEffect(() => {
		if (chatDelete) {
			setUpdatedMessage(chatDelete)
		}
	}, [chatDelete])

	useEffect(() => {
		if (chatUnbanned) {
			setUpdatedMessage(chatUnbanned)
		}
	}, [chatUnbanned])

	useEffect(() => {
		if(!newMessage?.chat_id) return

		const updated = [...chats]
		.map((el: IChat) => 
			el.chat_id === newMessage.chat_id
				? {
					...el,
					text: newMessage.text,
					timestamp: newMessage.timestamp,
					author: newMessage.author,
					n: newMessage.n
				} 
				: el
		)
		setLastRead({
			...lastRead,
			[`${newMessage.chat_id}:${domain}`]: {
				...lastRead[`${newMessage.chat_id}:${domain}`],
				n: newMessage.n
			}
		})
		setChats(updated)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newMessage])

	return { newMessage, updatedMessage, userUnbanned, userBanned}
}
