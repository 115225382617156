import { Toaster } from '@/shared/ui'
import { Outlet } from 'react-router-dom'

export const Layout = ({ header }: { header: React.ReactNode }) => (
	<div className='grid min-h-[100dvh] grid-rows-[auto_1fr]'>
		{header}
		<main className='container my-5'>
			<Outlet />
			<Toaster />
		</main>
	</div>
)
