import yaml from 'yaml'
import axios from 'axios'
import { II18n } from '../model/store/lang-store'
import { DEFAULT_ASSETS_ROOT } from '../config'

interface Urls {
	lang: string
	url: string
}

type ApiClientResponse<T> = { ok: true; data: T } | { ok: false; error: string }

const getDefaultLang = async (lang: string, parsedData: any) => {
	try {
		const res = await fetch(`${DEFAULT_ASSETS_ROOT}i18n/${lang}.yaml`)

		const resdata = await res.text()
		const defaultDictionary = yaml.parse(resdata)

		for (const section in parsedData) {
			if (Object.prototype.hasOwnProperty.call(defaultDictionary, section)) {
				for (const key in parsedData[section]) {
					defaultDictionary[section][key] = parsedData[section][key]
				}
			}
		}

		return defaultDictionary
	} catch {
		return parsedData
	}
}

export const getLangPack = async (urls: Urls[]): Promise<ApiClientResponse<Record<string, II18n>>> => {
	const result = {}

	try {
		for await (const obj of urls) {
			const res = await fetch(obj.url)
			if (!res.ok) {
				throw new Error(`Failed to fetch ${obj.lang}.yaml: ${res.status} ${res.statusText}`)
			}
			const data = await res.text()
			const parsedData = yaml.parse(data) as Record<string, string>

			if (!obj.url.startsWith(DEFAULT_ASSETS_ROOT)) {
				const pars = await getDefaultLang(obj.lang, parsedData)
				result[obj.lang] = pars
			} else {
				result[obj.lang] = parsedData
			}
		}
		return result ? { ok: true, data: result } : { ok: false, error: 'Network error' }
	} catch (error) {
		if (axios.isAxiosError(error) || error instanceof Error) {
			console.error(error.message)
			return { ok: false, error: error.message }
		}

		throw console.log(error)
	}
}
