import useRequest from '@/shared/lib/use-request'
import { useEffect, useState } from 'react'
import { toast } from '@/shared/lib'
import { IUser } from '@/entities/user/model/types'

export const useBannedUsersList = (domain: string, chatId: string | undefined) => {
	const [bannedUserList, setBannedUsersList] = useState<IUser[]>([])

	const { fetch: fetchBannedUsers } = useRequest('getChatbannedUsers')

	const getBannedUsersList = async () => {
		await fetchBannedUsers({
			params: {
				domain,
				user_addr: chatId
			},
			onSuccess(res) {
				setBannedUsersList(res.users)
			},
			onError(err) {
				setBannedUsersList([])
				toast({
					title: err.message,
					description: err.description,
					variant: 'destructive'
				})
			}
		})
	}

	useEffect(() => {
		if (!chatId) return

		getBannedUsersList()
	}, [domain, chatId])

	return { bannedUserList, setBannedUsersList, getBannedUsersList }
}