export const ErrorComponent = () => {
	return (
		<div className='flex min-h-[100dvh] flex-col items-center justify-center bg-background px-4 py-12 sm:px-6 lg:px-8'>
			<div className='mx-auto max-w-md text-center'>
				<h1 className='mb-4 text-6xl font-bold tracking-tight text-foreground sm:text-7xl'>Oops!</h1>
				<p className='mb-4 text-lg text-muted-foreground'>It looks like the page you're looking for doesn't exist.</p>
			</div>
		</div>
	)
}
