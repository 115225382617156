import { cn } from '@/shared/lib'
import { Badge } from '@/shared/ui/badge'
import { getCurrentCats } from '../model/utils'
import { ContentType } from '../model/types'

type Props = {
	content: ContentType
	className?: string
}

export const ContentCategories = ({ content, className }: Props) => {
	if (!content) return null

	return (
		<div className={cn('flex h-[20px] flex-row items-center', className)}>
			{getCurrentCats(content.savva_content.locales).map((content: string) => (
				<Badge variant='secondary' className='rounded-[5px] px-1' key={content}>
					{content}
				</Badge>
			))}
		</div>
	)
}
