import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { IChat, ILastRead } from './type'

interface IChatStore {
	myNewMessages: number
	setMyNewMessages: (state: number) => void
	value: string
	setValue: (state: string) => void
	lastRead: ILastRead
	setLastRead: (state: ILastRead) => void
	activeChat: IChat
	setActiveChat: (state: IChat) => void
	editedMessage: IChat | null
	setEditedMessage: (state: IChat | null) => void
	chats: IChat[]
	setChats: (state: IChat[]) => void
}

export const ChatStore = create<IChatStore>()(
	persist(
		set => ({
			chats: [],
			myNewMessages: 0,
			setMyNewMessages: state => set({ myNewMessages: state }),
			value: '',
			setValue: state => set({ value: state }),
			lastRead: {},
			setLastRead: state => set({ lastRead: state }),
			activeChat: {} as IChat,
			setActiveChat: state => set({ activeChat: state }),
			editedMessage: null,
			setEditedMessage: state => set({ editedMessage: state }),
			setChats: state => set({ chats: state })
		}),
		{
			name: 'chat',
			partialize: state => ({ lastRead: state.lastRead })
		}
	)
)
