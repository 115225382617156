import { useSponsorsSumm } from '@/entities/user'
import { LangStore } from '@/shared/model/store'
import { AmountBlock } from '@/shared/ui/amount-block/amount-block'

export const SponsorsSumm = () => {
	const { sponsorsSumm } = useSponsorsSumm()
	const { langPack } = LangStore()

	if (!sponsorsSumm) return null

	return (
		<div className='flex flex-col rounded-xl border bg-card p-6 text-card-foreground shadow'>
			<div className='flex flex-row items-center justify-between space-y-0 pb-2'>{langPack('editor.get_it_now')}</div>
			<AmountBlock
				size='md'
				amount={sponsorsSumm}
				perUsd
				usdClassName='absolute -bottom-[10px] text-[grey] text-[13px]'
			/>
		</div>
	)
}
