import { contractRead, DEFAULT_PREC, getSplitTransaction } from '@/shared/lib'
import useNotifier from '@/shared/lib/use-notifier'
import { LangStore } from '@/shared/model/store'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import { Button } from '@/shared/ui'

type Props = {
	cid: string
}

const NftBuy = ({ cid }: Props) => {
	const { contracts } = ContractsStore()
	const { langPack } = LangStore()

	const { writeContract, isLoading } = useNotifier({})

	const buy = async () => {
		const newPrice = await contractRead('NFTMarketplace', 'getPrice', [cid])
		const { message, v, r, s } = await getSplitTransaction({
			token: 'SAVVA',
			spender: contracts!.NFTMarketplace.address,
			amount: Number(newPrice) / DEFAULT_PREC
		})

		writeContract({
			address: contracts.NFTMarketplace.address,
			abi: contracts.NFTMarketplace.abi,
			functionName: 'buyWithPermit',
			args: [cid, message.value, message.deadline, v, r, s]
		})
	}

	return (
		<Button variant='orange' className='w-full text-white' size='sm' onClick={buy} loading={isLoading}>
			{langPack('nft.buy')}
		</Button>
	)
}

export default NftBuy
