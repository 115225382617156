import { cn } from '@/shared/lib'
import { Popover, PopoverContent, PopoverTrigger } from '@/shared/ui'
import { FC, useEffect } from 'react'
import { IMessage } from '../model/types'
import { getName } from '@/shared/ui/user-name/ui/user-name'
import StakedLevelImg from '@/shared/ui/user-name/ui/staked-level-icon'
import dayjs from 'dayjs'
import { useInView } from 'react-intersection-observer'
import { ChatStore } from '@/entities/chat'
import { ConfigStore, LangStore } from '@/shared/model/store'
import { Reactions } from './reactions'
import { ContextMenu } from './context-menu'
import { UserCard, UserStore } from '@/entities/user'
import { IUser } from '@/entities/user/model/types'

type Props = {
	message: IMessage
	bannedUserList: IUser[]
	showBlocked: boolean
	setShowBlocked: React.Dispatch<React.SetStateAction<boolean>>
	isNextUserDifferent: boolean
	className: string
	isFocused: boolean
}

export const Message: FC<Props> = ({
	message,
	isNextUserDifferent,
	bannedUserList,
	setShowBlocked,
	showBlocked,
	className,
	isFocused
}) => {
	const { author, banned, deleted, text, reactions, my_reaction } = message
	const { address } = author

	const { lastRead, setLastRead } = ChatStore()
	const { domain } = ConfigStore()
	const { signatureAddress } = UserStore()
	const { langPack } = LangStore()

	const { ref, inView, entry } = useInView({
		threshold: 1,
		triggerOnce: true
	})
	const key = `${message.chat_id}:${domain}`
	const isUserBanned = bannedUserList.find(user => user.address === address)
	const lastReadMessage = lastRead[key]?.lastRead

	useEffect(() => {
		if (inView && message.n >= lastReadMessage) {
			setLastRead({
				...lastRead,
				[key]: {
					...lastRead[key],
					lastRead: message.n
				}
			})
		}
	}, [inView, message])

	useEffect(() => {
		if (isFocused) {
			entry?.target.scrollIntoView({ block: 'end' })
		}
	}, [isFocused, entry])

	const authorMessages = isNextUserDifferent && (
		<UserCard user={message.author} onlyAvatar/>
		
	)

	{/* <Avatar>
			<AvatarImage src={`${baseUrl}ipfs/${message.author.avatar}`} alt='user' />
			<AvatarFallback>
				<User />
			</AvatarFallback>
		</Avatar> */}

	return (
		<div className={className}>
			{authorMessages}
			<Popover>
				<PopoverTrigger
					ref={ref}
					className={cn('w-[60%]', message.deleted && 'cursor-default', !isNextUserDifferent && 'ml-12')}
				>
					<div
						className={cn(
							'mb-2 space-y-3 text-wrap rounded-xl border p-4 text-sm',
							address === signatureAddress && 'bg-secondary'
						)}
					>
						<div className='flex items-center justify-between gap-8'>
							<div className='flex items-center gap-2'>
								<div>{getName(author)}</div>
								<StakedLevelImg staked={author.staked} />
							</div>
							<div>{dayjs(message.timestamp).format('HH.mm')}</div>
						</div>

						<p
							className={cn(
								'flex justify-start text-wrap whitespace-pre-line break-all text-start',
								banned || deleted || isUserBanned ? 'italic' : 'not-italic'
							)}
						>
							{deleted
								? langPack('chat.message_deleted')
								: isUserBanned && !showBlocked
									? langPack('chat.author_blocked_message')
									: banned && !showBlocked
										? langPack('chat.message_blocked_administrator')
										: text}
						</p>
						<Reactions myReaction={my_reaction} reactions={reactions} />
					</div>
				</PopoverTrigger>
				{!message.deleted && (
					<PopoverContent side='left' className='p-0'>
						<ContextMenu
							bannedUserList={bannedUserList}
							message={message}
							setShowBlocked={() => setShowBlocked(!showBlocked)}
							showBlocked={showBlocked}
						/>
					</PopoverContent>
				)}
			</Popover>
		</div>
	)
}
