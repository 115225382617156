import {
	AvatarImage,
	Avatar,
	FormControl,
	FormField,
	FormItem,
	AvatarFallback,
	FormMessage,
	Button
} from '@/shared/ui'
import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { z } from 'zod'
import { formSchema } from '../model/model'
import { Image, Trash2 } from 'lucide-react'
import { ConfigStore } from '@/shared/model/store'

type Props = {
	form: UseFormReturn<z.infer<typeof formSchema>>
	pathToFolder: string
}
export const PostCover: React.FC<Props> = ({ form, pathToFolder }) => {
	const { baseUrl } = ConfigStore()

	const deleteCover = () => {
		form.setValue('thumbnail', '')
	}

	return (
		<FormField
			control={form.control}
			name={'thumbnail'}
			defaultValue=''
			render={({ field }) => {
				return (
					<FormItem>
						<FormControl>
							<Avatar className='h-[100px] w-[160px] rounded-none'>
								<AvatarImage className='object-cover' src={`${baseUrl}user-file/${pathToFolder}/${field.value}`} />
								<AvatarFallback className='h-[100px] w-[160px] rounded-none object-cover'>
									<Image />
								</AvatarFallback>
								{field.value && (
									<Button size='icon' variant='semi' className='absolute right-0' onClick={() => field.onChange('')}>
										<Trash2 />
									</Button>
								)}
							</Avatar>
						</FormControl>
						<FormMessage />
					</FormItem>
				)
			}}
		/>
	)
}
