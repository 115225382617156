import TreeNode from './TreeNode'

const DirectoryTree = ({ files, removeFile }) => {
	return (
		<div className='my-3 rounded-2xl border-[2px] border-dashed border-zinc-200 p-4'>
			{files.map((file) => (
				<TreeNode key={file.webkitRelativePath} file={file} removeFile={removeFile} />
			))}
		</div>
	)
}

export default DirectoryTree
