import ReactTimeAgo from 'react-timeago'
import { LangStore } from '../model/store/lang-store'
import { FC } from 'react'

type Props = {
	date: number | string
	classNameOverflow?: string
	classNamedate?: string
	future?: boolean
}

export const TimeAgo: FC<Props> = ({ date, classNameOverflow = '', classNamedate = '', future }) => {
	const { lang } = LangStore()
	return (
		<div className={`text-[grey] ${classNameOverflow}`}>
			<ReactTimeAgo date={date} locale={lang} className={`text-[12px] ${classNamedate}`} future={future}/>
		</div>
	)
}