import { AspectRatio } from '@/shared/ui/aspect-ratio'
import { extractVideoKey } from '../lib/utils'
import { allowedAudios, allowedVideos } from '../model/media-type'

const YoutubePlayer = ({ url }) => (
	<AspectRatio ratio={16 / 9} className='mb-3'>
		<iframe
			src={`https://www.youtube.com/embed/${url}`}
			data-percentage='100%,56.25%'
			data-size='100%,56.25%'
			data-align='none'
			data-origin='100%,56.25%'
			data-file-name='3GNFMdf3t6w'
			data-file-size='0'
			data-index='0'
			className='h-full w-full rounded-md object-cover'
		/>
	</AspectRatio>
)

const VimeoPlayer = ({ match }) => (
	<AspectRatio ratio={16 / 9} className='mb-3'>
		<iframe
			src={`https://player.vimeo.com/video/${match[1]}`}
			frameBorder='0'
			allow='autoplay; fullscreen; picture-in-picture'
			allowFullScreen
			className='h-full w-full rounded-md object-cover'
		/>
	</AspectRatio>
)

const VideoPlayer = ({ url }) => (
	<AspectRatio ratio={16 / 9} className='mb-3'>
		<video
			controlsList='nodownload'
			width='100%'
			height='100%'
			controls
			src={url}
			className='h-full w-full rounded-md object-cover'
		>
			<source type='video/mp4' />
		</video>
	</AspectRatio>
)

const AudioPlayer = ({ url }) => <audio controlsList='nodownload' controls src={url} />

export const Media = item => {
	switch (true) {
		case item.src?.includes('www.youtube.com'):
		case item.src?.includes('https://youtube.com'):
			return <YoutubePlayer url={extractVideoKey(item.src)} />
		case item.src?.includes('player.vimeo.com'):
			return <VimeoPlayer match={item.src.match(/(?:vimeo\.com\/|video\/)(\d+)/)} />
		case allowedVideos.some(el => item.src?.includes(el)):
			return <VideoPlayer url={item.src} />
		case allowedAudios.some(el => item.src?.includes(el)):
			return <AudioPlayer url={item.src} />
		default:
			return <img src={item.src} alt={item.alt} className='mb-3'/>
	}
}
