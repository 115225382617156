import { IUser } from '@/entities/user/model/types'
import useNotifier from '@/shared/lib/use-notifier'
import { Button, Input, Label } from '@/shared/ui'
import { Activity, Check } from 'lucide-react'
import { FC, useState } from 'react'
import { formatEther, parseEther } from 'viem'
import { IPFSProfileType } from '../profile'
import { stringToBytes32 } from '@/shared/lib'
import { ConfigStore, LangStore } from '@/shared/model/store'
import { ContractsStore } from '@/shared/model/store/contracts-store'

type Props = {
	profile: IUser & IPFSProfileType
	getUserFetch: () => void
}

export const Sponsors: FC<Props> = ({ getUserFetch, profile }) => {
	const [readPrice, setReadPrice] = useState(() => formatEther(BigInt(profile?.chat_read_price)))
	const [writePrice, setWritePrice] = useState(() => formatEther(BigInt(profile?.chat_write_price)))

	const { langPack } = LangStore()

	const { domain } = ConfigStore()
	const {contracts} = ContractsStore()

	//FIXME: рефакторинг
	const { writeContract: write } = useNotifier({
		onSuccess: async () => {
			await getUserFetch()
		}
	})

	const writeChatReadPrice = () => {
		write({
			abi: contracts.UserProfile.abi,
			address: contracts.UserProfile.address,
			functionName: 'setUInt',
			args: [stringToBytes32(domain), stringToBytes32('chat_read_price'), parseEther(readPrice)]
		})
	}

	const writeChatWritePrice = () => {
		write({
			abi: contracts.UserProfile.abi,
			address: contracts.UserProfile.address,
			functionName: 'setUInt',
			args: [stringToBytes32(domain), stringToBytes32('chat_write_price'), parseEther(writePrice)]
		})
	}
	return (
		<div>
			<div className='flex w-full items-end gap-1'>
				<div className='w-full'>
					<Label>{langPack('profile.minimum_reading')}</Label>
					<Input value={readPrice} onChange={event => setReadPrice(event.target.value)} />
				</div>
				<Button size='icon' className='ml-auto' onClick={writeChatReadPrice}>
					<Check />
				</Button>
			</div>
			<div className='flex w-full items-end gap-1'>
				<div className='w-full'>
					<Label>{langPack('profile.minimum_communication')}</Label>
					<Input value={writePrice} onChange={event => setWritePrice(event.target.value)} />
				</div>
				<Button size='icon' className='ml-auto' onClick={writeChatWritePrice}>
					<Check />
				</Button>
			</div>
		</div>
	)
}
