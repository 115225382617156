import { ContentType } from '@/entities/feed-block/model/types'
import { getContentLang } from '@/features/content-lang-toggle'
import { AppDetailStore } from '@/shared/model/store'
import { useEffect, useState } from 'react'

type Props = {
	lang: string
	state: ContentType | null
}

export const useContentLang = ({ lang, state }: Props) => {
	const { appDetails } = AppDetailStore()
	const [contentLang, setContentLang] = useState(appDetails.default_locale)

	const contentLocales = [
		...new Set([...Object.keys(state?.savva_content.locales ?? {}), ...appDetails.locales.map(el => el.code)])
	]

	useEffect(() => {
		setContentLang(state ? getContentLang(state.savva_content.locales, lang) : lang)
	}, [state, lang])

	return { contentLang, contentLocales, setContentLang }
}
