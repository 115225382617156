import { cn } from '../lib'

export const Preloader = ({ small = false }) => (
	<div
		className={cn(
			small ? 'flex items-center justify-center' : 'fixed inset-0 z-[1000] flex items-center justify-center',
			'bg-transparent'
		)}
	>
		<div className='flex gap-2'>
			<div className='h-4 w-4 animate-bounce rounded-full bg-primary [animation-delay:.7s]'></div>
			<div className='h-4 w-4 animate-bounce rounded-full bg-primary [animation-delay:.3s]'></div>
			<div className='h-4 w-4 animate-bounce rounded-full bg-primary [animation-delay:.7s]'></div>
		</div>
	</div>
)
