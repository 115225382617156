import React from 'react'
import { Input } from './input'

type Props = {
    onChange: (value: number) => void
    value?: any
    placeholder?: string
    className?: string
    style?: React.CSSProperties
    maxLength?: number
    disabled?:boolean
    id?: string
    defaultValue?: number
}

const InputOnlyNumber = ({ onChange, value, placeholder, className, style, maxLength, disabled, id, defaultValue }: Props) => {
    return (
        <Input
            className={className}
            placeholder={placeholder}
            // type="number"
            value={value}
            defaultValue={defaultValue}
            style={style}
            id={id}
            disabled={disabled}
            onKeyDown={(event: any) => {
                if (['ArrowLeft', 'ArrowRight', 'Backspace'].includes(event.key)) {
                    return
                }
                if (maxLength && maxLength < event.target.value.length) {
                    event.preventDefault()
                }
                if (/[^0-9]/.test(event.key)) {
                    event.preventDefault()
                }
            }}
            onChange={(event: any) => onChange(event.target.value)}
        />
    )
}

export default InputOnlyNumber
