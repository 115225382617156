import { IUser } from '@/entities/user/model/types'
import { useEffect, useState } from 'react'
import { IPFSProfileType, ProfileProps } from '../profile'
import { LangStore } from '@/shared/model/store'
import useRequest from '@/shared/lib/use-request'

import { Card, Preloader } from '@/shared/ui'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/shared/ui/tabs'
import { UserCard } from '@/entities/user'
import { AmountBlock } from '@/shared/ui/amount-block/amount-block'

export interface ISponsors {
	amount: number
	weeks: number
	user: IUser & IPFSProfileType
	domain: string
}

const Sponsors = ({ profile, getUserFetch }: ProfileProps) => {
	const { langPack } = LangStore()
	const [currentTab, setCurrentTab] = useState<string>('sponsees')

	const { fetch: fetchSponsors, result, isLoading: isLoadingSponsors } = useRequest<ISponsors[]>('getSponsors')
	const { fetch: fetchSponsees, result: Sponses, isLoading: isLoadingSponsees } = useRequest<ISponsors[]>('getSponsees')

	const getAllSponsees = () => {
		fetchSponsees({
			params: {
				user_addr: profile?.address
			}
		})
	}
	const getAllSponsors = () => {
		fetchSponsors({
			params: {
				user_addr: profile?.address
			}
		})
	}

	const fetchAll = () => {
		getAllSponsors()
		getAllSponsees()
	}

	useEffect(() => {
		fetchAll()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [profile.address])

	return (
		<Card className='p-8 shadow-md'>
			<Tabs defaultValue='blog' className='w-full' value={currentTab} onValueChange={setCurrentTab}>
				<TabsList className='grid w-full grid-cols-2'>
					<TabsTrigger value='sponsees'>{langPack('profile.sponsor_other')}</TabsTrigger>
					<TabsTrigger value='sponsors'>{langPack('profile.sponsors_users')}</TabsTrigger>
				</TabsList>
				<TabsContent value='sponsors' className='p-2'>
					<div className='mb-6 grid grid-cols-5 grid-rows-1 gap-4'>
						<div className='col-span-2'>{langPack('default.user')}</div>
						<div className='col-start-3 text-center'>{langPack('default.domain')}</div>
						<div className='col-start-4 text-center'>{langPack('profile.number_week')}</div>
						<div className='col-start-5 text-center'>{langPack('profile.sum_in_week')}</div>
					</div>

					{(result ?? []).map((el: ISponsors) => (
						<div className='grid grid-cols-5 grid-rows-1 gap-4 mb-3 items-center'>
							<div className='col-span-2 w-full text-center'>
								<UserCard user={el.user} />
							</div>
							<div className='col-start-3 text-center'>{el.domain}</div>
							<div className='col-start-4 text-center'>{el.weeks}</div>
							<div className='col-start-5 text-center'>
								<AmountBlock amount={el.amount} />
							</div>
						</div>
					))}
				</TabsContent>
				<TabsContent value='sponsees' className='p-2'>
					<div className='mb-6 grid w-full grid-cols-5 grid-rows-1 gap-4'>
						<div className='col-span-2'>{langPack('default.user')}</div>
						<div className='col-start-3 text-center'>{langPack('default.domain')}</div>
						<div className='col-start-4 text-center'>{langPack('profile.number_week')}</div>
						<div className='col-start-5 text-center'>{langPack('profile.sum_in_week')}</div>
					</div>
					{isLoadingSponsees || isLoadingSponsors ? (
						<div className='mt-8'>
							<Preloader small />
						</div>
					) : (
						(Sponses ?? []).map((el: ISponsors) => (
							<div className='grid grid-cols-5 grid-rows-1 gap-4 mb-3 items-center'>
								<div className='col-span-2 w-full text-center'>
									<UserCard user={el.user} length={50} />
								</div>
								<div className='col-start-3 text-center'>{el.domain}</div>
								<div className='col-start-4 text-center'>{el.weeks}</div>
								<div className='col-start-5 text-center'>
									<AmountBlock amount={el.amount} />
								</div>
							</div>
						))
					)}
				</TabsContent>
			</Tabs>
		</Card>
	)
}

export default Sponsors
