import { AppDetailStore } from '@/shared/model/store'
import { LangStore } from '@/shared/model/store/lang-store'
import { Button, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/shared/ui'

export const LangToggle = () => {
	const { lang, setLang } = LangStore()
	const { appDetails } = AppDetailStore()

	document.title = appDetails.locales.find(el => el.code === lang)?.title || appDetails.locales[0].title

	if (appDetails.locales.length === 1) return null

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant='outline' size='icon'>
					{lang.toLocaleUpperCase()}
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align='center'>
				{appDetails.locales.map(el => (
					<DropdownMenuItem key={el.code} onClick={() => setLang(el.code)}>
						{el.code.toLocaleUpperCase()}
					</DropdownMenuItem>
				))}
			</DropdownMenuContent>
		</DropdownMenu>
	)
}
