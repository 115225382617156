import PromoBox from '@/pages/profile/ui/wallet/promo-box'
import { contractRead, createKeccakHash } from '@/shared/lib'
import { AppInfoStore, LangStore } from '@/shared/model/store'
import { Button, Card, Input, Label, Preloader } from '@/shared/ui'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import NoWallet from './no-wallet'
import { UserStore } from '@/entities/user'
import useRequest from '@/shared/lib/use-request'
import { Logo } from '@/features/logo'
import { CircleX, TicketX } from 'lucide-react'
import { useAccount } from 'wagmi'
import { Wallet } from '@/entities/wallet'
import { Auth } from '@/features/auth'
import { ConnectButton } from '@rainbow-me/rainbowkit'

const tzOffset = new Date().getTimezoneOffset()

type steps = 'hello' | 'get_wallet' | 'no_wallet' | 'congratulation'

export interface IPromo {
	donator: string
	hash: string
	index: number
	pls_amount: number
	savva_amount: number
	valid_till: number
}

export const ReedeemPage = () => {
	const { langPack } = LangStore()
	const { userData } = UserStore()
	const navigate = useNavigate()
	const { address, chain } = useAccount()
	const { appInfo } = AppInfoStore()

	const { id } = useParams()
	const queryParameters = new URLSearchParams(window.location.search)
	const current: steps = queryParameters.get('step') as steps
	const isWallet: boolean = window.ethereum

	const { fetch } = useRequest('redeemPromo')

	const [currentStep, setCurrentStep] = useState<steps>(current ?? 'hello')
	const [promoD, setPromoD] = useState<IPromo>()
	const [addr, setAddr] = useState<string>(address || '')
	const [secret, setSecretCode] = useState<string>()
	const [errorCode, setErrorCode] = useState<boolean>(false)

	const [isLoading, setIsLoading] = useState<boolean>(true)

	useEffect(() => {
		if (!id) return

		const getData = async () => {
			try {
				const promoData: IPromo = await contractRead('Promo', 'getPromoCode', [id])
				setPromoD(promoData)
			} catch (e) {
				console.log('Error', e)
			} finally {
				setIsLoading(false)
			}
		}
		getData()
	}, [id])

	const checkSecret = (secret: string) => {
		const hash = createKeccakHash(secret)
		return hash === id
	}

	const startRedeem = () => {
		if (!checkSecret(secret || '')) {
			setErrorCode(true)
			return
		} else {
			fetch({
				params: {
					hash: id,
					secret,
					user: addr
				},
				onSuccess() {
					setCurrentStep('congratulation')
				}
			})
		}
	}

	const addToken = async () => {
		try {
			await window.ethereum.request({
				method: 'wallet_watchAsset',
				params: {
					type: 'ERC20',
					options: {
						address: contracts.SavvaToken.address,
						symbol: 'SAVVA',
						decimals: 18,
						image: 'https://stihi.io/static/media/currIcon.44e7fa26.svg'
					}
				}
			})
		} catch (e) {
			console.log('Error', e)
		}
	}

	useEffect(() => {
		if (!address) return
		setAddr(address)
	}, [address])

	useEffect(() => {
		errorCode && setErrorCode(false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [secret, setErrorCode])

	useEffect(() => {
		current && setCurrentStep(current)
	}, [current])

	const expDate: string = dayjs(Number(promoD?.valid_till || '0'))
		.utcOffset(tzOffset, true)
		.format('DD.MM.YY  HH:mm:ss')
	if (isLoading) return <Preloader />

	if (!promoD || promoD.index < 1)
		return (
			<Card className='container flex h-[80vh] flex-col items-center justify-center shadow-lg'>
				<div className='text-[25px] font-bold'>{langPack('promo_code.repayment_error')}</div>
				<div className='mb-2 text-[grey]'>{langPack('promo_code.repayment_error_info')}</div>
				<div className='text-[grey]'>
					<div className='mb-2 flex flex-row items-center gap-4'>
						<CircleX color='red' size={18} /> {langPack('promo_code.first_reason')}
					</div>
					<div className='mb-2 flex flex-row items-center gap-4'>
						<CircleX color='red' size={18} /> {langPack('promo_code.second_reason')}
					</div>
					<div className='flex flex-row items-center gap-4'>
						<CircleX color='red' size={18} />{langPack('promo_code.third_reason')}
					</div>
				</div>
				<TicketX size={210} strokeWidth={1} />
			</Card>
		)

	return (
		<Card className='container flex h-[80vh] flex-col items-start justify-start shadow-lg'>
			{currentStep === 'hello' && (
				<div className='m-auto flex w-[60%] flex-col items-center justify-center'>
					<div className='mb-5 flex flex-col items-center justify-center text-center text-[grey]'>
						<div>{langPack('promo_code.promo_info_twenty')}</div>
						<div className='mt-5'>{langPack('promo_code.promo_info_twenty_one')}</div>
					</div>
					<div className='m-auto flex'>
						<PromoBox savva={Number(promoD.savva_amount)} pls={Number(promoD.pls_amount)} expDate={expDate} />
					</div>
					<div className='m-auto mt-5 flex w-[60%] items-center justify-center'>
						<Button onClick={() => setCurrentStep('get_wallet')}>{langPack('promo_code.btn_text_three')}</Button>
					</div>
				</div>
			)}
			{currentStep === 'get_wallet' && (
				<div className='m-auto flex w-[60%] flex-col items-center justify-center'>
					<div className='flex flex-col items-center justify-center'>
						<div className='text-[20px] font-bold'>
							{langPack(isWallet ? 'promo_code.promo_wallet_created_title' : 'promo_code.promo_title_six')}
						</div>
						<div className='text-[14px] text-[grey]'>
							{langPack(isWallet ? 'promo_code.promo_wallet_created_desc' : 'promo_code.promo_info_nineteen')}
						</div>
					</div>
					{isWallet ? (
						<>
							<div className='mt-8 grid w-full grid-cols-4 grid-rows-1 items-center justify-center gap-4'>
								<Label className='text-right'>{langPack('nft.address')}</Label>
								<div className='col-span-3'>
									<Input
										value={addr}
										onChange={e => setAddr(e.target.value)}
										disabled
										placeholder={langPack('promo_code.promo_input_placeholder_one')}
									/>
								</div>
							</div>
							<div className='mt-8 grid w-full grid-cols-4 grid-rows-1 items-center justify-center gap-4'>
								<Label className='text-right'>{langPack('profile.secret_text')}</Label>
								<div className='col-span-3'>
									<Input
										value={secret}
										onChange={e => setSecretCode(e.target.value)}
										placeholder={langPack('promo_code.promo_input_placeholder_two')}
									/>
								</div>
							</div>
						</>
					) : (
						<></>
					)}
					{isWallet ? (
						<div className='mt-8 flex w-full flex-row'>
							{chain?.id !== appInfo.blockchain_id && address && (
								<div className='w-full'>
									<ConnectButton.Custom>
										{({ openChainModal }) => {
											return (
												<Button onClick={openChainModal} className='w-full' variant={'destructive'}>
													{langPack('promo_code.false_network')}
												</Button>
											)
										}}
									</ConnectButton.Custom>
								</div>
							)}
							{!address && (
								<div className='w-full'>
									<Wallet className='w-full' />
								</div>
							)}
							{address && !userData?.address && chain?.id === appInfo.blockchain_id && <Auth />}
							{address && userData?.address && chain?.id === appInfo.blockchain_id && (
								<Button
									onClick={startRedeem}
									className='flex w-full'
									variant={errorCode ? 'destructive' : 'orange'}
									disabled={!secret || secret?.length < 10}
								>
									{errorCode ? 'Enter correct secret code' : langPack('promo_code.repay')}
								</Button>
							)}
						</div>
					) : (
						<div className='mt-8 flex flex-row'>
							<Button onClick={() => setCurrentStep('no_wallet')} className='flex w-full' variant={'orange'}>
								{langPack('promo_code.btn_text_two')}
							</Button>
						</div>
					)}
				</div>
			)}
			{currentStep === 'no_wallet' && <NoWallet />}
			{currentStep === 'congratulation' && (
				<div className='flex h-full w-full flex-col items-center justify-center'>
					<Logo />
					<div className='mt-4 text-[20px] font-bold'>{langPack('promo_code.promo_title_one')}</div>
					<div className='text-[14px] text-[grey]'>{langPack('promo_code.promo_title_two')}</div>
					<div className='mt-10 flex flex-row items-center gap-4'>
						<Button onClick={addToken} variant={'orange'}>
							{langPack('promo_code.add_token')}
						</Button>
						<Button onClick={() => navigate(`/${address}?p=wallet`)}>
							{langPack('promo_code.btn_text_one')}
						</Button>
					</div>
				</div>
			)}
		</Card>
	)
}