/* eslint-disable no-case-declarations */
import { ContentStore } from '@/entities/feed-block/model/content-store'
import { ContentType } from '@/entities/feed-block/model/types'
import { ContractsStore } from '../model/store/contracts-store'
import { IUser } from '@/entities/user/model/types'
import { SocketStore } from '../model/store/socket-store'

export type SocketEvents = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any
	error: string
	type: SocketEventsTypes
}

export type SocketEventsTypes =
	| 'chat_new'
	| 'chat_delete'
	| 'chat_edit'
	| 'react'
	| 'chat_banned'
	| 'chat_user_banned'
	| 'chat_unbanned'
	| 'chat_user_unbanned'
	| 'fund_contributed'
	| 'fund_prize'
	| 'content_processed'
	| 'nft_removed_from_market'
	| 'nft_added_to_market'
	| 'nft_minted'
	| 'nft_price_changed'
	| 'nft_bought'
	| 'nft_burned'
	| 'nft_transferred'
	| 'token_price_changed'
	| 'banned_user'
	| 'unbanned_user'
	| 'banned_post'
	| 'unbanned_post'

export const socketEvents = async (event: SocketEvents) => {
	const { contenList, activePost } = ContentStore.getState()
	
	switch (event.type) {
		/* case 'content_processed': 
			if(event.data.content.savva_content.content_type === 'comment') {
				// const old = ContentStore.getState().commentList
				// old.push(event.data.content)
				// ContentStore.setState({commentList: old})
			}
			break; */
		case 'token_price_changed':
			ContractsStore.setState({ tokenPrice: event.data })
			break
		case 'fund_contributed':
			const tmpList = [...contenList].map((el: ContentType) =>
				el.savva_cid === event.data.content_id
					? {
							...el,
							fund: {
								...el.fund,
								amount: event.data.amount,
								total_author_share: event.data.author_share
							}
						}
					: el
			)

			ContentStore.setState({ contenList: tmpList })
			if (activePost?.savva_cid && event.data.content_id === activePost?.savva_cid) {
				const active = { ...activePost }
				active.fund = {
					...active.fund,
					amount: event.data.amount,
					round_time: event.data.round_time,
					total_contributed: (Number(activePost.fund.total_contributed) || 0) + Number(event.data.contributed)
				}
				ContentStore.setState({ activePost: active })
			}
			break
		case 'fund_prize':
			const res = contenList.map((post: ContentType) => {
				if (post.savva_cid === event.data.content_id) {
					return {
						...post,
						fund: {
							...post.fund,
							amount: event.data.amount,
							round_time: event.data.round_time
						}
					}
				}

				return post
			})

			if (activePost?.savva_cid && event.data.content_id === activePost?.savva_cid) {
				const active = { ...activePost }
				active.fund = {
					...active.fund,
					amount: event.data.amount,
					round_time: event.data.round_time
				}
				ContentStore.setState({ activePost: active })
			}

			ContentStore.setState({ contenList: res })
			break

		case 'nft_removed_from_market':
			if (!activePost?.savva_cid || event.data.content_id !== activePost?.savva_cid) return

			const active = { ...activePost }
			active.nft = {
				...active.nft,
				price: 0,
				on_market: false
			}
			ContentStore.setState({ activePost: active })
			break
		case 'nft_added_to_market':
			if (!activePost?.savva_cid || event.data.content_id !== activePost?.savva_cid) return
			const a = { ...activePost }
			a.nft = {
				...a.nft,
				price: event.data.price,
				owner: event.data.owner,
				on_market: true
			}
			ContentStore.setState({ activePost: a })
			break
		case 'nft_price_changed':
			if (!activePost?.savva_cid || event.data.content_id !== activePost?.savva_cid) return
			const ac = { ...activePost }
			ac.nft = {
				...ac.nft,
				price: event.data.price
			}
			ContentStore.setState({ activePost: ac })
			break
		case 'nft_bought':
			if (!activePost?.savva_cid || event.data.content_id !== activePost?.savva_cid) return
			const nft_bought = { ...activePost }
			nft_bought.nft = {
				...nft_bought.nft,
				price: event.data.price,
				owner: event.data.buyer,
				on_market: false
			}
			ContentStore.setState({ activePost: nft_bought })
			break
		case 'nft_minted':
			const list_now = [...contenList].map((el: ContentType) =>
				el.savva_cid === event.data.content_id
					? {
							...el,
							nft: {
								price: 0,
								on_market: false,
								owner: event.data.author
							}
						}
					: el
			)

			ContentStore.setState({ contenList: list_now })
			if (!activePost?.savva_cid || event.data.content_id !== activePost?.savva_cid) return
			const nft_minted = { ...activePost }
			nft_minted.nft = {
				...nft_minted.nft,
				price: 0,
				owner: event.data.author
			}
			ContentStore.setState({ activePost: nft_minted })
			break
		case 'react':

      		// ContentStore.setState({ activePost: event.data.data })
			// const r: ContentType = []
			// r.push(event.data)
			// const isPost =
			// 	data.object_type === 0 &&
			// 	data.data.savva_content.content_type === 'post' &&
			// 	activePost.savva_cid === data.data.savva_cid
			// const isComment =
			// 	data.object_type === 0 &&
			// 	commentList.find(comment => comment.savva_cid === data.data.savva_cid) &&
			// 	data.data.savva_content.content_type === 'comment'
			// const isChat = data.object_type === 1 && activeChat === data.data.chat_id

			// switch (true) {
			// 	case isPost:
			// 		setReactionsPost(data.data, !!myAddress)
			// 		break
			// 	case isComment:
			// 		setReactionsComment(data.data, !!myAddress)
			// 		break
			// 	case isChat:
			// 		// setReactionsChat(data.data)
			// 		break
			// 	default:
			// 		break
			// }
			// storeMessages(r, event.data.data.chat_id)
			break
		case 'nft_burned':
			const l = [...contenList].map((el: ContentType) =>
				el.savva_cid === event.data.content_id
					? {
							...el,
							nft: {
								price: 0,
								on_market: false,
								owner: {} as IUser
							}
						}
					: el
			)

			ContentStore.setState({ contenList: l })
			if (!activePost?.savva_cid || event.data.content_id !== activePost?.savva_cid) return
			const nft_burned = { ...activePost }
			nft_burned.nft = {
				price: 0,
				on_market: false,
				owner: null
			}
			ContentStore.setState({ activePost: nft_burned })
			break
		case 'nft_transferred':
			if (!activePost?.savva_cid || event.data.content_id !== activePost?.savva_cid) return
			const nft_transferred = { ...activePost }
			nft_transferred.nft = {
				...nft_transferred.nft,
				owner: event.data.to
			}
			ContentStore.setState({ activePost: nft_transferred })
			break
		default:
			break
	}
}

export const wsConnect = (url: string, domain: string) => {
	const { setMessage } = SocketStore.getState()
	const { hostname } = new URL(url)
	const websocket = new WebSocket(`wss://${hostname}/api/ws?domain=${domain}`)

	websocket.onopen = () => {
		/* websocket.send(
			JSON.stringify({
				data: {
					domain
				}
			})
		) */
	}

	websocket.onerror = err => {
		console.log('error', err)
	}

	websocket.onclose = () => {
		console.log('disconnect')
		setTimeout(_ => wsConnect(url, domain), 5000)
	}

	websocket.onmessage = event => {
		const cleanEvent = JSON.parse(event.data)
		socketEvents(cleanEvent)
		setMessage(cleanEvent)
	}
}
