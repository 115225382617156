import { ContentStore } from "@/entities/feed-block/model/content-store"
import { UserStore } from "@/entities/user"
import useNotifier from "@/shared/lib/use-notifier"
import { LangStore } from "@/shared/model/store"
import { ContractsStore } from "@/shared/model/store/contracts-store"
import { Button, Input, Label } from "@/shared/ui"
import { useState } from "react"

interface Props {
    closeModal: () => void
}

const TransferToken = ({closeModal}: Props) => {
    const { activePost } = ContentStore()
    const {contracts} = ContractsStore()
    const { langPack } = LangStore()
    const { userData } = UserStore()

    const [recipient, setRecipient] = useState<string>('')

    const { writeContract: writeTransfer, isLoading } = useNotifier({
        onSuccess() {
          closeModal();
        },
      });

      const transferhandler = async () => {
        writeTransfer({
            address: contracts.ContentNFT.address,
            abi: contracts.ContentNFT.abi,
            functionName: 'transferFrom',
			args: [userData?.address, recipient, activePost?.savva_cid]
        });
    };

  return (
    <div>
         <div className='text-[14px] text-[grey] flex w-full'>
               {langPack('nft.transfer_description')}
            </div>
            {activePost?.nft.on_market && 
                 <div className='text-[14px] text-[#cf4343] flex w-full mt-6'>
                    {langPack('nft.transfer_on_market_warning')}                  
                </div>
            }
            <div className='flex flex-row  items-end gap-4 mt-6'>
                <div className='w-full'>
                    <Label>{langPack('nft.address')}</Label>
                    <Input value={recipient} onChange={(e) => setRecipient(e.target.value)}  disabled={activePost?.nft.on_market}/>
                </div>
                <div className='flex flex-row gap-4'>
                    <Button disabled={activePost?.nft.on_market} loading={isLoading} onClick={transferhandler}>{langPack('nft.transfer_token')}</Button>
                </div>
            </div>
    </div>
  )
}

export default TransferToken