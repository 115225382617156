import { FeedPageType } from '@/pages/feed-page'
import { AppDetailStore,  ConfigStore, LangStore } from '@/shared/model/store'
import { AnnounceBlock } from './announce-block'
import { IBlock } from '@/shared/model/types'
import { HtmlContentBlock } from './html-content-block'

export const Announces = ({ pageType }: FeedPageType) => {
	const { lang } = LangStore()
	const { assetsUrl } = ConfigStore()
	const { tabs } = AppDetailStore(state => state.appDetails.modules.tabs)

	if (!tabs.length) return null

	const cureentBlocks = tabs.find(el => el.type === pageType)

	if (!cureentBlocks?.right_panel.available) return null

	return (
		<div className='size-full'>
			{cureentBlocks.right_panel.blocks.map((el: IBlock, idx) => {
				if (el.type === 'html') {
					return <HtmlContentBlock key={idx} configUrl={assetsUrl} el={el} lang={lang} />
				}
				if (el.type === 'content_List') {
					return <AnnounceBlock key={idx} data={el} />
				}
			})}
		</div>
	)
}
