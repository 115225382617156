export const injectGoogleAnalytics = (measurementId: string) => {
	const existingScript = document.querySelector('script[src*="https://www.googletagmanager.com/gtag/js"]')
	if (existingScript) {
		existingScript.parentNode.removeChild(existingScript)
	}

	delete window.dataLayer

	if (typeof window.gtag === 'function') {
		delete window.gtag
	}

	if (!measurementId) {
		console.log('Google Analytics script removed.')
		return
	}

	const gaScript = document.createElement('script')
	gaScript.async = true
	gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`

	document.head.appendChild(gaScript)

	gaScript.onload = function () {
		window.dataLayer = window.dataLayer || []
		function gtag() {
			dataLayer.push(arguments)
		}
		window.gtag = gtag

		gtag('js', new Date())
		gtag('config', measurementId)

		console.log(`Google Analytics configured with Measurement ID: ${measurementId}`)
	}
}
