import { Skeleton } from '../../skeleton'

interface Props {
    isGrid?: boolean
}

const BlogUnit = ({isGrid} : Props) => isGrid ? 
    (
        <div className='flex flex-col w-full shadow-md rounded-lg overflow-hidden my-5'>
            <Skeleton className='h-[180px] w-full rounded-b-none' />
            <div className='w-full px-[20px] py-[8px] flex flex-col justify-between'>
                <Skeleton className='h-4 w-24 rounded-lg' />
                <Skeleton className='h-6 w-32 rounded-lg mt-3' />
                <Skeleton className='h-4 w-[95%] rounded-lg mt-3' />
                <Skeleton className='h-4 w-[80%] rounded-lg  mt-3' />
                <Skeleton className='h-4 w-[75%] rounded-lg  mt-3' />
                <div className='flex flex-row mt-8 justify-between border-t-[1px] pt-2'>
                    <Skeleton className='h-6 w-20 rounded-lg' />
                    <Skeleton className='h-6 w-6 rounded-lg' />
                </div>
            </div>
        </div>
    ) 
        : 
    (
        <div className='flex mx-auto mt-6 w-full rounded-xl shadow-md lg:mb-3 relative bg-transparent over flex-row h-[190px]'>
            <div className='w-full px-[20px] py-[8px] flex flex-col justify-between'>
                <Skeleton className='h-6 w-24 rounded-lg' />
                <Skeleton className='h-6 w-[95%] rounded-lg mt-3' />
                <Skeleton className='h-6 w-[80%] rounded-lg' />
                <div className='flex flex-row gap-4'>
                    <Skeleton className='h-6 w-20 rounded-lg' />
                    <Skeleton className='h-6 w-20 rounded-lg' />
                </div>
            </div>
        <div>
                <Skeleton className='h-full w-[220px] rounded-l-none' />
        </div>
        </div>
    )


export default BlogUnit
