import useRequest from '@/shared/lib/use-request'
import { ConfigStore, LangStore } from '@/shared/model/store'
import {
	Avatar,
	AvatarFallback,
	AvatarImage,
	Button,
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger
} from '@/shared/ui'
import { useFileUpload } from '@/shared/ui/filte-input'
import { BookImage, ImagePlus, Link, SquarePlay, Trash2 } from 'lucide-react'
import { FC } from 'react'
import { getSubstringFromLastDot } from '../lib/utils'
import { allowedAudios, allowedVideos } from '@/features/md-editor'

type Props = {
	file: File
	index: number
	addFile: (value: string) => void
	setThumb: (value: string) => void
	updateFiles: () => void
	pathToFolder: string
}

export const FileCard: FC<Props> = ({ file, index, addFile, setThumb, updateFiles, pathToFolder }) => {
	const { baseUrl } = ConfigStore()
	const { removeFileFromSet } = useFileUpload()
	const { fetch: fetchDeleteFile } = useRequest('deleteFile')
	const { langPack } = LangStore()
	const type = getSubstringFromLastDot(file.name)

	const deleteFile = async () => {
		removeFileFromSet(index)
		await fetchDeleteFile({
			params: {
				path: `${pathToFolder}/uploads/${file.name}`
			},
			onSuccess: async () => await updateFiles()
		})
	}

	const addMediaFile = (type: 'file' | 'link') => {
		const prefix = type === 'file' ? '!' : ''
		const data = `${prefix}[image](uploads/${file.name})`

		addFile(data)
	}
	
	return (
		<div className='relative h-full w-full cursor-default rounded-md'>
			<Avatar className='overflow-auto! h-full w-full rounded-none object-cover'>
				<AvatarImage
					src={`${baseUrl}user-file/${pathToFolder}/uploads/${file.name}`}
					className='overflow-visible!'
					style={{ overflow: 'visible' }}
					decoding='async'
				/>

				<AvatarFallback className='h-full w-full rounded-none object-cover'>
					<SquarePlay />
				</AvatarFallback>
			</Avatar>
			<div className='absolute inset-0 flex flex-col justify-end'>
				<div className='flex w-full items-center bg-card/30 backdrop-blur-sm'>
					<TooltipProvider>
						<Tooltip>
							<TooltipTrigger>
								<Button
									className='rounded-none hover:bg-primary'
									variant={'ghost'}
									size={'icon'}
									onClick={() => addMediaFile('file')}
								>
									<BookImage />
								</Button>
							</TooltipTrigger>
							<TooltipContent className='z-10'>
								<p>{langPack('action.add')}</p>
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>

					<TooltipProvider>
						<Tooltip>
							<TooltipTrigger>
								<Button
									className='rounded-none hover:bg-primary'
									variant={'ghost'}
									size={'icon'}
									onClick={() => addMediaFile('link')}
								>
									<Link />
								</Button>
							</TooltipTrigger>
							<TooltipContent className='z-10'>
								<p>{langPack('editor.add_link')}</p>
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>

					<TooltipProvider>
						<Tooltip>
							<TooltipTrigger>
								<Button
									className='rounded-none hover:bg-primary'
									variant={'ghost'}
									size={'icon'}
									onClick={() => setThumb(file.name)}
									disabled={allowedAudios.includes(type) || allowedVideos.includes(type)}
								>
									<ImagePlus />
								</Button>
							</TooltipTrigger>
							<TooltipContent className='z-10'>
								<p>{langPack('editor.set_banner')}</p>
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>

					<TooltipProvider>
						<Tooltip>
							<TooltipTrigger>
								<Button className='rounded-none hover:bg-primary' variant={'ghost'} size={'icon'} onClick={deleteFile}>
									<Trash2 />
								</Button>
							</TooltipTrigger>
							<TooltipContent className='z-10'>
								<p>{langPack('action.delete')}</p>
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				</div>
			</div>
		</div>
	)
}
