import { getAppInfo } from '@/shared/api'
import { DEFAULT_ASSETS_ROOT } from '@/shared/config'
import { AppInfoStore, ConfigStore } from '@/shared/model/store'
import { IAppInfo } from '@/shared/model/types'

type ReturnType = {
	currentDomainData: {
		assets_cid: string
		name: string
		telegram_bot: string
		website: string
	}
	appInfo: IAppInfo
	currentURL: string
	assetsUrl: string
}

type RequestType = {
	backendLink: string
	domain: string
	gear: boolean
}

export const getCurrentAppInfo = async (defaultConnect: RequestType): Promise<ReturnType> => {
	const { baseUrl, setBaseUrl, domain, setDomain, testMode, setAssetsUrl, setGear } = ConfigStore.getState()
	const { setAppInfo } = AppInfoStore.getState()

	let currentURL = baseUrl || defaultConnect.backendLink
	let appInfo = await getAppInfo(currentURL)

	if (!appInfo.ok && currentURL !== defaultConnect.backendLink) {
		currentURL = defaultConnect.backendLink
		appInfo = await getAppInfo(defaultConnect.backendLink)
	}
	// setGear(defaultConnect?.gear || false)
	if (!appInfo.ok) throw new Error('Error missing backend link')

	if (!appInfo.data.blockchain_id) throw new Error('INFO DATA ERROR')

	setBaseUrl(currentURL)
	setAppInfo(appInfo.data)

	const domains = appInfo.data.domains

	const currentDomainData =
		domains.find(el => el.name === domain) ?? domains.find(el => el.name === defaultConnect.domain) ?? domains[0]

	if (!currentDomainData) throw new Error('Error missing domain')

	setDomain(defaultConnect.domain)

	const assetsUrl = testMode
		? `${appInfo.data.temp_assets_url}/${currentDomainData.name}/`
		: currentDomainData.assets_cid
			? `${appInfo.data.assets_url}/${currentDomainData.name}/`
			: DEFAULT_ASSETS_ROOT

	setAssetsUrl(assetsUrl)

	return { currentURL, currentDomainData, appInfo: appInfo.data, assetsUrl }
}
