import dayjs from 'dayjs'
import { FC } from 'react'
import { IMessage } from '../model/types'

type Props = {
	message: IMessage
	previousMessage?: IMessage
}

export const DateMessages: FC<Props> = ({ message, previousMessage }) => {
	const currentDate = dayjs(message.timestamp).locale('ru')
	const previousDate = previousMessage ? dayjs(previousMessage.timestamp).locale('ru') : null

	const showDate = !previousDate || !currentDate.isSame(previousDate, 'day')

	if (!showDate) return null

	return (
		<div className='flex items-center justify-center'>
			<div className='mx-4 flex-grow border-t text-muted-foreground opacity-55'></div>
			<div className='my-3 text-center text-sm text-muted-foreground opacity-55'>
				{currentDate.format('dddd, MMMM D, YYYY')}
			</div>
			<div className='mx-4 flex-grow border-t text-muted-foreground opacity-55'></div>
		</div>
	)
}
