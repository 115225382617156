type Props = {
	reactions: number[]
}

export const emojiList = ['👍', '❤️', '😂', '😢', '😡', '😱', '🏆', '🔥', '👏', '👎']

const ReactionsPreview: React.FC<Props> = ({ reactions }) => {
	const allReactionsCount = reactions.reduce((acum, num) => acum + num)

	return allReactionsCount > 0 ? (
		<div className='flex flex-row items-center gap-1'>
			{emojiList.map(
				(el, idx) =>
					reactions[idx] > 0 && (
						<div className=" text-xl" key={el} title={''} style={{ color: 'initial' }}>
							{el}
						</div>
					)
			)}
			<span className='ml-1 text-[12px]'>{allReactionsCount}</span>
		</div>
	) : null
}

export default ReactionsPreview
