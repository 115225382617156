import { UserStore } from '@/entities/user'
import { cn } from '@/shared/lib/utils/utils'
import { AppDetailStore } from '@/shared/model/store'
import { LangStore } from '@/shared/model/store/lang-store'
import { ITab } from '@/shared/model/types'
import { NavLink } from 'react-router-dom'

export const Navigation = ({ isMobile = false }: { isMobile?: boolean }) => {
	const tabs = AppDetailStore(state => state.appDetails.modules.tabs.tabs)
	const { lang } = LangStore()
	const { userData } = UserStore()

	const currentTabs = userData?.address ? tabs : tabs.filter((el: ITab) => el.type !== 'for-you')

	return (
		<ul className={cn('flex gap-4', isMobile && 'flex-col')}>
			{currentTabs.map(tab => (
				<NavLink
					className={({ isActive }) =>
						cn(
							'relative flex items-center font-medium',
							'rounded-md',
							'transition-all duration-300',
							'after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-0 after:bg-primary after:transition-all after:duration-300 after:ease-in-out after:content-[""]',
							'hover:after:w-full hover:after:scale-x-100 hover:after:transform',
							isActive ? 'text-primary after:w-full' : 'hover:text-primary'
						)
					}
					to={tab.type}
					key={tab.type}
				>
					{tab.title[lang]}
				</NavLink>
			))}
		</ul>
	)
}
