import { ContentType } from '@/entities/feed-block/model/types'
import { IUser } from '@/entities/user/model/types'
import { IPFSProfileType } from '@/pages/profile/ui/profile'
import useNotifier from '@/shared/lib/use-notifier'
import { AppDetailStore, LangStore } from '@/shared/model/store'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import {
	Button,
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
	Label,
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue
} from '@/shared/ui'
import { Textarea } from '@/shared/ui/textarea'
import { FC, useEffect, useState } from 'react'
import { useAccount } from 'wagmi'

type Props = {
	type: 'content' | 'author'
	domain: string
	content?: ContentType
	author?: IUser & IPFSProfileType
	onAction?: () => void
}

export const AuthorContentBlocker: FC<Props> = ({ type, content, domain, onAction, author }) => {
	const { langPack, lang } = LangStore()
	const [open, setOpen] = useState<boolean>(false)
	const bunReasons = AppDetailStore(state => state.appDetails.modules.bun_reasons[lang] ?? [])

	const [reason, setReason] = useState('')
	const { contracts } = ContractsStore()
	const { address } = useAccount()

	const closeHandle = (state: boolean) => {
		if (!state) {
			setReason('')
		}
		setOpen(state)
	}

	const { writeContract, isLoading, isConfirmed } = useNotifier()

	const isBlocked = author ? author.banned : type === 'content' ? content?.banned : content?.author.banned

	const block = async () => {
		const command = type === 'content' ? 'ban_post' : 'ban_user'
		const resiver = author ?  author.address  : type === 'content' ? content?.savva_cid : content?.author.address
		writeContract({
			abi: contracts.ContentRegistry.abi,
			address: contracts.ContentRegistry.address,
			functionName: 'command',
			args: [domain, command, resiver, reason, '', '']
		})
	}

	const unBlock = async () => {
		const command = type === 'content' ? 'unban_post' : 'unban_user'
		const resiver = author ?  author.address : type === 'content' ? content?.savva_cid : content?.author.address
		writeContract({
			abi: contracts.ContentRegistry.abi,
			address: contracts.ContentRegistry.address,
			functionName: 'command',
			args: [domain, command, resiver, reason, '', '']
		})
	}

	useEffect(() => {
		if(!isConfirmed) return
		onAction && onAction()
		closeHandle(false)
	}, [isConfirmed])

	const buttonTitle = 
		author ? isBlocked ? 'unbun_author' : 'bun_author' :
		type === 'content'
			? isBlocked ? 'unbun_content' : 'bun_content'
			: isBlocked ? 'unbun_author' : 'bun_author'

	return (
		<Dialog>
			<DialogTrigger asChild open={open} onOpenChange={closeHandle}>
				<Button variant='outline' size='icon' className='w-full rounded-none'>
					{langPack(`post.${buttonTitle}`)}
				</Button>
			</DialogTrigger>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>{langPack(`post.${buttonTitle}`)}</DialogTitle>
					<DialogDescription>
						{langPack(`post.${isBlocked ? 'un' : ''}bun_description_${type === 'content' ? 'content' : 'author'}`)}
					</DialogDescription>
				</DialogHeader>
				{!!bunReasons.length && type === 'content' && (
					<Select onValueChange={setReason} defaultValue={reason}>
						<SelectTrigger>
							<SelectValue />
						</SelectTrigger>
						<SelectContent>
							<SelectGroup>
								{bunReasons.map(el => (
									<SelectItem key={el} value={el}>
										{el}
									</SelectItem>
								))}
							</SelectGroup>
						</SelectContent>
					</Select>
				)}
				<div>
					<div className='grid w-full gap-1.5'>
						<Label htmlFor='message'>{langPack(`post.${isBlocked ? 'un' : ''}bun_content_label`)}</Label>
						<Textarea
							value={reason} onChange={e => setReason(e.target.value)}
							id='message'
						/>
					</div>
				</div>
				<DialogFooter className='sm:justify-start'>
					<Button loading={isLoading} type='button' disabled={!address || isLoading} onClick={isBlocked ? unBlock : block}>
						{langPack(isBlocked ? 'post.unbun_action' : 'action.block')}
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}
