import { apiClient } from "@/shared/api/api-client"
import yaml from 'yaml'
import { IContent, IDataFromIpfs } from "../model/types"

export const getContentFromIpfs = async (url: string) => {
	const response = await apiClient.get<string>(`${url}/info.yaml`)

	if (response.ok) {
		const content = yaml.parse(response.data)
		
		content.locales = await getDataFromIpfs(url, content)
		return content.locales
	} else {
		console.error(response.error)
	}
}

const getDataFromIpfs = async (
	url: string,
	data: IDataFromIpfs
) => {
	const updateLocales = {} as IContent
	for await (const [key, locale] of Object.entries(data.locales)) {
		
		const data = locale.data_path ? await apiClient.get(`${url}/${locale.data_path}`) : ''

		const chapters = locale.chapters?.length
			? await Promise.all(
					locale.chapters.map(async el => {
						const chapterData = await apiClient.get(`${url}/${el.data_path}`)
						
						return {
							title: String(el.title),
							data: chapterData.ok ? String(chapterData.data) : '',
						}
					})
				)
			: []

		updateLocales[key] = {
			title: locale.title,
			tags: locale.tags,
			categories: locale.categories,
			chapters: [{title: '', data: data && data.ok ? String(data.data) : ''}, ...chapters]
		}
	}

	return updateLocales
}