import { apiClient } from '@/shared/api/api-client'
import { ContractsStore, ITokenPrice } from '../model/store/contracts-store'
import { ConfigStore } from '../model/store'

export const getTokenPrice = async () => {
	const { baseUrl } = ConfigStore.getState()
	const data = await apiClient.get<ITokenPrice>('get-token-prices', {
		baseURL: baseUrl
	})
	if (!data.ok) throw new Error(data.error)
	ContractsStore.setState({ tokenPrice: data.data })
}
