import { ContentStore } from '@/entities/feed-block/model/content-store'
import { UserStore } from '@/entities/user'
import useNotifier from '@/shared/lib/use-notifier'
import { LangStore } from '@/shared/model/store'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import { Button } from '@/shared/ui'

interface Props {
	closeModal: () => void
}

const RemoveToken = ({ closeModal }: Props) => {
	const { activePost } = ContentStore()
	const { contracts } = ContractsStore()
	const { userData } = UserStore()
	const { langPack } = LangStore()

	const { writeContract, isLoading } = useNotifier({
		onSuccess() {
			closeModal()
		}
	})

	const remove = () => {
		writeContract({
			address: contracts.ContentNFT.address,
			abi: contracts.ContentNFT.abi,
			functionName: 'burn',
			args: [activePost?.savva_cid]
		})
	}

	const disabled = activePost?.nft.on_market || isLoading
	return (
		<div>
			<div className='flex w-full text-[14px] text-[grey]'>{langPack('nft.remove_token_title')}</div>
			{activePost?.author.address !== userData?.address && (
				<div className='mt-2 flex w-full text-[14px] text-[#cf4343]'>
					{langPack('nft.remove_token_no_owner_warning')}
				</div>
			)}
			{activePost?.nft.on_market && (
				<div className='mt-2 flex w-full text-[14px] text-[#cf4343]'>{langPack('nft.remove_on_market_warning')}</div>
			)}
			<div className='mt-6'>
				<Button size={'sm'} variant='destructive' disabled={disabled} onClick={remove}>
					{langPack('nft.remove_token')}
				</Button>
			</div>
		</div>
	)
}

export default RemoveToken
