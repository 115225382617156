import { ConfigStore } from '@/shared/model/store'

export const urlTransform = (src: string) => {
	const url = ConfigStore.getState().baseUrl
	const match = src.match(/https?:\/\/.+/)
	return match ? match[0] : `${url}user-file/${src}`
}

export function extractVideoKey(url: string) {
	if(url.includes('shorts')) {
		const parts =  url.split('shorts/')
		return parts[1].split('?')[0]
	}

	const parts = url.split('?')

	const queryParams = parts[1].split('&')

	for (let i = 0; i < queryParams.length; i++) {
		const param = queryParams[i].split('=')
		if (param[0] === 'v') {
			return param[1]
		}
	}

	return null
}
