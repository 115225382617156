import { ROUTER } from '@/shared/router'
import { Badge, Button } from '@/shared/ui'
import { Link } from 'react-router-dom'
import { useGoveranceList } from '../lib/hooks/use-goverance-list'
import { UserStore, useSponsorsSumm } from '@/entities/user'
import { Landmark, NotebookPen } from 'lucide-react'
import { SavvaIcon } from '@/shared/assets'
import { AppSetup } from '@/features/app-setup'
import { LangToggle } from '@/features/lang-toggle/ui/lang-toggle'
import { ThemeToggle } from '@/features/theme-toggle'
import { Chat } from '@/widgets/chat'
import { cn } from '@/shared/lib'
import { ConfigStore } from '@/shared/model/store'

export const ActionMenu = ({ isMobile = false }) => {
	const	{gear} = ConfigStore()
	const { signatureAddress } = UserStore()
	const { governanceList } = useGoveranceList()
	const { sponsorsSumm } = useSponsorsSumm()

	return (
		<div className={cn('flex items-center gap-2', isMobile && 'justify-between')}>
			{!!governanceList.length && (
				<Button variant='outline' size='icon'>
					<Link to={ROUTER.governance} state={governanceList}>
						<Landmark />
					</Link>
				</Button>
			)}
			{signatureAddress && (
				<Link to={ROUTER.newPost}>
					<Button variant='outline' className='relative' size='icon'>
						<NotebookPen />
						{!!sponsorsSumm && (
							<Badge className='absolute end-[-14px] top-[-15px] flex h-6 w-max justify-center bg-transparent p-0 hover:bg-transparent'>
								<SavvaIcon />
							</Badge>
						)}
					</Button>
				</Link>
			)}
			{gear && <AppSetup />}
			<LangToggle />
			<ThemeToggle />
			{signatureAddress && <Chat userAddress={signatureAddress} />}
		</div>
	)
}
