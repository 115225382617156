import { Input, Label } from '../..'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import InputOnlyNumber from '../../input-number'

type Props = {
	value: number | undefined
	onChange: (v: number) => void
	label?: string
	placeholder?: string
	disabled?: boolean
	perUsd?: boolean
	noDigits?: boolean
	max?: number
	defaultValue?: number
	token?: string
}

const AmountInput = ({
	label,
	value,
	onChange,
	placeholder,
	disabled,
	perUsd = true,
	noDigits = false,
	max,
	defaultValue,
	token = 'SAVVA'
}: Props) => {
	const { tokenPrice } = ContractsStore()

	const setQuaintyHandler = (value: number) => {
		if (!max) {
			onChange(value)
			return
		}
		if (value > max) {
			onChange(max)
		} else {
			onChange(value)
		}
	}

	return (
		<div className='grid w-full gap-4'>
			<div className={label ? 'relative grid grid-cols-4 items-center gap-4' : 'relative flex items-center gap-4'}>
				{!!label && (
					<Label htmlFor='quantity' className='text-right'>
						{label}
					</Label>
				)}
				{noDigits ? (
					<InputOnlyNumber
						disabled={disabled}
						id='quantity'
						value={value && Number(value > 0) && value.toString()}
						onChange={setQuaintyHandler}
						className='col-span-3'
						placeholder={placeholder}
						defaultValue={defaultValue}
					/>
				) : (
					<Input
						disabled={disabled}
						id='quantity'
						type='number'
						value={value && Number(value > 0) && value.toString()}
						className='col-span-3'
						placeholder={placeholder}
						onKeyDown={(event: any) => {
							if (['ArrowLeft', 'ArrowRight', 'Backspace'].includes(event.key)) {
								return
							}
							if (/[^0-9]/.test(event.key)) {
								event.preventDefault()
							}
						}}
						onChange={(event: any) => setQuaintyHandler(event.target.value)}
					/>
				)}

				{perUsd && (
					<div className='absolute right-[10px] flex text-[12px] text-[grey]'>
						{Number(value) > 0 ? `~$${(tokenPrice[token === 'SAVVA' ? 'savva_token_price' : 'base_token_price'] * Number(value)).toFixed(2)}` : null}
					</div>
				)}
			</div>
		</div>
	)
}

export default AmountInput
