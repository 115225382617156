import { IUser } from '@/entities/user/model/types'
import useRequest from '@/shared/lib/use-request'
import { useEffect, useState } from 'react'
import { ConfigStore } from '@/shared/model/store'
import { Card, Input, Preloader } from '@/shared/ui'
import { UserCard, UserStore } from '@/entities/user'
import { Link } from 'react-router-dom'
import FollowButton from './follow-button'
import { IPFSProfileType } from '../profile'
import { Skeleton } from '@/shared/ui/skeleton'

type Props = {
	profile: IUser
	followers?: boolean
	getUserFetch: () => void
}

type IFollowers = { user: IUser & IPFSProfileType; follows_me: boolean; i_follow: boolean }

const Followings = ({ profile, followers = false, getUserFetch }: Props) => {
	const { fetch, result, isLoading } = useRequest<IFollowers[]>('getFollowing')
	const {
		fetch: fetchFollowers,
		result: followersResult,
		isLoading: isLoadingFollowers
	} = useRequest<IFollowers[]>('getFollowers')
	const { domain } = ConfigStore()
	const { userData } = UserStore()

	const [filterVal, setFilterVal] = useState<string>('')

	useEffect(() => {
		;(followers ? fetchFollowers : fetch)({
			params: {
				domain: domain,
				user_addr: profile.address,
				limit: 1000,
				offset: '0',
				...(userData?.address && { caller: userData.address })
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [profile])

	const list = !followers ? result : followersResult

	const filteredSubscribers = () => {
		if (!list) return []

		if (!filterVal.length) return list

		const lowerFilterVal = filterVal.toLowerCase()

		return list.filter(
			(el: IFollowers) =>
				el.user.address.toLowerCase().includes(lowerFilterVal) ||
				el.user.name.toLowerCase().includes(lowerFilterVal) ||
				el.user.display_name.toLowerCase().includes(lowerFilterVal)
		)
	}

	if (isLoading || isLoadingFollowers)
		return (
			<Card className='mt-10 p-8 shadow-md'>
				{[1, 2, 3].map((el: number) => (
					<div className='mb-6 flex items-center space-x-4' key={el}>
						<Skeleton className='h-12 w-12 rounded-full' />
						<div className='space-y-2'>
							<Skeleton className='h-4 w-[250px]' />
							<Skeleton className='h-4 w-[200px]' />
						</div>
					</div>
				))}
			</Card>
		)

	return (
		<Card className='p-8 shadow-md'>
			<div className='mb-6'>
				<Input placeholder='Фильтр...' onChange={e => setFilterVal(e.target.value)} value={filterVal} />
			</div>
			{filteredSubscribers().map((el: IFollowers) => (
				<Link to={`/${el.user.address}`} className='mb-6 flex flex-row items-center justify-between'>
					<UserCard user={el.user} />
					<FollowButton
						profile={el.user}
						getUserFetch={getUserFetch}
						isSubscribed={el.i_follow}
						if_follow_me={el.follows_me}
						is_my={profile.address === userData?.address}
						followers={followers}
					/>
				</Link>
			))}
		</Card>
	)
}

export default Followings
