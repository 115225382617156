// import './style.scss'
// import ConfigStore from 'store/configs.store'
// import { Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import Clam from '../assets/clam.svg?react'
import Shrimp from '../assets/shrimp.svg?react'
import Seahorse from '../assets/seahorse.svg?react'
import Fish from '../assets/fish.svg?react'
import Dolphin from '../assets/dolphin.svg?react'
import Shark from '../assets/shark.svg?react'
import Stingray from '../assets/stingray.svg?react'
import Orca from '../assets/orca.svg?react'
import Whale from '../assets/whale.svg?react'
import { AppDetailStore, LangStore } from '@/shared/model/store'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/shared/ui/tooltip'
import { DEFAULT_PREC } from '@/shared/lib'

const icons = {
	'/images/icons8-clam-50.svg': Clam,
	'/images/icons8-shrimp-50.svg': Shrimp,
	'/images/icons8-seahorse-50.svg': Seahorse,
	'/images/icons8-top-view-fish-50.svg': Fish,
	'/images/icons8-dolphin-50.svg': Dolphin,
	'/images/icons8-shark-50.svg': Shark,
	'/images/icons8-stingray.svg': Stingray,
	'/images/icons8-orca-50.svg': Orca,
	'/images/icons8-whale-50.svg': Whale
}

interface IStakerModel {
	min: number
	icon: string
	text: string
}

type Props = {
	staked: number
	color?: string
}

const StakedLevelImg: React.FC<Props> = ({ staked }) => {
	const [value, setValue] = useState<IStakerModel | null>(null)
	const { lang } = LangStore()
	const { appDetails } = AppDetailStore()
	const array = appDetails?.modules.staker_levels.staker_levels.levels || []

	const minStaked = 1000
	const maxStaked = 1000000


	const parcedStaked = staked / DEFAULT_PREC


	const getStaker = () => {
		if (parcedStaked < minStaked) {
			return null
		}

		if (parcedStaked >= maxStaked) {
			return {
				...array[array.length - 1],
				text: array[array.length - 1].name.find(el => el.locale === lang)?.text || ''
			}
		}

		for (let i = 1; i < array.length - 1; i++) {
			const currentLevel = array[i]
			const nextLevel = array[i + 1]

			if (parcedStaked >= currentLevel.min && parcedStaked < nextLevel.min) {
				return {
					...currentLevel,
					text: currentLevel.name.find(el => el.locale === lang)?.text || ''
				}
			}
		}

		return null
	}

	useEffect(() => {
		if (!staked) return

		setValue(getStaker())
	}, [lang, staked])


	if(!value?.icon) return null
	const IconComponent = icons[value.icon];

	return (
		<>
			{value?.icon && (
				<TooltipProvider>
					<Tooltip>
						<TooltipTrigger className='flex flex-col items-center justify-center'><IconComponent className="stroke-[grey] fill-[grey]"/></TooltipTrigger>
						<TooltipContent>{`${value?.text} > ${value?.min}`}</TooltipContent>
					</Tooltip>
				</TooltipProvider>
			)}
		</>
	)
}

export default StakedLevelImg
