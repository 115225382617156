import { publicClientWeb3 } from '@/app/model'
import { UserCard, UserStore } from '@/entities/user'
import { IUser } from '@/entities/user/model/types'
import { contractRead, parseAmount } from '@/shared/lib'
import useNotifier from '@/shared/lib/use-notifier'
import useRequest from '@/shared/lib/use-request'
import { ConfigStore, LangStore } from '@/shared/model/store'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import { Button, Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger, Input, Label, Preloader, useDebounce } from '@/shared/ui'
import { AmountBlock } from '@/shared/ui/amount-block/amount-block'
import AmountInput from '@/shared/ui/amount-input/ui/amount-input'
import { useEffect, useState } from 'react'
import { parseEther } from 'viem'
import { useSendTransaction } from 'wagmi'

type Props = {
    isPls?: boolean;
    isStake?: boolean
    getData: () => void;
  };


const TransferModal = ({getData, isPls, isStake} : Props) => {
    const { langPack} = LangStore()
    const { domain } = ConfigStore()
    const { userData } = UserStore()
    const { contracts } = ContractsStore()

    const { fetch, isLoading, result, dropState } = useRequest<IUser>('getUser');

    
    const [quantity, setQuantity] = useState<number>()
    const [open, setOpen] = useState<boolean>(false)
    const [balance, setBalance] = useState<bigint | number>(0n)
    const [value, setValue] = useState<string>('');


    const debouncedValue = useDebounce(value, 500);

    const { sendTransaction, isSuccess } = useSendTransaction();
    
    const address = userData?.address || '' as `0x${string}`;

    const { writeContract, isLoading: isLoadingContract } = useNotifier({
        onSuccess() {
            getData();
            setOpen(false);
        },
    })

    const getUserProfile = () => {
        dropState()
        const userParam = debouncedValue && debouncedValue.startsWith("0x")
          ? "user_addr"
          : "user_name";
    
        fetch({
          params: {
            domain: domain,
            [userParam]: debouncedValue,
          },
        });
    };

    useEffect(() => {
    if (!isSuccess) return;
        getData();
        setOpen(false);
    }, [isSuccess]);

    useEffect(() => {
        if (debouncedValue) {
          getUserProfile();
        } else {
            dropState();
        }
      }, [debouncedValue]);


    const sendTransfer = () => {
        const amount = quantity;

        if(isStake) {
            writeContract({
                address: contracts.Staking.address,
                abi: contracts.Staking.abi,
                functionName: 'transfer',
                  args: [result?.address as `0x${string}`, parseAmount(amount || 0)],
            });
            return
        }

        isPls
          ? sendTransaction({
              to: result!.address as `0x${string}`,
              value: parseEther(String(amount)),
            })
          : writeContract({
            address: contracts.SavvaToken.address,
            abi: contracts.SavvaToken.abi,
            functionName: 'transfer',
              args: [result?.address as `0x${string}`, parseAmount(amount || 0)],
            });
      };

    const updateBalance = async () => {
        const tokensAmount: bigint = await contractRead('SavvaToken', 'balanceOf', [
            address,
        ]);
        setBalance(tokensAmount);
    };

    const getPls = async () => {
        const client = publicClientWeb3()
        const bal: bigint = await client.getBalance({
            address
        });
        setBalance(bal);
    };

    const getStakingBalance = async () => {
        const res: bigint = await contractRead('Staking', 'balanceOf', [
            address,
            ]);
            setBalance(res);
        };

    const closeHandle = (state) => {
        if(!state) {
            setQuantity(undefined)
        }
        setOpen(state)
    }

    useEffect(() => {
        if(!open) return
        if(isStake) {
            getStakingBalance()
            return
        }
        isPls ? getPls() : updateBalance()
      }, [open])

    const disable = isLoading || isLoadingContract
    
  return (
    <Dialog onOpenChange={closeHandle} open={open}>
      <DialogTrigger asChild>
        <Button className="w-full z-50 text-[white]" variant='default'>{langPack('action.transfer')}</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[525px]">
        <DialogHeader>
          <DialogTitle>{langPack('staking.pass_user')}</DialogTitle>
        </DialogHeader>
        <Label>{langPack('default.user_name')}</Label>
        <Input onChange={(e) => setValue(e.target.value)} value={value}/>
        <div className='mt-0'>
            {isLoading && <Preloader small/>}
            {result?.address ? <UserCard user={result}/> : <div className='flex text-center text-[grey] text-[12px]'>{langPack('default.specify_user')}</div>}
        </div>
        <AmountInput
          value={quantity}
          onChange={setQuantity}
          placeholder={`Enter value`}
          disabled={disable}
          label={langPack('fund.quantity')}
        />
        <div className="grid grid-cols-4 items-center gap-4 relative">
            <Label className="text-right">{langPack('default.balance')}</Label>
            <div><AmountBlock amount={balance} size='sm' token={isPls ? 'PLS' : 'SAVVA'}/></div>
        </div>
        <DialogFooter>
          <Button onClick={sendTransfer} type="submit"  className="text-[white]" variant="orange" disabled={disable} loading={disable}>
            {langPack('action.transfer')}
        </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default TransferModal