import { getLangPack } from '@/shared/api'
import { LangStore } from '@/shared/model/store'

export const getCurrentLang = async (locales, defaultLocale) => {
	const { setI18n, setLang, lang } = LangStore.getState()

	try {
		const i18n = await getLangPack(locales)
		if (!i18n.ok) throw new Error(i18n.error)
	
		const target = locales.find(locale => locale.lang === lang)
			
		if (!lang || !target) {
			setLang(defaultLocale)
		}

		setI18n(i18n.data)
		return lang
	} catch (error) {
		console.error('Error: Missing app details:', error)
		throw error
	}
}
