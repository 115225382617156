import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

type ApiClientResponse<T> = { ok: true; data: T } | { ok: false; error: string }

export class ApiClient {
	private client: AxiosInstance

	constructor() {
		this.client = axios.create({
			headers: {
				'Content-type': 'application/json',
				Accept: 'application/json'
			},
			withCredentials: true 
		})
	}

	public setBaseURL(baseURL: string) {
		this.client.defaults.baseURL = baseURL
	}

	public async get<TResult>(url: string, options?: AxiosRequestConfig): Promise<ApiClientResponse<TResult>> {
		try {
			const response = await this.client.get<TResult>(url, options)
			return response.data ? { ok: true, data: response.data } : { ok: false, error: 'Network error' }
		} catch (error) {
			if (axios.isAxiosError(error)) {
				console.error(error.message)
				return { ok: false, error: error.message }
			} else {
				return { ok: false, error: 'Network error' }
			}
		}
	}

	public async post<TResult, TData>(url: string, data: TData): Promise<ApiClientResponse<TResult>> {
		try {
			const response = await this.client.post<TResult>(url, data)
			return response.data ? { ok: true, data: response.data } : { ok: false, error: 'Network error' }
		} catch (error) {
			if (axios.isAxiosError(error)) {
				console.error(error.message)
				return { ok: false, error: error.message }
			} else {
				return { ok: false, error: 'Network error' }
			}
		}
	}
}

export const apiClient = new ApiClient()
