import {
	getCurrentAppDetails,
	getCurrentAppInfo,
	getCurrentLang,
	getDefaultConnectYaml,
	getDomainStyle,
	injectGoogleAnalytics
} from '@/app/api'
import { getTokenPrice } from '@/shared/api'
import { wsConnect } from '@/shared/ws'
import { getAbis } from '@/widgets/post-editor/lib/utils'
import { useEffect, useState } from 'react'

//** TODO:
const mode = import.meta.env.MODE

const defaultYaml = '/public/default_connect.yaml'

const pathToDefaultConnect = mode === 'development' ? defaultYaml : `${window.location.origin}/default_connect.yaml`
//**

export const useInitApp = () => {
	const [isLoading, setisLoading] = useState(false)
	const [isError, setIsError] = useState(false)
	const [style, setStyle] = useState('')
	const [textLoading, setTextLoading] = useState('')
	const [title, setTitle] = useState('')
	const [favicons, setFavicons] = useState<string[]>([])

	const initApp = async () => {
		setisLoading(true)
		
		try {
			const defaultConnect = await getDefaultConnectYaml(pathToDefaultConnect)
			setTextLoading(defaultConnect.domain)

			const { appInfo, currentDomainData, currentURL, assetsUrl } = await getCurrentAppInfo(defaultConnect)
			setTextLoading(prev => (prev !== defaultConnect.domain ? defaultConnect.domain : prev))

			const style = await getDomainStyle(assetsUrl)
			setStyle(style)

			const { appDetails, locales } = await getCurrentAppDetails(assetsUrl)

			injectGoogleAnalytics(appDetails.GA_tag)
			const lang = await getCurrentLang(locales, appDetails.default_locale)

			setTitle(
				appDetails?.locales
					? (appDetails.locales.find(el => el.code === lang)?.title ?? appDetails.locales[0].title)
					: ''
			)
			setFavicons(Object.keys(appDetails.favicon))

			Promise.all([await getAbis(appInfo), await getTokenPrice()])
			wsConnect(currentURL, currentDomainData.name)
		} catch (error) {
			if (error instanceof Error) {
				console.error(error)
			} else {
				console.error('Unexpected error', error)
			}

			setIsError(true)
		} finally {
			setisLoading(false)
		}
	}

	useEffect(() => {
		initApp()
	}, [])

	return { isLoading, isError, style, textLoading, title, favicons }
}
