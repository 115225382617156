import './style.css'
import ContentImage from '@/features/content-image/ui/content-image'
import ReactionsPreview from '@/features/reaction-preview/ui/reaction-preview'
import { formattedDate, parceEthAmount } from '@/shared/lib'
import { UserName } from '@/shared/ui/user-name'
import { MessageCircle } from 'lucide-react'
import { Link } from 'react-router-dom'
import SavvaIcon from '@/shared/assets/images/savva.svg?react'
import { fixLengthPreview, getCurrentTitles } from '../model/utils'
import { ContentCategories } from './content-categories'
import { ContentStore } from '../model/content-store'
import { ContentType } from '../model/types'
import { FundRibbon } from './fund-ribbon'
import { NftIcon } from '@/shared/assets'
import Blocked from './blocked'
import { LangStore } from '@/shared/model/store'

type Props = {
	content: ContentType
}

// TODO ribbon css to tailwind
const FeedBlockLine = ({ content }: Props) => {
	const { setActivePost } = ContentStore()
	const {lang} = LangStore()

	const linkToAuthor = content.author.name.length ? `/@${content.author.name}` : `/${content.author.address}`
	return (
		<Link
			className='flex mx-auto mt-6 w-full rounded-xl shadow-md lg:mb-3 relative bg-card over'
			to={`/content/${content.short_cid.length ? content.short_cid : content.savva_cid}`}
			key={content.savva_cid}
			state={content}
			onClick={() => setActivePost(content)}
		>
				<Blocked content={content}/>
				<div className='flex w-full rounded-xl relative bg-transparent overflow-hidden'>
				<div className='rcontentative relative justify-between md:flex w-full'>
					<div className='w-full px-[20px] py-[8px] flex flex-col justify-between'>
						<div>
							<object style={{ display: 'flex' }}>
								<Link to={linkToAuthor}>
									<UserName account={content.author} sliceLen={19} />
								</Link>
							</object>
							<div className='text-500 text-base font-semibold uppercase tracking-wide'>
								{getCurrentTitles(content.savva_content.locales, 'title')}
							</div>
							<p className='mb-2 mt-1 text-gray-500 postItem leading-5'>
								{content.banned && 'BANNED'}
								{fixLengthPreview(
									getCurrentTitles(
										content.savva_content.locales,
										'text_preview'
									), 180
								)}
							</p>
						</div>
						<div>
							<ContentCategories content={content} className='w-full justify-start' />
							<div className='flex w-full flex-row items-center justify-between pl-1'>
								<div className='flex w-full flex-row items-center'>
									<div className='text-muted-foreground text-sm'>
										{formattedDate(content.timestamp, lang)}
									</div>
									<div className='row mt-[2px] flex items-center gap-1 pl-6 text-[grey]'>
										<MessageCircle color='grey' size={15} />
										<span className='text-[12px]'>{content.total_childs}</span>
									</div>
									<div className='mt-[2px] pl-6'>
										<ReactionsPreview reactions={content.reactions} />
									</div>
								</div>
								{content.fund.total_author_share > 0 && (
									<div className='flex flex-row items-center text-[14px] font-[400]'>
										{parceEthAmount(content.fund.total_author_share, '', 2)}
										<SavvaIcon className='scale-50' />
									</div>
								)}
							</div>
						</div>
					</div>
					
					<div className='rcontentative overflow-hidden rounded-b-lg md:rounded-b-none lg:rounded-r-lg  md:flex-shrink-0 relative'>
						<ContentImage content={content} />
					</div>
				</div>
				</div>
				<FundRibbon content={content}/>
				{content.nft.owner?.address && <div className='absolute top-[0px] md:-top-[10px] -right-[10px] md:right-[225px] scale-125 z-10'>
					<NftIcon />
				</div>}
		</Link>
	)
}

export default FeedBlockLine
