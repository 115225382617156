import { ILang } from '@/entities/feed-block/model/types'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export interface II18n {
	action: {
		save: string
		connect: string
	}
	app_setup: {
		title: string
		description: string
		set_default_url: string
	}
}

interface ILangStore {
	lang: ILang
	setLang: (state: string) => void
	langPack: (key: string) => string
	i18n: Record<string, II18n>
	setI18n: (state: Record<string, II18n>) => void
}

function deepMerge(target: any, source: any): any {
	
	if (typeof target !== 'object' || typeof source !== 'object') {
		return source;
	}

	const result = { ...target };

	for (const key in target) {
		if (target.hasOwnProperty(key)) {
			if (key in source) {
				// Если ключ есть в обоих объектах, выполняем глубокое объединение
				result[key] = deepMerge(target[key], source[key]);
			} else {
				// Если ключ отсутствует в новом объекте, оставляем старое значение
				result[key] = target[key];
			}
		}
	}

	// Добавляем ключи, которые есть только в новом объекте
	for (const key in source) {
		if (source.hasOwnProperty(key) && !(key in target)) {
			result[key] = source[key];
		}
	}

	return result;
}

export const getValue = (object, keys) => keys.split('.').reduce((o, k) => (o || {})[k], object)

export const LangStore = create<ILangStore>()(
	persist(
		(set, get) => ({
			lang: '',
			setLang: state => set({ lang: state }),
			i18n: {},
			setI18n: (newI18n) => set({ i18n: newI18n }),
			langPack: (key: string) => getValue(get().i18n[get().lang] || get().i18n['en'], key) ?? key
		}),
		{
			name: 'lang',
			partialize: state => ({ lang: state.lang })
		}
	)
)
