import { ChatStore } from '@/entities/chat'
import { toast } from '@/shared/lib'
import useRequest from '@/shared/lib/use-request'
import { ConfigStore } from '@/shared/model/store'

export const useChatActions = () => {
	const { fetch: fetchChatNew, isLoading: isLoadingSend } = useRequest('chatNew')
	const { fetch: fetchChatEdit } = useRequest('chatEdit')

	const {domain} = ConfigStore()
	const {activeChat} = ChatStore()

	const sendMessage = async (value: string) => {
		if (!activeChat) return
		await fetchChatNew({
			params: {
				domain,
				'chat-id': activeChat.chat_id,
				text: value
			},
			onError: () => toast({ title: 'Error', variant: 'destructive' })
		})
	}

	const sendEditMessage = async (n: number, text: string) => {
		if (!activeChat) return
		await fetchChatEdit({
			params: {
				domain: domain,
				'chat-id': activeChat.chat_id,
				n,
				text
			},
			onError: () => toast({ title: 'Error', variant: 'destructive' })
		})
	}
	
	return { sendMessage, sendEditMessage, isLoadingSend }
}
