import { Toast, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from '@/shared/ui/toast'
import { ToasterStatusType, useToast } from '../lib'
import { RefreshCw } from 'lucide-react';

const GetState = ({type}: {type?: ToasterStatusType}) => {
	switch (type) {
		case 'pending':
			return <RefreshCw className="mr-2 h-4 w-4 animate-spin" />
	
		default:
			return <></>
	}
}

export function Toaster() {
	const { toasts } = useToast()

	return (
		<ToastProvider>
			{toasts.map(function ({ id, title, description, action, type, ...props }) {
				return (
					<Toast key={id} {...props}>
						<div className='grid gap-1'>
							{title && <ToastTitle>{title}<GetState type={type}/></ToastTitle>}
							{description && <ToastDescription className='text-[12px] break-words box-border'>{description}</ToastDescription>}
						</div>
						{action}
						<ToastClose />
					</Toast>
				)
			})}
			<ToastViewport />
		</ToastProvider>
	)
}
