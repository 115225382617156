import { useTheme } from '@/shared/lib'
import { LangStore } from '@/shared/model/store/lang-store'
import { Locale, RainbowKitProvider, darkTheme, lightTheme } from '@rainbow-me/rainbowkit'
import '@rainbow-me/rainbowkit/styles.css'

export const WalletProvider = ({ children }: { children: React.ReactNode }) => {
	const { lang } = LangStore()
	const { theme } = useTheme()
	const currentLang = lang.toLocaleLowerCase() as Locale

	const customTheme =
		theme === 'dark'
			? darkTheme({ accentColor: 'hsl(var(--primary))' })
			: lightTheme({ accentColor: 'hsl(var(--primary))' })

	return (
		<RainbowKitProvider theme={customTheme} locale={currentLang}>
			{children}
		</RainbowKitProvider>
	)
}
