import { AppInfoStore } from '@/shared/model/store'
import { getDefaultConfig } from '@rainbow-me/rainbowkit'
import {
	rainbowWallet,
	walletConnectWallet,
	enkryptWallet,
	coreWallet,
	frameWallet,
	rabbyWallet,
	metaMaskWallet
} from '@rainbow-me/rainbowkit/wallets'
import { createPublicClient, http } from 'viem'
import { pulsechain, pulsechainV4 } from 'viem/chains'

const projectId = '8e86e632ba3ffe4207396f98577a9a3e'

export const availableNetwork = {
    943: pulsechainV4,
    369: pulsechain
}


export const getWeb3config = () => {
    const state = AppInfoStore.getState()
    const currentChain = availableNetwork[state.appInfo.blockchain_id]
    return getDefaultConfig({
	appName: 'Savva',
	projectId,
	chains: [currentChain],
	wallets: [
		{
			groupName: 'Suggested',
			wallets: [rainbowWallet, metaMaskWallet, rabbyWallet, frameWallet, walletConnectWallet, enkryptWallet, coreWallet]
		}
	]
})
}

export const publicClientWeb3 = () => {
	const state = AppInfoStore.getState()
    const currentChain = availableNetwork[state.appInfo.blockchain_id]

	return createPublicClient({
		chain: currentChain,
		transport: http()
	})
}

export const web3config = getDefaultConfig({
	appName: 'Savva',
	projectId,
	chains: [pulsechainV4],
	wallets: [
		{
			groupName: 'Suggested',
			wallets: [rainbowWallet, metaMaskWallet, frameWallet, walletConnectWallet, enkryptWallet, coreWallet]
		}
	]
})