import { ContentStore } from '@/entities/feed-block/model/content-store'
import { UserStore } from '@/entities/user'
import { useWallet } from '@/entities/wallet'
import { GuardButton } from '@/features/guard-button'
import { DEFAULT_SPONSOR_VALUES } from '@/pages/profile/ui/settings/general'
import { cn, contractRead, DEFAULT_PREC, getSplitTransaction, stringToBytes32 } from '@/shared/lib'
import useNotifier from '@/shared/lib/use-notifier'
import { ConfigStore, LangStore } from '@/shared/model/store'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import {
	Button,
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger
} from '@/shared/ui'
import { AmountBlock } from '@/shared/ui/amount-block/amount-block'
import AmountInput from '@/shared/ui/amount-input/ui/amount-input'
import { useEffect, useState } from 'react'

const DEFAULT_CONFIG = {
	authorShare: 0,
	nftOwnerCut: 0,
	minContribution: 0,
	allowance: 0
}

const ContributeModal = () => {
	const { langPack } = LangStore()
	const { activePost } = ContentStore()
	const { domain } = ConfigStore()
	const [quantity, setQuantity] = useState<number | undefined>(undefined)
	const [open, setOpen] = useState(false)
	const [config, setConfig] = useState<typeof DEFAULT_CONFIG>(DEFAULT_CONFIG)
	const { contracts } = ContractsStore()
	const { userData } = UserStore()
	const {savvaBalance} = useWallet()
	
	const { writeContract, isLoading } = useNotifier({
		onSuccess() {
			closeHandle(false)
		}
	})

	const contribute = async () => {
		const amount = quantity
		const { message, v, r, s } = await getSplitTransaction({
			token: 'SAVVA',
			spender: contracts!.ContentFund.address,
			amount
		})
		
		await writeContract({
			address: contracts.ContentFund.address,
			abi: contracts.ContentFund.abi,
			functionName: 'contributeWithPermit',
			args: [activePost?.author.address, domain, activePost?.guid, message.value, message.deadline, v, r, s]
		})
	}

	const closeHandle = state => {
		if (!state) {
			setQuantity(undefined)
		}
		setOpen(state)
	}

	const getConfig = async () => {
		const newConfig = DEFAULT_CONFIG
		newConfig.authorShare =
			Number((await contractRead('Config', 'getUInt', [stringToBytes32('authorShare')])) || 0) / 100
		newConfig.nftOwnerCut =
			Number((await contractRead('Config', 'getUInt', [stringToBytes32('nftOwnerCut')])) || 0) / 100
		newConfig.minContribution =
			Number((await contractRead('Config', 'getUInt', [stringToBytes32('minContribution')])) || 0) / DEFAULT_PREC

		setConfig(newConfig)
	}

	useEffect(() => {
		if (!open) return
		getConfig()
	}, [open])

	const disable = isLoading

	return (
		<Dialog onOpenChange={closeHandle} open={open}>
			<DialogTrigger asChild>
				<GuardButton
					requireAuth
					title={langPack('fund.button')}
					varinat='orange'
					className='z-50 w-full text-[white]'
				/>
			</DialogTrigger>
			<DialogContent className='sm:max-w-[525px]'>
				<DialogHeader>
					<DialogTitle>{langPack('fund.modal_title')}</DialogTitle>
					<DialogDescription>
						{`${langPack('fund.modal_note_text')}: ${config.minContribution} SAVVA, ${langPack('fund.modal_note_text_two')}: ${config.authorShare + config.nftOwnerCut}% - ${langPack('fund.modal_note_text_three')}, ${100 - config.authorShare - config.nftOwnerCut}% - ${langPack('fund.modal_note_text_four')}`}
					</DialogDescription>
					{
						<div className='flex flex-row items-center justify-between'>
							<h3 className={cn(!savvaBalance && 'text-destructive')}>{langPack('default.balance')}</h3>
							<AmountBlock amount={savvaBalance * DEFAULT_PREC}/> 
						</div>
					}
				</DialogHeader>
				<div className='flex w-full flex-row items-center justify-between'>
					{
						(userData?.sponsor_values || DEFAULT_SPONSOR_VALUES).map((el: number, idx: number) => {
							if (!el) return null

							return (
								<Button
									key={idx}
									size='sm'
									disabled={disable}
									variant={el === quantity ? 'orange' : 'secondary'}
									className='w-[18%]'
									onClick={() => setQuantity(el)}
								>
									{el}
								</Button>
							)
						})}
				</div>
				<AmountInput
					value={quantity}
					onChange={setQuantity}
					placeholder={`min ${config.minContribution} SAVVA`}
					disabled={disable}
					label={langPack('fund.quantity')}
				/>
				<DialogFooter>
					<Button
						onClick={contribute}
						type='submit'
						className='text-[white]'
						variant='orange'
						disabled={(!quantity || quantity > savvaBalance)}
						loading={disable}
					>
						{langPack('fund.button')}
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}

export default ContributeModal
