import { cn, useTheme } from '@/shared/lib'
import ReactMdEditor, { MDEditorProps } from '@uiw/react-md-editor'
import { FC } from 'react'
import rehypeSanitize from 'rehype-sanitize'
import { Media } from './media'
import { urlTransform } from '../lib/utils'
import remarkBreaks from 'remark-breaks'

type Props = {
	value: string
	onChange: (value: string) => void
	extraCommands?: MDEditorProps['extraCommands']
	disabled?: boolean
	preview?: MDEditorProps['preview']
	pathToFolder?: string
}

export const MdEditor: FC<Props> = ({
	value,
	onChange,
	extraCommands,
	disabled,
	preview = 'live',
	pathToFolder = ''
}) => {
	const { theme } = useTheme()

	return (
		<ReactMdEditor
			value={value}
			onChange={e => onChange(e || '')}
			data-color-mode={theme}
			aria-disabled={disabled}
			className='!border-input !bg-background'
			preview={preview}
			height={500}
			extraCommands={extraCommands}
			previewOptions={{
				urlTransform: url => urlTransform(`${pathToFolder}/${url}`),
				rehypePlugins: [[rehypeSanitize]],
				remarkPlugins:[[remarkBreaks]],
				components: {img: Media},
				className: cn('!bg-background')
			}}
		/>
	)
}
