import useNotifier from '@/shared/lib/use-notifier'
import useRequest from '@/shared/lib/use-request'
import { AppInfoStore, ConfigStore, LangStore } from '@/shared/model/store'
import { ContractsStore } from '@/shared/model/store/contracts-store'
import { IDomain } from '@/shared/model/types'
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
	Button,
	Card,
	Label,
	Switch
} from '@/shared/ui'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/shared/ui/tabs'
import { useEffect, useState } from 'react'
import UploadFolder from './UploadFolder'
// import { initApp } from '@/shared/model/store/init-app'
import { RefreshCw } from 'lucide-react'
import { toast } from '@/shared/lib'

export const ConfigurationPage = () => {
	const { domain: stateDomain, testMode, setTestMode } = ConfigStore()
	const { contracts } = ContractsStore()
	const { appInfo } = AppInfoStore()
	const { langPack } = LangStore()
	const { fetch: fetchUploadFolderConfig, isLoading, isSuccess } = useRequest('uploadFolderConfig')
	const { fetch: fetchIpfsDir } = useRequest('getIpfsDir')
	const { fetch: fetchSetAssets } = useRequest('ipfsAssets')

	const [currentTab, setCurrentTab] = useState<string>('config')
	const [files, setFiles] = useState<File[]>([])
	const [loadingTestMode, setLoadingTestMode] = useState<boolean>(false)

	const domain = stateDomain
	const ipfsAssets = appInfo.domains.find((el: IDomain) => el.name === domain)?.assets_cid || ''

	const { writeContract } = useNotifier({
		onSuccess: () => {
			setTimeout(() => window.location.replace('/'), 500)
		}
	})

	const setSettings = async (hash: string) => {
		writeContract({
			abi: contracts.ContentRegistry.abi,
			address: contracts.ContentRegistry.address,
			functionName: 'command',
			args: [domain, 'set_domain_assets_cid', hash, '', '', '']
		})
	}

	const deleteAssets = () => {
		writeContract({
			abi: contracts.ContentRegistry.abi,
			address: contracts.ContentRegistry.address,
			functionName: 'command',
			args: [domain, 'set_domain_assets_cid', '', '', '', '']
		})
	}

	const setConfig = () => {
		fetchSetAssets({
			params: {
				domain
			},
			onSuccess(hash: { cid: string }) {
				setSettings(hash.cid)
			}
		})
	}

	const downloadConfig = () => {
		const configUrl = `${appInfo.assets_url}/${domain}/${ipfsAssets}`
		fetchIpfsDir({
			baseUrl: `${configUrl}`
		})
	}

	const checkTestConfig = async () => {
		const baseUrl = `${appInfo.temp_assets_url}/${domain}/config.yaml`

		try {
			await fetch(baseUrl)
		} catch (error) {
			toast({
				title: 'Test config not found',
				variant: 'destructive'
			})
			setTestMode(false)
		}
	}

	const onSubmit = () => {
		const formData = new FormData()

		// Read selected files
		for (let i = 0; i < files.length; i++) {
			formData.append('file', files[i])
		}

		formData.append('domain', domain)

		fetchUploadFolderConfig({
			data: formData,
			isMultipart: true,
			params: {
				domain
			},
			onSuccess() {
				setFiles([])
			}
		})
	}

	const switchTestMode = (state: boolean) => {
		setLoadingTestMode(true)
		setTestMode(state)
		setTimeout(() => {
			// initApp()
			window.location.reload()
			setLoadingTestMode(false)
		}, 1000)
	}

	useEffect(() => {
		checkTestConfig()
	}, [])

	// if (loading) return <Preloader />

	const isConfig = Array.from(files)?.find((el: File) => el.name.includes('config.yaml'))

	return (
		<Card className='container p-4'>
			<Tabs onValueChange={setCurrentTab} value={currentTab}>
				<TabsList>
					<TabsTrigger value='config'>{langPack('default.configuration')}</TabsTrigger>
					<TabsTrigger value='blocked'>{langPack('configuration.locks')}</TabsTrigger>
				</TabsList>
				<TabsContent value='config' className='py-2'>
					<div className='flex flex-row items-center gap-6'>
						<div className='flex flex-row items-center gap-4'>
							<Switch id='test-mode' onCheckedChange={switchTestMode} checked={testMode} />
							<Label htmlFor='test-mode flex flex-row gap-4'>
								{loadingTestMode ? (
									<RefreshCw className='mr-2 h-4 w-4 animate-spin' />
								) : (
									<div>{langPack('configuration.test_mode')}</div>
								)}
							</Label>
						</div>
						<div className='flex flex-row gap-4'>
							<Button onClick={downloadConfig} disabled={!ipfsAssets.length} size={'sm'}>
								{langPack('configuration.get_config')}
							</Button>
							<Button onClick={setConfig} size={'sm'} disabled={!testMode}>
								{langPack('configuration.set_config')}
							</Button>
							<Button onClick={onSubmit} size={'sm'} disabled={!isConfig || isLoading} loading={isLoading}>
								{langPack('configuration.save_setting')}
							</Button>

							<AlertDialog>
								<AlertDialogTrigger asChild>
									<Button size={'sm'} disabled={isLoading} loading={isLoading}>
										{langPack('configuration.delete_assets')}
									</Button>
								</AlertDialogTrigger>
								<AlertDialogContent>
									<AlertDialogHeader>
										<AlertDialogTitle>{langPack('configuration.delete_assets')}</AlertDialogTitle>
										<AlertDialogDescription>{langPack('configuration.dialog_desk')}</AlertDialogDescription>
									</AlertDialogHeader>
									<AlertDialogFooter>
										<AlertDialogCancel>{langPack('action.leave')}</AlertDialogCancel>
										<AlertDialogAction onClick={deleteAssets}>ок</AlertDialogAction>
									</AlertDialogFooter>
								</AlertDialogContent>
							</AlertDialog>
						</div>
					</div>
					<div>
						<UploadFolder setFiles={setFiles} files={files} isLoading={isLoading} isSuccess={isSuccess} />
					</div>
				</TabsContent>
				<TabsContent value='blocked' className='py-2'>
					{langPack('configuration.locks')}
				</TabsContent>
			</Tabs>
		</Card>
	)
}