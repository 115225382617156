import { useChainModal } from '@rainbow-me/rainbowkit'
import { useAccount, useSignMessage } from 'wagmi'
import { AppInfoStore, ConfigStore, LangStore } from '@/shared/model/store'
import { UserStore } from '@/entities/user/model/user-store'
import useRequest from '@/shared/lib/use-request'
import { Button } from '@/shared/ui'
import { getContentFromHash } from '@/widgets/post-editor/lib/utils'
import { ChatStore } from '@/entities/chat'

//TODO: Вынести метод из profile getUserFetch

export const Auth = () => {
	const { chain } = useAccount()
	const { openChainModal } = useChainModal()
	const { address } = useAccount()
	const { signatureAddress, setSignatureAddress, setUserData } = UserStore()
	const { setActiveChat, chats } = ChatStore()
	const {domain} = ConfigStore()
	const message = AppInfoStore(state => state.appInfo.auth_text_to_sign)
	const {langPack} = LangStore()
	//FIXME: Придумать как сделать так что бы не пришлось придумывать названия методам или обектам
	const fetchAuth = useRequest('auth')
	const fetchLogout = useRequest('logout')
	const fetchUser = useRequest('getUser')

	//FIXME: Добавить типы 
	const getUser = async () => {
		await fetchUser.fetch({
			params: {
				domain,
				user_addr: address,
			},
			onSuccess: async (res) => {
				const userProfile = await getContentFromHash(res.profile_cid)
				const parced = userProfile ? JSON.parse(userProfile) : {}
				setUserData({...res, ...parced})
			}
		})
	};

	const siweMessage = async (signature: string) => {
		if (!address) return

		await fetchAuth.fetch({
			params: {
				user_addr: address,
				signature
			},
			onSuccess: async () => {
				await getUser()

				setSignatureAddress(address)
				setActiveChat(chats.find(el => el.owner.address === address) ?? chats[0])
			}
		})
	}

	const { signMessageAsync } = useSignMessage({
		mutation: {
			onSuccess: async data => {
				await siweMessage(data)
			}
		}
	})

	const siwe = async () => (!chain && openChainModal ? openChainModal() : signMessageAsync({ message }))

	//FIXME: Обработать ошибки
	const logout = async () => {
		await fetchLogout.fetch({
			onSuccess: async () => {
				setSignatureAddress(null)
				getUser()
			}
		})
	}

	return (
		<Button variant="secondary" className='w-full' onClick={signatureAddress ? logout : siwe}>
			{signatureAddress ? langPack('action.logout') : langPack('action.login')}
		</Button>
	)
}
