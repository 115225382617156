import { create } from 'zustand'
import { IAppInfo } from '../types/app-info'

interface IAppDetailsStore {
	appInfo: IAppInfo
	setAppInfo: (state: IAppInfo) => void
}

export const AppInfoStore = create<IAppDetailsStore>(set => ({
	appInfo: {} as IAppInfo,
	setAppInfo: state => set({ appInfo: state })
}))