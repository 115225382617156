import { ROUTER } from '@/shared/router'
import { ContentEditor } from '@/widgets/post-editor'
import { Navigate, useLocation } from 'react-router-dom'

const contentType = {
	'/new-post': 'post',
	'/new-comment': 'comment',
	'/post-edit': 'post-edit',
	'/comment-edit': 'comment-edit'
}

export const EditorPage = () => {
	const { pathname, state } = useLocation()

	const keywords = ['/post-edit', '/comment-edit']
	const matchedKey = keywords.find(keyword => pathname.startsWith(keyword))

	if (matchedKey && !state) return <Navigate to={ROUTER.leaders} />
	const contentTypeKey = Object.keys(contentType).find(key => pathname.startsWith(key))

	return <ContentEditor contentType={contentType[contentTypeKey!]} />
}
