import { ReactElement } from 'react'
import { Avatar, AvatarFallback } from './avatar'
import { cn } from '../lib'
import { CheckCheck } from 'lucide-react'

export type StepperItem = {
    label: string
    value: string
    icon?: ReactElement | string
    component?: ReactElement
    disabled?: boolean
    success?: boolean
    active?: boolean
}

type Props = {
    items: StepperItem[]
    onSelect: (value: string) => void
    current: string
}

const Stepper = ({items, onSelect, current}: Props) => {
  return (
    <div className='min-h-[80px]'>
         <ol className="flex flex-row items-center w-full justify-center">
                {
                    items.map((el: StepperItem, index: number, arr: StepperItem[]) => (
                        <li className={
                                cn(
                                    "flex items-center cursor-pointer",
                                    index + 1 < arr.length && "w-full after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-100 after:border-4 after:inline-block dark:after:border-gray-700"                                )
                            }
                            onClick={() => !el.disabled && onSelect(el.value)}
                        >
                            <div className='flex flex-col justify-center items-center relative'>
                                <Avatar className={cn('h-[50px] w-[50px] border-[4px]', el.value === current && 'border-primary', el.success && 'border-[#50C878]')}>
                                    <AvatarFallback className={cn(el.disabled && 'opacity-70')}>
                                        {el.success ? <CheckCheck color='#50C878'/> : el.icon}
                                    </AvatarFallback>
                                </Avatar>
                                <div className='flex w-max-[70px] mt-4 absolute top-[50px]'>{el.label}</div>
                            </div>
                        </li>
                    ))
                }
            </ol>   
            <div className='mt-[80px]'>
                {items.find((el: StepperItem) => el.value === current)?.component || <></>}
            </div>
    </div>
    
  )
}

export default Stepper