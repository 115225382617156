import { useTheme } from '@/shared/lib'
import { LangStore } from '@/shared/model/store'
import { Button, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/shared/ui'
import { Moon, Sun } from 'lucide-react'

export const ThemeToggle = () => {
	const { setTheme } = useTheme()
	const { langPack } = LangStore()

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant='outline' size='icon'>
					<Sun className='h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0' />
					<Moon className='absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100' />
					<span className='sr-only'>Toggle theme</span>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align='end'>
				<DropdownMenuItem onClick={() => setTheme('light')}>{langPack('default.light')}</DropdownMenuItem>
				<DropdownMenuItem onClick={() => setTheme('dark')}>{langPack('default.dark')}</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	)
}
