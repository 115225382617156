import yaml from 'yaml'
import axios from 'axios'
import { IAppDetails } from '../model/types/app-details'

function prependPathToHref(obj: object, path: string): object {
	for (const key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			const value = obj[key]

			if (key === 'href' && typeof value === 'string' && !value.startsWith(path)) {
				obj[key] = path + value
			} else if (typeof value === 'object' && !Array.isArray(value)) {
				prependPathToHref(value, path)
			}
		}
	}
	return obj
}

type ApiClientResponse<T> = { ok: true; data: T } | { ok: false; error: string }

export const getAppDetails = async (url: string): Promise<ApiClientResponse<IAppDetails>> => {
	try {
		const { data } = await axios.get(url + 'config.yaml')

		const result = yaml.parse(data) as IAppDetails

		prependPathToHref(result.favicon, url)

		Object.entries(result.logo).forEach(([key, value]) => {
			result.logo[key] = url + `${value}`
		})

		const modules = result.modules
		for (const [key, value] of Object.entries(modules)) {
			const response = await fetch(url + `${value}`)
			const moduleData = await response.text()
			const parsedData = yaml.parse(moduleData)
			result.modules[key] = parsedData
		}

		return result ? { ok: true, data: result } : { ok: false, error: 'Network error' }
	} catch (error) {
		if (axios.isAxiosError(error) || error instanceof Error) {
			console.error(error.message)
			return { ok: false, error: error.message }
		}

		throw console.log(error)
	}
}
