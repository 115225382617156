import { Skeleton } from "@/shared/ui/skeleton"

export const ChatSkeleton = () => {
  return (
    <>
    {[...Array(12)].map((el: number) => (
        <div className="flex cursor-pointer justify-between gap-7 border-l-2 p-2 py-3 pl-2" key={el}>
            <div className='flex items-center w-full flex-row justify-center'>
                    <Skeleton className='h-[40px] w-[50px] rounded-full' />
                    <div className='flex flex-col w-full ml-2 items-center justify-center relative -mt-1'>
                            <div className="flex flex-row justify-between w-full">
                                <Skeleton className='h-4 w-24 rounded-md' />
                                <Skeleton className='h-4 w-12 rounded-md' />
                            </div>
                            <div className='text-[grey] text-[12px] leading-[14px] w-full mt-1'>
                                <Skeleton className='h-2 w-28 rounded-md' />
                                <Skeleton className='h-2 w-24 rounded-md mt-1' />
                            </div>
                        </div>
                </div>
        </div>
    ))}
    </>
  )
}