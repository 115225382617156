import { Button, Preloader } from '@/shared/ui'
import { Alert, AlertDescription, AlertTitle } from '@/shared/ui/alert'
import { useEffect, useState } from 'react'
import DirectoryTree from './DirectoryTree'
import { LangStore } from '@/shared/model/store'

const UploadFolder = ({ setFiles, isLoading, files }: any) => {
	const [filessss, setFilesssss] = useState<File[]>([])

	const { langPack } = LangStore()

	const handleFilesChange = (fileList: File[]) => {
		if (!fileList) return
		const filesArray = Array.from(fileList).map((file: File) => {
			const filePath = file.webkitRelativePath
			const pathParts = filePath.split('/')
			const name = pathParts.pop()
			const isDirectory = name === ''
			return {
				name: isDirectory ? pathParts.pop() : name,
				webkitRelativePath: filePath,
				type: file.type,
				children: []
			}
		})

		const directoryTree: any = {}

		filesArray.forEach(file => {
			const pathParts = file.webkitRelativePath.split('/')
			pathParts.shift() // remove the first empty element

			let currentDirectory: any = directoryTree
			pathParts.forEach(part => {
				if (!currentDirectory.children) {
					currentDirectory.children = []
				}
				const directory = currentDirectory.children.find((child: any) => child.name === part && child.type === '')
				if (directory) {
					currentDirectory = directory
				} else {
					const newDirectory = {
						name: part,
						webkitRelativePath: `${currentDirectory.webkitRelativePath}/${part}`,
						orp: file.webkitRelativePath,
						type: '',
						children: []
					}

					currentDirectory.children.push(newDirectory)
					currentDirectory = newDirectory
				}
			})

			currentDirectory.children.push(file)
		})
		setFilesssss(directoryTree.children)
	}

	const isConfig = filessss?.find((el: File) => el.name.includes('config.yaml'))

	useEffect(() => {
		if (!files.length) return
		handleFilesChange(files)
	}, [files])

	const removeFile = (webkitRelativePath: string) => {
		setFiles((prev: File[]) => prev.filter((el: File) => el.webkitRelativePath !== webkitRelativePath))
	}

	const handleFileDownload = (list: any) => {
		setFiles(list.target.files)
	}

	return (
		<div>
			{isLoading ? (
				<Preloader small />
			) : (
				<>
					{!filessss.length && (
						<div className='my-3 flex w-full items-center justify-center'>
							<label
								for='dropzone-file'
								className='flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600 dark:hover:bg-gray-800'
							>
								<div className='flex flex-col items-center justify-center pb-6 pt-5'>
									<svg
										className='mb-4 h-8 w-8 text-gray-500 dark:text-gray-400'
										aria-hidden='true'
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 20 16'
									>
										<path
											stroke='currentColor'
											stroke-linecap='round'
											stroke-linejoin='round'
											stroke-width='2'
											d='M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2'
										/>
									</svg>
									<p className='mb-2 text-sm text-gray-500 dark:text-gray-400'>
										<span className='font-semibold'>{langPack('configuration.directory_selection')}</span>
									</p>
									<p className='text-xs text-gray-500 dark:text-gray-400'>
										{langPack('configuration.directory_selection_info')}
									</p>
								</div>
								<input
									id='dropzone-file'
									className='hidden'
									type='file'
									multiple
									mozdirectory=''
									webkitdirectory=''
									directory=''
									onChange={handleFileDownload}
								/>
							</label>
						</div>
					)}
					{files.length
						? !isConfig && (
								<Alert variant={'destructive'} className='my-4'>
									<AlertTitle>{langPack('configuration.directory_selection_error')}</AlertTitle>
									<AlertDescription>{langPack('configuration.directory_selection_error_info')}</AlertDescription>
								</Alert>
							)
						: null}
					{files.length ? <DirectoryTree files={filessss} removeFile={removeFile} /> : null}
					{filessss.length ? (
						<Button
							className='w-full'
							variant={'destructive'}
							onClick={() => {
								setFiles([])
								setFilesssss([])
							}}
						>
							{langPack('action.clear')}
						</Button>
					) : null}
				</>
			)}
		</div>
	)
}

export default UploadFolder
