import { SocketEvents } from '@/shared/ws'
import { create } from 'zustand'

export type SocketEventsTypes =
	| 'chat_new'
	| 'chat_delete'
	| 'chat_edit'
	| 'react'
	| 'chat_banned'
	| 'chat_user_banned'
	| 'chat_unbanned'
	| 'chat_user_unbanned'
	| 'fund_contributed'
	| 'fund_prize'
	| 'content_processed'
	| 'nft_removed_from_market'
	| 'nft_added_to_market'
	| 'nft_minted'
	| 'nft_price_changed'
	| 'nft_bought'
	| 'nft_burned'
	| 'nft_transferred'
	| 'token_price_changed'
	| 'banned_user'
	| 'unbanned_user'
	| 'banned_post'
	| 'unbanned_post'
	| 'club_buy'

type Messages  = {
    [k in keyof SocketEventsTypes]: SocketEvents
}

interface ISocketStore {
	messages: Messages
	last: SocketEvents
	setMessage: (state: SocketEvents) => void
}

export const SocketStore = create<ISocketStore>(set => ({
	messages: {} as Messages,
	last: {} as SocketEvents,
	setMessage: (mes: SocketEvents) => {
		set(state => ({ messages: {...state.messages, [mes.type]: mes.data} }))
		set(() => ({ last: mes}))
	}
}))
