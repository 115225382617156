import { FeedPage } from '@/pages'
import { CommentPage } from '@/pages/comment-page'
import { ConfigurationPage } from '@/pages/configuration'
import { ContentPage } from '@/pages/content'
import { EditorPage } from '@/pages/editor'
import { GovernancePage } from '@/pages/governance'
import { ProfilePage } from '@/pages/profile'
import { ReedeemPage } from '@/pages/redeem-promo'
import { Layout } from '@/shared/layout'
import { ROUTER } from '@/shared/router'
import { Preloader } from '@/shared/ui/preloader'
import { Header } from '@/widgets/header'
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom'

const router = createBrowserRouter([
	{
		path: '/',
		element: <Layout header={<Header />} />,
		children: [
			{
				index: true,
				element: <Navigate to={ROUTER.leaders} replace />
			},
			{
				path: ROUTER.leaders,
				element: <FeedPage pageType={ROUTER.leaders} />
			},
			{
				path: ROUTER.actual,
				element: <FeedPage pageType={ROUTER.actual} />
			},
			{
				path: ROUTER.new,
				element: <FeedPage pageType={ROUTER.new} />
			},
			{
				path: ROUTER.forYou,
				element: <FeedPage pageType={ROUTER.forYou} />
			},
			{
				path: ROUTER.comments,
				element: <CommentPage />
			},
			{
				path: `${ROUTER.content}/:id`,
				element: <ContentPage />
			},
			{
				path: ROUTER.newPost,
				element: <EditorPage key={ROUTER.newPost}/>
			},
			{
				path: `${ROUTER.postEdit}/:id`,
				element: <EditorPage key={ROUTER.postEdit}/>
			},
			{
				path: ROUTER.newComment,
				element: <EditorPage  key={ROUTER.newComment} />
			},
			{
				path: `${ROUTER.commentEdit}/:id`,
				element: <EditorPage  key={ROUTER.commentEdit} />
			},
			{
				path: ROUTER.configuration,
				element: <ConfigurationPage />
			},
			{
				path: ':id',
				element: <ProfilePage />
			},
			{
				path: `${ROUTER.promoCode}/:id`,
				element: <ReedeemPage />
			},
			{
				path: ROUTER.governance,
				element: <GovernancePage />
			},
			{
				path: '*',
				element: <Navigate to={ROUTER.leaders} replace />
			}
		]
	}
])

export const AppRouter = () => <RouterProvider router={router} fallbackElement={<Preloader />} />
