import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ILang } from "@/entities/feed-block/model/types";
import MarkdownIt from 'markdown-it';
import plainText from 'markdown-it-plain-text';

dayjs.extend(utc);
dayjs.extend(relativeTime);

export const DEFAULT_PREC = 1000000000000000000;

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const formatNumber = (
  number: number,
  lang: string
): string => {
  if (!number) return "0";
  const currentLang = `${lang.toLocaleLowerCase()}-${lang}`;
  
  return new Intl.NumberFormat(currentLang, {
    maximumFractionDigits: Math.floor(number) < 1 ? 4 : 2,
  }).format(number);
};

export const getEllipsisTxt = (str: string, n = 6, delimetr = '...') => {
  if (str) {
    return `${str.slice(0, n)}${delimetr}${str.slice(str.length - n)}`;
  }
  return '';
};

export const parceEthAmount = (data: string | number, token: string = '', fix = 4) => `${(data ? Number(Number(data) / DEFAULT_PREC) : 0).toFixed(fix)} ${token}`;

export const checkEthAddress = (str: string) => str.match(/^0x[a-fA-F0-9]{40}$/g);

export const shortNumberFormat = (number: number) => {
  return number.toLocaleString('en-US', {
    // add suffixes for thousands, millions, and billions
    // the maximum number of decimal places to use
    maximumFractionDigits: 2,
    // specify the abbreviations to use for the suffixes
    notation: 'compact',
    compactDisplay: 'short'
  });
}

const TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const TIME_FORMAT_TIME = 'HH:mm:ss';
const TIME_FORMAT_STR = 'DD MMMM';
const TIME_FORMAT_STR_WITHYEAR = 'DD MMMM YYYY';

export const getUTCTimeToShow = (tUTC: any, locale: string): string => dayjs.utc(tUTC, TIME_FORMAT).locale(locale).fromNow();
export const getUTCTimeToShowStr = (tUTC: string): string => dayjs.utc(tUTC, TIME_FORMAT).format(TIME_FORMAT_STR);
export const getUTCTimeToShowStrFull = (tUTC: string): string => dayjs.utc(tUTC, TIME_FORMAT).format(TIME_FORMAT);
export const getUTCTimeToShowStrShortDate = (tUTC: string, locale: ILang): string => dayjs.utc(tUTC).locale(locale).format(TIME_FORMAT_STR_WITHYEAR);
export const getUTCTimeMMSS = (tUTC: string): string => dayjs.utc(tUTC).format(TIME_FORMAT_TIME);

export default function cleanMDtext(text: string) {
  if (text?.length > 0) {
    const md = new MarkdownIt();
    md.use(plainText);
    md.render(text);
    // Here are plain text result
    const result = (md as any).plainText;
    return result;
  }
  return text;
}

export const sortDesc = (a, b) => {
  const dateA = new Date(a.date);
  const dateB = new Date(b.date);
 
  return dateA - dateB;
}

export const cleanPreview = (input: string) => {
  // Remove Markdown images ![alt](url)
  let plain = input.replace(/!\[.*?\]\(.*?\)/g, "");

  // Remove Markdown links [text](url)
  plain = plain.replace(/\[(.*?)\]\(.*?\)/g, "\$1");

  // Remove Markdown headings ##
  plain = plain.replace(/^(#{1,6})\s*/gm, "");

  // Remove inline code and code blocks
  plain = plain.replace(/`([^`]*)`/g, "\$1");
  plain = plain.replace(/```[\s\S]*?```/g, "");

  // Remove emphasis *italic* or bold
  plain = plain.replace(/\*\*([^*]+)\*\*|\*([^*]+)\*/g, "\$1\$2");

  // Remove underscores _italic_ or bold
  plain = plain.replace(/_([^_]+)_/g, "\$1");

  // Remove blockquotes > text
  plain = plain.replace(/^>\s*/gm, "");

  // Remove horizontal rules ---
  plain = plain.replace(/^-{3,}\s*$/gm, "");

  // Remove unordered list markers - or *
  plain = plain.replace(/^[-*]\s+/gm, "");

  // Remove ordered list markers 1. 2. 3.
  plain = plain.replace(/^\d+\.\s+/gm, "");

  // Remove extra Markdown tags not explicitly handled
  plain = plain.replace(/[\\\[\]{}#|<>~^]/g, "");

  // Replace all line breaks (CR and LF) with spaces
  plain = plain.replace(/\n/g, " ").replace(/\r/g, " ");

  // Truncate to the first 256 characters
  if (plain.length > 1024) {
    plain = plain.substring(0, 1024);
  }

  // Remove multiple consecutive spaces
  plain = plain.replace(/\s+/g, " ");

  return plain;
}
 