import yaml from 'yaml'
import { IEditorLocale, IEditorSavvaContent } from '../model/types'
import { apiClient } from '@/shared/api/api-client'

export const getContentFromYaml = async (url: string) => {
	const response = await apiClient.get<string>(url + 'info.yaml')
	
	if (response.ok) {
		const post = yaml.parse(response.data) as IEditorSavvaContent
		post.locales = await getDataFromFolder(post.locales, url)
		return post
	} else {
		console.error(response.error)
	}
}

const getDataFromFolder = async (
	locales: Record<string, IEditorLocale>,
	url: string
): Promise<Record<string, IEditorLocale>> => {
	const updateLocales = {}
	for await (const [key, locale] of Object.entries(locales)) {
		
		const data = locale.data_path ? await apiClient.get(`${url}${locale.data_path}`) : ''

		const chapters = locale?.chapters?.length
			? await Promise.all(
					locale.chapters.map(async el => {
						const chapterData = await apiClient.get(`${url}${el.data_path}`)
						return {
							title: String(el.title),
							data: chapterData.ok ? String(chapterData.data) : '',
							id: Math.random().toString()
						}
					})
				)
			: []
		
		if (chapters.length) {
			chapters.unshift({ title: 'post', data: 'data', id: Math.random().toString() })
		}

		updateLocales[key] = {
			title: String(locale.title),
			tags: locale.tags.map((el: string) => ({ label: el, value: el })),
			categories: locale.categories.map(el => {
				const [group, label] = el.split('/')
				return {
					label: label || group,
					value: el
				}
			}),
			data: data && data.ok ? String(data.data) : '',
			chapters,
			currentChapter: chapters.length ?  chapters[0].id : ''
		}
	}

	return updateLocales
}
